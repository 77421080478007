import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../Components/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import amplitude from "amplitude-js";
import ClassroomDetailBanner from "./widgets/ClassroomDetailBanner";
import left_arrow from "../../images/left_arrow.png";
import {
  TextField,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Select,
  OutlinedInput,
} from "@mui/material";

function CreateCLass() {
  const location = useLocation();
  const [learning, setLearning] = useState();
  const [createQuiz, setcreateQuiz] = useState({
    quizName: "",
    quizSubject: location.state
      ? location.state.classroomData &&
        location.state.classroomData.subject.subject_name
      : "",
    hasExamStylePrompt: 0,
    hasMockExams: 0,
    formativeQuizTypeStatus: 0,
    folder_id:
      location.state !== null && location.state.folder_id !== null
        ? location.state.folder_id
        : null,
  });
  const hasExamStylePrompt = useRef(0);
  const formativeQuizTypeStatus = useRef(0);
  const hasMockExams = useRef(0);
  const [subjects, setSubjects] = useState([]);
  const [subjectStatus, setSubjectStatus] = useState();
  const [className, setClassName] = useState([]);
  const navigate = useNavigate();

  const [selectedSubject, setSelectedSubject] = useState([]);
  useEffect(() => {
    getSubjectsList();
    getClassName();
  }, []);

  const onChangeQuizName = (e) => {
    setcreateQuiz({ ...createQuiz, [e.target.name]: e.target.value });
  };

  const onChangeSubject = (e) => {
    setcreateQuiz({ ...createQuiz, [e.target.name]: e.target.value });
    const selectedIndex = subjects.findIndex(
      (subject) => subject.subject_name === e.target.value
    );
    hasExamStylePrompt.current = subjects[selectedIndex].exam_style_quiz_status;
    formativeQuizTypeStatus.current =
      subjects[selectedIndex].formative_quiz_type_status;
    hasMockExams.current = subjects[selectedIndex].has_mock_exams;
    localStorage.setItem(
      "selected_subject_id",
      subjects[selectedIndex].subject_id
    );

    const selectedSubject = subjects.filter(
      (subject) =>
        subject.subject_id === parseInt(subjects[selectedIndex].subject_id)
    );

    setSelectedSubject(selectedSubject);
    setSubjectStatus(selectedSubject[0].subject_status_have_lesson);
  };

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url:
          process.env.REACT_APP_REST_API_BASE_URL +
          "/get_subjects_for_classroom",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        setSubjects(JSON.parse(res.data));
        var subjects = res.data;
        if (location.state && location.state.classroomData) {
          subjects = subjects.filter(
            (subject) =>
              location.state.classroomData.subject.subject_id ===
              subject.subject_id
          );
          hasExamStylePrompt.current = subjects[0].exam_style_quiz_status;
          formativeQuizTypeStatus.current =
            subjects[0].formative_quiz_type_status;
          hasMockExams.current = subjects[0].has_mock_exams;
          localStorage.setItem("selected_subject_id", subjects[0].subject_id);
          amplitude
            .getInstance()
            .setUserId(localStorage.getItem("email_address"));
          amplitude
            .getInstance()
            .logEvent("Select Subject [" + subjects[0].subject_name + "]");
        }
      });
  };
  const getClassName = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_classes", {
        method: "POST",
      })
      .then((res) => {
        console.log("classroom list: ", res.data.classes_data);
        setClassName(res.data.classes_data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClick = () => {
    const quizNameTrimmed = createQuiz.quizName.trim();
    const isDuplicateClass = className.some(
      (classItem) => quizNameTrimmed === classItem.classroom_name.trim()
    );

    if (isDuplicateClass) {
      alert("Class with the same name already exists.");
      return;
    }

    if (
      createQuiz.quizName.length > 0 &&
      selectedSubject.length > 0 &&
      learning
    ) {
      const stateData = {
        data: createQuiz,
        classroomData: location.state?.classroomData,
        url: location.state?.url,
        selectedSubject: selectedSubject[0],
        classes_data: null,
        subject_status_have_lesson: subjectStatus,
      };

      if (learning === "Schedule Learning") {
        navigate("/add-lesson-plans", { state: stateData });
      } else {
        navigate("/add-prior-plans", { state: stateData });
      }
    } else {
      alert("Please enter all the empty fields");
    }
  };

  const subjectList = subjects.map(
    (subject) =>
      subject.subject_product_status === 2 && (
        <MenuItem key={subject.subject_id} value={subject.subject_name}>
          {subject.subject_name}
        </MenuItem>
      )
  );

  let subjectData = null;
  if (
    location.state &&
    location.state.classroomData &&
    location.state.classroomData.subject
  ) {
    subjectData = location.state.classroomData.subject;
  } else {
    subjectData = null;
  }

  const handleOnClick = () => {
    if (location.state) {
      if (location.state.url) {
        window.location = location.state.url;
      }
    }
  };

  return (
    <>
      <Sidebar item_id="quiz" />
      <div className="sm:ml-[10px] md:ml-[316px]">
        {location.state && location.state.classroomData ? (
          <>
            <ClassroomDetailBanner
              classroomData={location.state.classroomData}
            />
            <div className="home sm:ml[25px] md:ml-[60px] underline teacher_navigation_text">
              <button className="" onClick={handleOnClick}>
                {"Classroom"}
              </button>
              <button>{" > Create Quiz"}</button>
            </div>
          </>
        ) : (
          <div></div>
        )}

        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label" style={{ color: "#403151" }}>
              Create Class
            </label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="82"
              height="14"
              viewBox="0 0 82 14"
              fill="none"
            >
              <path
                d="M2.5 14C2.5 12.3431 3.84315 11 5.5 11H76.5C78.1569 11 79.5 12.3431 79.5 14H2.5Z"
                fill="#403151"
              />
            </svg>
          </div>
          <div className="pl-10">
            <label className="disabled-label" style={{ color: "#403151" }}>
              Add lesson
            </label>
          </div>
          {location.state ? (
            <>
              {!location.state.classroomData ? (
                <div className="pl-10">
                  <label
                    className="disabled-label"
                    style={{ color: "#403151" }}
                  >
                    Share
                  </label>
                </div>
              ) : (
                <div></div>
              )}
            </>
          ) : null}
        </div>
        <div className="flex flex-row justify-center items-center sm:mr-[10px] md:mr-0">
          <div className=""></div>

          <div className="selectionDiv">
            <div className="form-div">
              <div className="sm:w-full md:w-[600px]">
                <div>
                  <label
                    className="create_quiz_label"
                    style={{
                      color: "#322E33",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px" /* 150% */,
                      letterSpacing: "0.5px",
                    }}
                  >
                    1. Name your class
                  </label>
                </div>
                <div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="quizName"
                    value={createQuiz.quizName}
                    onChange={onChangeQuizName}
                    id="quizName"
                    placeholder="e.g Y9 OCR Computer Science"
                    inputProps={{ maxLength: 45 }}
                  />
                </div>
                <label
                  className="character-length-label md:pl-[490px] sm:pb-[10px]"
                  style={{
                    color: "#49454F",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "16px" /* 133.333% */,
                    letterSpacing: "0.4px",
                  }}
                >
                  Max. 45 characters
                </label>
                <div>
                  <label
                    className="create_quiz_label mb-2"
                    style={{
                      color: "#322E33",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px" /* 150% */,
                      letterSpacing: "0.5px",
                    }}
                  >
                    2. Select the subject you'd like to create the class from.
                  </label>
                </div>
                <div>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      displayEmpty
                      value={
                        subjectData !== null
                          ? subjectData.subject_name
                          : createQuiz.quizSubject || ""
                      }
                      disabled={subjectData !== null}
                      onChange={onChangeSubject}
                      name="quizSubject"
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <label
                              style={{
                                color: "#C8C8C8",
                                fontFamily: "Roboto",
                              }}
                            >
                              Select subject of your choice
                            </label>
                          );
                        }
                        return selected;
                      }}
                      style={{
                        backgroundColor:
                          subjectData !== null ? "#1C1B1F1F" : undefined,
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {/* <MenuItem disabled value="">
                        <em>Select subject of your choice</em>
                      </MenuItem> */}
                      {subjectList}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <div>
                    <label
                      className="create_quiz_label mb-2"
                      style={{
                        color: "#322E33",
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px" /* 150% */,
                        letterSpacing: "0.5px",
                      }}
                    >
                      3. Please select one of the following.
                    </label>
                  </div>
                  <div className="mb-[24px]">
                    <FormControl fullWidth variant="outlined">
                      <Select
                        displayEmpty
                        value={learning}
                        onChange={(e) => setLearning(e.target.value)}
                        name="learning"
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <label
                                style={{
                                  color: "#C8C8C8",
                                  fontFamily: "Roboto",
                                }}
                              >
                                Select your learning option
                              </label>
                            );
                          }
                          return selected;
                        }}
                      >
                        {/* <MenuItem value="" disabled>
                          <em>Select your learning option</em>
                        </MenuItem> */}
                        <MenuItem value="Schedule Learning">
                          Schedule Learning
                        </MenuItem>
                        <MenuItem value="Prior Learning">
                          Prior Learning
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <button
                    onClick={handleClick}
                    className="teepee-button sm:w-[45%] md:w-[45%] hover:bg-black hover:cursor-pointer ml-[60px]"
                    style={{ backgroundColor: "#7E418B", color: "#FFFFFF" }}
                  >
                    Next
                  </button>
                  <button
                    onClick={() => {
                      navigate("/");
                    }}
                    className="teepee-button sm:w-[45%] md:w-[45%] hover:bg-black hover:cursor-pointer"
                    style={{
                      background: "transparent",
                      border: "1px solid #7E418B",
                      color: "#7E418B",
                      display: "flex",
                      alignItems: "center", // Add this to vertically center items inside the button
                      justifyContent: "center",
                      fontWeight: "600px",
                      fontFamily: "Roboto", // Add this to horizontally center items inside the button
                    }}
                  >
                    <img
                      src={left_arrow}
                      alt="Go Back"
                      style={{
                        marginRight: "10px", // Add space between the image and text
                      }}
                    />
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateCLass;
