import React, { Fragment, useState } from "react";
import YesNoDialog from "../YesNoDialog";
import axios from "axios";
import Button from "@mui/material/Button";
import LessonAccordian from "./LessonAccordian";

function ViewLessonDetails(props) {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deletingObject, setDeletingObject] = useState([null, null]);

  const handleCloseWithNo = () => {
    setOpenConfirmationDialog(false);
  };

  const handleCloseWithYes = () => {
    setOpenConfirmationDialog(false);
    console.log("object is ", deletingObject);
    var type = deletingObject[1];
    var id = null;
    if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_CHAPTER) {
      id = deletingObject[0].topic_id;
      console.log("chapter " + id + " Type " + type);
    } else if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_TOPIC) {
      id = deletingObject[0].sub_topic_id;
      console.log("Topic " + id);
    } else if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_SUBTOPIC) {
      id = deletingObject[0].lesson_id;
      console.log("Subtopic " + id);
    }

    sendDeleteRequest(type, id);
  };
  function sendDeleteRequest(type, object_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_lesson", {
        method: "POST",
        type: type,
        lesson_id: object_id,
      })
      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function addNewLessons(area_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_lesson", {
        method: "POST",
        area_id: area_id,
      })

      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onDeleteClick = (object, type) => {
    setOpenConfirmationDialog(true);
    setDeletingObject([object, type]);
  };
  return (
    <div>
      <YesNoDialog
        handleCloseWithNo={handleCloseWithNo}
        handleCloseWithYes={handleCloseWithYes}
        open={openConfirmationDialog}
        title={"Confirm"}
        description={"Are you sure you want to delete this record?"}
      />
      <div className="form-div">
        <div className="ml-[30px] mr-[30px] w-full">
          {props.lesson_data?.map((area, areaIndex) => (
            <Fragment key={areaIndex}>
              <div style={{}}>
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    {area["area_has_lesson"]?.map((lesson, lessonIndex) => (
                      <Fragment key={lessonIndex}>
                        <div style={{}}>
                          <div
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "30px",
                              backgroundColor: "#F0F0F0",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                fontFamily: "Roboto",
                                color: "#49454F",
                              }}
                            >
                              <b>Lesson Name: </b>
                            </p>
                            <p>
                              <LessonAccordian
                                addTodo={props.addTodo}
                                title={lesson}
                                area={area}
                                onDeleteClick={onDeleteClick}
                                question_list={lesson["area_has_question_list"]}
                              />
                            </p>
                          </div>
                        </div>

                        <div
                          style={{
                            flex: 1,
                            height: "1px",
                            backgroundColor: "#B3B3B3",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        />
                      </Fragment>
                    ))}
                  </p>
                </div>
              </div>
              <Button
                onClick={() => {
                  addNewLessons(area.area_id);
                }}
                className="delete-button"
                style={{ color: "#1976d2", padding: "6px 8px" }}
              >
                Add New lesson
              </Button>
              <div
                style={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: "#B3B3B3",
                  marginTop: "100px",
                  marginBottom: "10px",
                }}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewLessonDetails;
