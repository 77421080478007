import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const columns = [
  { id: "student_name", label: "Student Name", minWidth: 100, maxWidth: 100 },
  { id: "student_type", label: "Source", minWidth: 100, maxWidth: 100 },
  {
    id: "feedback_submission_timestamp",
    label: "Feedback submission Time",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  { id: "feedback_text", label: "Message", minWidth: 100, maxWidth: 100 },
  { id: "email_addresses", label: "Email", minWidth: 170, maxWidth: 170 },

  { id: "classroom_names", label: "Classroom", minWidth: 100, maxWidth: 100 },
];

export default function StickyHeadTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    getWebUsersFeedbackAgainstTeepeeFeedback();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getWebUsersFeedbackAgainstTeepeeFeedback = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_student_feedbacks",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
      })
      .then((res) => {
        var web_user_feedback_against_teepee_feedback = res.data;
        console.log(
          web_user_feedback_against_teepee_feedback.student_feedbacks
        );
        setRows(web_user_feedback_against_teepee_feedback.student_feedbacks);
      });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  useEffect(() => {
    if (userType === "1") {
      navigate("/");
      return;
    }
  }, [navigate, userType]);

  return (
    <>
      <Sidebar item_id="evaluation_feedback" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Student Feedback</label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="116"
              height="14"
              viewBox="0 0 116 14"
              fill="none"
            >
              <path
                d="M2.5 14C2.5 12.3431 3.84315 11 5.5 11H110.5C112.157 11 113.5 12.3431 113.5 14V14H2.5V14Z"
                fill="#403151"
              />
            </svg>
          </div>
        </div>
        <div className="flex flex-row justify-left items-center sm:ml-[10px] md:ml-[60px] mt-[20px] sm:mr-[10px] md:mr-[60px]">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 840 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#f7f2f9",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key={"student_type"}
                      align={"center"}
                      style={{
                        minWidth: "50px",
                        backgroundColor: "#f7f2f9",
                      }}
                    >
                      Student's Product
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                style={{
                                  minWidth: column.minWidth,
                                  maxWidth: column.maxWidth,
                                  overflowWrap: "break-word",
                                }}
                                key={column.id}
                                align={column.align}
                              >
                                {column.id === "student_type" ? (
                                  <div className="flex center">
                                    {value === 1 || value === 3
                                      ? "App"
                                      : value === 2 && "Web"}
                                  </div>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            style={{
                              minWidth: 170,
                              maxWidth: 170,
                              overflowWrap: "break-word",
                              borderLeft: "1px solid rgba(224, 224, 224, 1)",
                            }}
                            key={"action"}
                            align={"center"}
                          >
                            {row["student_type"] === 1
                              ? "Geovise"
                              : row["student_type"] === 2
                              ? "Jess"
                              : row["student_type"] === 3 && "Byteback"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{ backgroundColor: "#f7f2f9" }}
              rowsPerPageOptions={[100, 150, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}
