import React, { useState } from "react";
import warning_icon from "../../images/Warning.png";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 200,
    opacity: "0.8",
    padding: "12px 16px",
  },
}));

const StudentsNeedingAttention = (props) => {
  const colors = ["bg-blue-500", "bg-pink-500", "bg-purple-500"];
  const students = props.data?.class_analytics_data?.student_needing_attention;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  return (
    <div className="p-4 border-gray-300 border-t-[white] shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <div className="flex text-lg font-bold font-roboto text-[#322E33]">
          <ClickAwayListener onClickAway={handleClose}>
            <div className="flex">
              <HtmlTooltip
                title="The students whose mastery level is 20% or below."
                PopperProps={{ disablePortal: true }}
                enterTouchDelay={0}
                leaveTouchDelay={10}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
              >
                <button className="ml-1 mt-0.5 flex" onClick={handleOpen}>
                  Students Needing Attention{" "}
                  <img
                    className="ml-2 h-5 w-5 mt-1"
                    src={warning_icon}
                    alt="Warning Icon"
                  />
                </button>
              </HtmlTooltip>
            </div>
          </ClickAwayListener>
        </div>
        <div className="text-lg font-bold font-roboto text-[#322E33]">
          <ClickAwayListener onClickAway={handleClose2}>
            <div className="flex">
              <HtmlTooltip
                title="It is a measure of the average number of attempts the entire class takes to master each question."
                PopperProps={{ disablePortal: true }}
                enterTouchDelay={0}
                leaveTouchDelay={10}
                open={open2}
                onOpen={handleOpen2}
                onClose={handleClose2}
              >
                <button className="ml-1 mt-0.5 flex" onClick={handleOpen2}>
                  Mastery Level
                </button>
              </HtmlTooltip>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <div className="max-h-[230px] overflow-y-auto hide-scrollbar">
        {students && students.length > 0 ? (
          students.map((student, index) => (
            <StudentAttention
              key={index}
              name={student.student_name}
              initial={student.student_name.charAt(0)}
              color={colors[index % colors.length]}
              level={student.recall_status_4_percentage}
            />
          ))
        ) : (
          <div className="py-2 px-10 text-center text-gray-600 bg-gray-100 font-bold flex item-center justify-center">
            No data available
          </div>
        )}
      </div>
    </div>
  );
};

const StudentAttention = ({ name, initial, color, level }) => (
  <div className="flex justify-between items-center mb-2 border border-gray-300 rounded-md bg-gray-100 p-2">
    <div className="flex items-center">
      <div
        className={`w-6 h-6 rounded-full ${color} text-white flex items-center justify-center`}
      >
        {initial}
      </div>
      <div className="ml-2 text-gray-800 font-bold font-roboto">{name}</div>
    </div>
    <div className="text-[#403151] font-roboto">
      {level ? `${parseInt(level)}%` : "0%"}
    </div>
  </div>
);

export default StudentsNeedingAttention;
