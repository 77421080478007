import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Bringing in the GoogleOAuthProvider from the package

import amplitude from "amplitude-js";
import AppState from "./context/AppState";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import registerServiceWorker from "../src/Components/Calendar/registerServiceWorker";

import * as reducers from "./Components/Calendar/store/rootReducer";
const store = createStore(combineReducers(reducers), applyMiddleware(thunk));
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="770138605925-6i7ki4cct9ad13ps8s8s9nuc4si3u477.apps.googleusercontent.com">
    <Provider store={store}>
      <BrowserRouter>
        <AppState>
          <App />
        </AppState>
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>
);
registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
