import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_GET_CLASS_ANALYTICS_END_POINT,
} from "../../Components/Constants/ApiConstant";
const useGetClassAnalytics = () => {
  const getClassAnalytics = (callback, classroom_id) => {
    var access_token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = access_token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_GET_CLASS_ANALYTICS_END_POINT,
        {
          classroom_id: classroom_id,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return getClassAnalytics;
};

export default useGetClassAnalytics;
