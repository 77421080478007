import React from "react";

const DashboardLabel = () => (
  <div className="home md:ml-[20px] text-center">
    <label className="dashboard-label text-[#403151] font-roboto text-[14px] font-medium leading-[20px] tracking-[0.1px]">
      My Lesson Plans
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="110"
        height="18"
        viewBox="0 0 100 14"
        fill="none"
      >
        <path
          d="M2.5 14C2.5 12.3431 3.84315 11 5.5 11H94.5C96.1569 11 97.5 12.3431 97.5 14V14H2.5V14Z"
          fill="#403151"
        />
      </svg>
    </label>
  </div>
);

export default DashboardLabel;
