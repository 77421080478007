import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { styled } from "@mui/material/styles";
import {
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  TextField,
  Chip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import { Opacity } from "@material-ui/icons";

const PurpleRadio = styled((props) => <Radio {...props} />)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
  },
  "&.Mui-checked": {
    color: "#7E418B",
    padding: 0,
    width: "16px",
    height: "16px",
    marginRight: "4px",
    marginLeft: "8px",
  },
  "&:not(.Mui-checked)": {
    color: "transparent",
    border: "2px solid black",
    marginRight: "4px",
    marginLeft: "8px",
    width: "16px",
    height: "16px",
    padding: 0,
  },
  "& .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiTouchRipple-root": {
    display: "block",
  },
}));

const EventDetails = (props) => {
  const location = useLocation();
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedTopicIds, setSelectedTopicIds] = useState([]);
  const [selectedSubtopicIds, setSelectedSubtopicIds] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [areas, setAreas] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectAllChecked2, setSelectAllChecked2] = useState(false);

  const areaValue = useRef();
  // const contentType = useRef(
  //   location.state.subject_status_have_lesson === 0 ? "concept" : "lesson"
  // );

  const initialEventDetail = {
    id: props.eventType === "add" ? props.newIndex : props.eventInfo.id,
    start: props.eventInfo.start || moment(),
    end: props.eventInfo.end || moment(),
    allDay: props.eventInfo.allDay || false,
    hexColor: props.eventInfo.hexColor || "white",
    notes: props.eventInfo.notes || "",
    topic: "",
    subtopics: [],
    areas: [],
    calendar_id:
      props.eventType === "add" ? props.newIndex : props.eventInfo.calendar_id,
    newTopic: [],
    newSubtopic: [],
    newArea: [],
    subject_id: props.subject_id,
    index: null,
    selected_topic: [],
    selected_sub_topic: [],
    selected_areas: [],
    area_object_lists: [],
    data: [],
    contentType:
      location.state.subject_status_have_lesson === 0 ? "concept" : "lesson",
  };

  const [eventDetail, setEventDetail] = useState(initialEventDetail);

  useEffect(() => {
    setSelectAllChecked(false);
    setSelectAllChecked2(false);
    if (props.eventType === "edit") {
      const topicss = props.eventInfo.selected_topic;
      const sub_topicss = props.eventInfo.selected_sub_topic;

      if (topicss && topicss.length > 0) {
        var topicIds = [];
        if (location.state.subject_status_have_lesson === 0) {
          topicIds = topicss.map((topic) => topic.topic_id);
          setSelectedTopicIds(topicIds);
        } else {
          topicIds = topicss.map((topic) => topic.super_topic_id);
          setSelectedTopicIds(topicIds);
        }
      }

      if (sub_topicss && sub_topicss.length > 0) {
        var subTopicIds = [];
        if (location.state.subject_status_have_lesson === 0) {
          subTopicIds = sub_topicss.map((sub) => sub.sub_topic_id);
          setSelectedSubtopicIds(subTopicIds);
        } else {
          subTopicIds = sub_topicss.map((sub) => sub.topic_id);
          setSelectedSubtopicIds(subTopicIds);
        }
      }

      setEventDetail((prevDetail) => ({
        ...prevDetail,
        id: props.eventType === "add" ? props.newIndex : props.eventInfo.id,
        start: props.eventInfo.start || moment(),
        end: props.eventInfo.end || moment(),
        allDay: props.eventInfo.allDay || false,
        hexColor: props.eventInfo.hexColor || "#265985",
        notes: props.eventInfo.notes || "",
        topic: props.eventInfo.topic || "",
        subtopics: props.eventInfo.subtopics || [],
        areas: props.eventInfo.areas || [],
        calendar_id:
          props.eventType === "add"
            ? props.newIndex
            : props.eventInfo.calendar_id,
        newTopic: props.eventInfo.newTopic || [],
        newSubtopic: props.eventInfo.newSubtopic || [],
        newArea: props.eventInfo.newArea || [],
        subject_id: props.subject_id,
        index: props.index,
        selected_topic: props.eventInfo.selected_topic || [],
        selected_sub_topic: props.eventInfo.selected_sub_topic || [],
        selected_areas: props.eventInfo.selected_areas || [],
        area_object_lists: props.eventInfo.area_object_lists || [],
        data: props.data || [],
        contentType:
          props.eventInfo.contentType || initialEventDetail.contentType,
      }));
    } else {
      // Reset state for new event
      setSelectedTopicIds([]);
      setSelectedSubtopicIds([]);
      setSubtopics([]);
      setAreas([]);
      setCheckboxStates({});
      setSelectAllChecked(false);
      setEventDetail(initialEventDetail);
    }
  }, [props]);

  useEffect(() => {
    const fetchSubtopics = async () => {
      if (location.state.subject_status_have_lesson === 0) {
        const subtopicsRequests = selectedTopicIds.map((topicId) =>
          axios.post(
            `${process.env.REACT_APP_REST_API_BASE_URL}/get_sub_topic_for_lesson`,
            {
              topic_id: topicId,
            }
          )
        );

        try {
          const subtopicsResponses = await Promise.all(subtopicsRequests);
          const allSubtopics = subtopicsResponses.flatMap((res) => res.data);
          let subtopicIds = allSubtopics.map(
            (subtopic) => subtopic.sub_topic_id
          );
          let filteredSelectedSubtopicIds = selectedSubtopicIds.filter(
            (selectedSubtopicId) => subtopicIds.includes(selectedSubtopicId)
          );
          setSelectedSubtopicIds(filteredSelectedSubtopicIds);
          setSubtopics(allSubtopics);
          setEventDetail((prevDetail) => ({
            ...prevDetail,
            newSubtopic: allSubtopics,
          }));
        } catch (error) {
          console.error("Error fetching subtopics:", error);
        }
      } else {
        const subtopicsRequests = selectedTopicIds.map((topicId) =>
          axios.post(
            `${process.env.REACT_APP_REST_API_BASE_URL}/get_topic_lesson`,
            {
              super_topic_id: topicId,
            }
          )
        );

        try {
          const subtopicsResponses = await Promise.all(subtopicsRequests);
          const allSubtopics = subtopicsResponses.flatMap((res) => res.data);
          let subtopicIds = allSubtopics.map((subtopic) => subtopic.topic_id);
          let filteredSelectedSubtopicIds = selectedSubtopicIds.filter(
            (selectedSubtopicId) => subtopicIds.includes(selectedSubtopicId)
          );
          setSelectedSubtopicIds(filteredSelectedSubtopicIds);
          setSubtopics(allSubtopics);
          setEventDetail((prevDetail) => ({
            ...prevDetail,
            newSubtopic: allSubtopics,
          }));
        } catch (error) {
          console.error("Error fetching subtopics:", error);
        }
      }
    };

    if (selectedTopicIds.length > 0) {
      fetchSubtopics();
    } else {
      setSubtopics([]);
      setSelectedSubtopicIds([]);
      setAreas([]);
      setSelectedAreas([]);
      setCheckboxStates({});
      setEventDetail((prevDetail) => ({
        ...prevDetail,
        newSubtopic: [],
        newArea: [],
        selected_sub_topic: [],
        selected_areas: [],
      }));
    }
  }, [selectedTopicIds]);

  const fetchAreas = async (subtopicIds) => {
    setSelectAllChecked(false);
    setSelectAllChecked2(false);

    if (location.state.subject_status_have_lesson === 0) {
      const areasRequests = subtopicIds.map((subtopicId) =>
        axios.post(
          `${process.env.REACT_APP_REST_API_BASE_URL}/get_areas_for_lesson`,
          {
            sub_topic_id: subtopicId,
          }
        )
      );

      try {
        const areasResponses = await Promise.all(areasRequests);
        const allAreas = areasResponses.flatMap((res) => res.data);
        setAreas(allAreas);
        setEventDetail((prevDetail) => ({
          ...prevDetail,
          newArea: allAreas,
        }));
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    } else {
      const areasRequests = subtopicIds.map((subtopicId) =>
        axios.post(
          `${process.env.REACT_APP_REST_API_BASE_URL}/get_sub_topic_lesson`,
          {
            topic_id: subtopicId,
            status: eventDetail.contentType === "concept" ? 1 : 0,
          }
        )
      );

      try {
        const areasResponses = await Promise.all(areasRequests);
        const allAreas = areasResponses.flatMap((res) => res.data);
        setAreas(allAreas);
        setEventDetail((prevDetail) => ({
          ...prevDetail,
          newArea: allAreas,
        }));
      } catch (error) {
        console.error("Error fetching areas:", error);
      }
    }
  };

  useEffect(() => {
    if (selectedSubtopicIds.length > 0) {
      fetchAreas(selectedSubtopicIds);
    } else {
      setAreas([]);
      setSelectedAreas([]);
      setCheckboxStates({});
      setEventDetail((prevDetail) => ({
        ...prevDetail,
        newArea: [],
        selected_areas: [],
      }));
    }
  }, [selectedSubtopicIds]);

  useEffect(() => {
    setEventDetail((prevState) => ({
      ...prevState,
      selected_areas: selectedAreas || [],
    }));
  }, [selectedAreas]);

  useEffect(() => {
    const initialCheckboxStates = {};

    eventDetail.newArea.forEach((subTopic) => {
      const checkboxName = `area-${
        eventDetail.contentType === "lesson"
          ? subTopic.sub_topic_id
          : subTopic.area_id
      }`;

      let isChecked = false;

      if (props.eventInfo.data) {
        isChecked =
          props.eventInfo.data?.have_subtopic === 0 ||
          !props.eventInfo.data?.have_subtopic
            ? props.eventInfo.data?.area_object_lists.some(
                (areaObject) =>
                  parseInt(
                    eventDetail.contentType === "concept"
                      ? areaObject.area_id
                      : areaObject.sub_topic_id
                  ) ===
                  (eventDetail.contentType === "concept"
                    ? subTopic.area_id
                    : subTopic.sub_topic_id)
              )
            : props.eventInfo.data?.sub_topic_objects.some(
                (subtopicObject) =>
                  parseInt(
                    eventDetail.contentType === "concept"
                      ? subtopicObject.area_id
                      : subtopicObject.sub_topic_id
                  ) ===
                  (eventDetail.contentType === "concept"
                    ? subTopic.area_id
                    : subTopic.sub_topic_id)
              );
      } else {
        isChecked =
          props.eventInfo.data?.have_subtopic === 0 ||
          !props.eventInfo.data?.have_subtopic
            ? selectedAreas.includes(
                eventDetail.contentType === "concept"
                  ? subTopic.area_name
                  : subTopic.sub_topic_name
              )
            : selectedAreas.includes(
                eventDetail.contentType === "concept"
                  ? subTopic.area_name
                  : subTopic.sub_topic_name
              );
      }

      initialCheckboxStates[checkboxName] = isChecked;

      if (isChecked) {
        props.handleValueChangeArea(
          eventDetail.contentType === "concept"
            ? subTopic.area_name
            : subTopic.sub_topic_name
        );
      } else {
        props.handleValueChangeArea(
          eventDetail.contentType === "concept"
            ? subTopic.area_name + "D"
            : subTopic.sub_topic_name + "D"
        );
      }
    });

    setCheckboxStates(initialCheckboxStates);

    const { have_subtopic, area_object_lists, sub_topic_objects } =
      props.eventInfo.data || {};

    if (have_subtopic === 0 || have_subtopic === 1) {
      if (eventDetail.contentType === "concept" && have_subtopic === 0) {
        if (eventDetail.newArea?.length === area_object_lists?.length) {
          setSelectAllChecked(true);
          setSelectAllChecked2(false);
        }
      } else if (have_subtopic === 1) {
        if (eventDetail.newArea?.length === sub_topic_objects?.length) {
          setSelectAllChecked2(true);
          setSelectAllChecked(false);
        }
      }
    } else {
      if (
        eventDetail.contentType === "concept" &&
        area_object_lists?.length > 0 &&
        sub_topic_objects?.length === 0
      ) {
        if (eventDetail.newArea?.length === area_object_lists?.length) {
          setSelectAllChecked(true);
          setSelectAllChecked2(false);
        } else {
          setSelectAllChecked(false);
          setSelectAllChecked2(false);
        }
      } else if (
        area_object_lists?.length > 0 &&
        sub_topic_objects?.length > 0 &&
        eventDetail.newArea?.length === sub_topic_objects?.length
      ) {
        setSelectAllChecked2(true);
        setSelectAllChecked(false);
      } else {
        setSelectAllChecked2(false);
        setSelectAllChecked(false);
      }
    }
  }, [
    eventDetail.newArea,
    props.eventInfo.data,
    props.showModal,
    eventDetail.contentType,
    eventDetail.selected_sub_topic,
    eventDetail.selected_topic,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "chapter") {
      const selectedChapters = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setSelectedTopicIds(selectedChapters.map((id) => parseInt(id)));
      var selectedTopicObjects = [];
      if (location.state.subject_status_have_lesson === 0) {
        selectedTopicObjects = selectedChapters.map((id) =>
          props.newTopic.find((topic) => topic.topic_id === parseInt(id))
        );
      } else {
        selectedTopicObjects = selectedChapters.map((id) =>
          props.newTopic.find((topic) => topic.super_topic_id === parseInt(id))
        );
      }
      setEventDetail((prevState) => ({
        ...prevState,
        selected_topic: selectedTopicObjects,
      }));
      props.handleValueChangeTopic(selectedTopicObjects);
    } else if (name === "topic") {
      var selectedSubtopics = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setSelectedSubtopicIds(selectedSubtopics.map((id) => parseInt(id)));
      const selectedSubtopicObjects = [];
      if (location.state.subject_status_have_lesson === 0) {
        selectedSubtopicObjects = selectedSubtopics.map((id) =>
          eventDetail.newSubtopic.find(
            (subtopic) => subtopic.sub_topic_id === parseInt(id)
          )
        );
      } else {
        selectedSubtopicObjects = selectedSubtopics.map((id) =>
          eventDetail.newSubtopic.find(
            (subtopic) => subtopic.topic_id === parseInt(id)
          )
        );
      }
      setEventDetail((prevState) => ({
        ...prevState,
        selected_sub_topic: selectedSubtopicObjects,
      }));
      props.handleValueChangeSubTopic(selectedSubtopicObjects);
    } else {
      setCheckboxStates((prevStates) => ({
        ...prevStates,
        [name]: e.target.checked,
      }));
      console.log("selected area value", JSON.stringify(value));
      setSelectedAreas((prevSelectedAreas) => {
        if (e.target.checked) {
          return prevSelectedAreas.includes(value)
            ? prevSelectedAreas
            : [...prevSelectedAreas, value];
        } else {
          setSelectAllChecked(false);
          return prevSelectedAreas.filter((area) => area !== value);
        }
      });

      props.handleValueChangeArea(e.target.checked ? value : `${value}D`);
    }
  };

  const handleSelectAllChange = (e) => {
    if (eventDetail.contentType === "lesson") {
      setSelectAllChecked2(e.target.checked);
    } else {
      setSelectAllChecked(e.target.checked);
    }

    const newCheckboxStates = {};
    eventDetail.newArea.forEach((subTopic) => {
      const checkboxName = `area-${
        eventDetail.contentType === "lesson"
          ? subTopic.sub_topic_id
          : subTopic.area_id
      }`;
      newCheckboxStates[checkboxName] = e.target.checked;
    });
    setCheckboxStates(newCheckboxStates);
    setSelectedAreas(
      e.target.checked
        ? eventDetail.newArea.map((area) =>
            eventDetail.contentType === "lesson"
              ? area.sub_topic_name
              : area.area_name
          )
        : []
    );
    areaValue.current = e.target.checked
      ? eventDetail.newArea.map((area) =>
          eventDetail.contentType === "lesson"
            ? area.sub_topic_name
            : area.area_name
        )
      : [];

    props.handleValueChangeSelectAll(selectedAreas);
  };

  const topicOptions = props.newTopic.map((subject) => ({
    label:
      location.state.subject_status_have_lesson === 0
        ? subject.topic_name
        : subject.super_topic_name,
    value:
      location.state.subject_status_have_lesson === 0
        ? subject.topic_id
        : subject.super_topic_id,
  }));

  const subtopicOptions = subtopics.map((subject) => ({
    label:
      location.state.subject_status_have_lesson === 0
        ? subject.sub_topic_name
        : subject.topic_name,
    value:
      location.state.subject_status_have_lesson === 0
        ? subject.sub_topic_id
        : subject.topic_id,
  }));

  const areaOptions = areas.map((subTopic, index) => {
    const checkboxName = `area-${
      eventDetail.contentType === "lesson"
        ? subTopic.sub_topic_id
        : subTopic.area_id
    }`;

    return (
      <li key={index}>
        <div className="flex items-center p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
          <input
            // disabled={
            //   moment(eventDetail.start).isBefore(
            //     moment().subtract(1, "days")
            //   ) && props.eventType !== "add"
            // }
            id={`area-${index}`}
            type="checkbox"
            name={checkboxName}
            value={
              eventDetail.contentType === "concept"
                ? subTopic.area_name
                : subTopic.sub_topic_name
            }
            onChange={handleChange}
            checked={checkboxStates[checkboxName] || false}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />

          <label
            htmlFor={`area-${index}`}
            className="w-full ml-2 text-sm font-medium text-gray-900 rounded dark:text-gray-900"
          >
            {eventDetail.contentType === "lesson"
              ? subTopic.sub_topic_name
              : subTopic.area_name}
          </label>
        </div>
      </li>
    );
  });

  const isAtLeastOneAreaChecked = Object.values(checkboxStates).some(
    (isChecked) => isChecked
  );
  useState(() => {
    if (props.eventType !== "add") {
      setSelectedAreas(props.eventInfo.data.area_object_lists.area_name);
    }
  }, [props.eventInfo.data]);
  return (
    <Modal show={props.showModal} onHide={props.handleHide} centered>
      <Modal.Header style={{ backgroundColor: "#CAC4D0" }} closeButton>
        <Modal.Title id="contained-modal-title"></Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ paddingLeft: "7%", paddingRight: "12%", paddingTop: "4%" }}
      >
        <p
          style={{
            color: "#403151",
            fontFamily: "Roboto",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "28px",
          }}
        >
          Plan Your Lesson for {moment(props.eventInfo.start).format("LL")}{" "}
        </p>
        <p
          style={{
            color: "#403151",
            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
          }}
        >
          {location.state.subject_status_have_lesson === 0
            ? "Choose the topic, subtopic, and the specific areas you intend to teach on this day."
            : "Choose the topic, subtopic, and the specific lesson or concept you intend to teach on this day."}
        </p>
        <div style={{ marginBottom: "4px", marginTop: "20px", width: "462px" }}>
          <div className="teepee-divider"></div>
        </div>

        <form>
          <div className="form-group">
            <label
              style={{
                color: "#49454F",
                marginTop: "24px",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                marginBottom: "4px",
              }}
            >
              1. Select the topic of your choice
            </label>
            <div>
              <Autocomplete
                // Uncomment the following line if you want to use the disabled condition
                // disabled={
                //   moment(eventDetail.start).isBefore(moment().subtract(1, "days")) && props.eventType !== "add"
                // }
                multiple
                options={topicOptions}
                getOptionLabel={(option) => option?.label}
                value={selectedTopicIds.map((id) =>
                  topicOptions.find((option) => option.value === id)
                )}
                onChange={(event, newValue) => {
                  const topicIds = newValue.map((option) => option.value);
                  setSelectedTopicIds(topicIds);

                  let selectedTopicObjects = [];
                  if (location.state.subject_status_have_lesson === 0) {
                    selectedTopicObjects = newValue.map((option) => ({
                      topic_id: option.value,
                      topic_name: option.label,
                    }));
                  } else {
                    selectedTopicObjects = newValue.map((option) => ({
                      super_topic_id: option.value,
                      super_topic_name: option.label,
                    }));
                  }

                  setEventDetail((prevState) => ({
                    ...prevState,
                    selected_topic: selectedTopicObjects,
                    selected_sub_topic: [],
                    selected_areas: [],
                    newSubtopic: [],
                    newArea: [],
                  }));

                  props.handleValueChangeTopic(selectedTopicObjects);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      selectedTopicIds.length === 0 ? "Select Topic" : ""
                    }
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <Chip
                        variant="outlined"
                        label={option.label}
                        {...getTagProps({ index })}
                        style={{
                          margin: "2px 0",
                          backgroundColor: "#EBEBEB",
                        }}
                      />
                    </div>
                  ))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <label
              style={{
                color: "#49454F",
                marginTop: "24px",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                marginBottom: "4px",
              }}
            >
              2. Choose your desired subtopics
            </label>
            <div>
              <Autocomplete
                onMouseEnter={(e) => {
                  return;
                }}
                // Uncomment the following line if you want to use the disabled condition
                // disabled={
                //   moment(eventDetail.start).isBefore(moment().subtract(1, "days")) && props.eventType !== "add"
                // }
                limitTags={3}
                multiple
                options={subtopicOptions}
                getOptionLabel={(option) => option?.label}
                value={selectedSubtopicIds.map((id) =>
                  subtopicOptions.find((option) => option.value === id)
                )}
                onChange={(event, newValue) => {
                  const subtopicIds = newValue.map((option) => option.value);
                  setSelectedSubtopicIds(subtopicIds);

                  let selectedSubtopicObjects = [];
                  if (location.state.subject_status_have_lesson === 0) {
                    selectedSubtopicObjects = newValue.map((option) => ({
                      sub_topic_id: option.value,
                      sub_topic_name: option.label,
                    }));
                  } else {
                    selectedSubtopicObjects = newValue.map((option) => ({
                      topic_id: option.value,
                      topic_name: option.label,
                    }));
                  }

                  setEventDetail((prevState) => ({
                    ...prevState,
                    selected_topic: [],
                    selected_sub_topic: selectedSubtopicObjects,
                    selected_areas: [],
                    newArea: [],
                    newSubtopic: [],
                  }));

                  props.handleValueChangeSubTopic(selectedSubtopicObjects);
                  fetchAreas(subtopicIds);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      selectedSubtopicIds.length < 1 ? "Select Subtopic" : ""
                    }
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <Chip
                        variant="outlined"
                        label={option?.label}
                        {...getTagProps({ index })}
                        style={{
                          margin: "2px 0",
                          backgroundColor: "#EBEBEB",
                        }}
                      />
                    </div>
                  ))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div style={{ marginTop: "24px" }}>
              <div
                style={{
                  display:
                    location.state.subject_status_have_lesson === 0
                      ? "none"
                      : "",
                }}
              >
                <label
                  style={{
                    color: "#49454F",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "24px",
                    letterSpacing: "0.15px",
                    marginBottom: "4px",
                  }}
                >
                  3. Select your lessons or concepts
                </label>
                <FormControl
                  component="fieldset"
                  className="mt-4 mb-4 items-start"
                  // disabled={
                  //   moment(eventDetail.start).isBefore(
                  //     moment().subtract(1, "days")
                  //   ) && props.eventType !== "add"
                  // }
                >
                  <RadioGroup
                    row
                    aria-label="contentType"
                    name="contentType"
                    value={eventDetail.contentType}
                    onChange={(e) => {
                      eventDetail.contentType = e.target.value;
                      // setSelectedSubtopicIds([]);
                      setAreas([]);

                      setSelectedAreas([]);
                      setCheckboxStates({});
                      setSelectAllChecked(false);
                      setEventDetail((prevDetail) => ({
                        ...prevDetail,
                        newSubtopic: [],
                        newArea: [],
                        selected_sub_topic: [],
                        selected_areas: [],
                      }));
                      fetchAreas(selectedSubtopicIds);
                    }}
                  >
                    <FormControlLabel
                      value="lesson"
                      control={<PurpleRadio />}
                      label="Lessons"
                    />
                    <FormControlLabel
                      value="concept"
                      control={<PurpleRadio />}
                      label="Concepts"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <label
              style={{
                color: "#49454F",
                marginTop: "24px",
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                marginBottom: "4px",
                display:
                  location.state.subject_status_have_lesson === 1 ? "none" : "",
              }}
            >
              3. Select your areas
            </label>
            <div className="subtopic_checkbox">
              <div
                id="sub_topics_container"
                className="p-2.5 bg-white shadow-sm outline-none rounded-[8px] border-[1px] border-[#474747] w-full h-[7srem] text-[#474747] leading-[30px] text-[20px] font-[500] mb-1"
              >
                <ul
                  className="h-24 px-3 overflow-y-auto text-sm text-black dark:text-black"
                  aria-labelledby="dropdownSearchButton"
                >
                  {areaOptions.length > 0 ? (
                    <>
                      <span
                        style={{
                          display:
                            eventDetail.contentType === "lesson" ? "" : "none",
                        }}
                      >
                        <input
                          // disabled={
                          //   moment(eventDetail.start).isBefore(
                          //     moment().subtract(1, "days")
                          //   ) && props.eventType !== "add"
                          // }
                          id="selectAll"
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={selectAllChecked2}
                        />{" "}
                        Select All
                      </span>
                      <span
                        style={{
                          display:
                            eventDetail.contentType === "concept" ? "" : "none",
                        }}
                      >
                        <input
                          id="selectAll"
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={selectAllChecked}
                          // disabled={
                          //   moment(eventDetail.start).isBefore(
                          //     moment().subtract(1, "days")
                          //   ) && props.eventType !== "add"
                          // }
                        />{" "}
                        Select All
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                  {areaOptions}
                </ul>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-[100%]">
          {props.eventType !== "add" && (
            <Button
              bsStyle="warning"
              onClick={() =>
                props.deleteEvent(
                  eventDetail,
                  eventDetail.calendar_id,
                  eventDetail.data
                )
              }
              style={{
                color: "#B3261E",
                backgroundColor: "white",
                Opacity:
                  selectedTopicIds.length === 0 ||
                  selectedSubtopicIds.length === 0 ||
                  !isAtLeastOneAreaChecked ||
                  moment(eventDetail.start).isBefore(
                    moment().subtract(1, "days")
                  )
                    ? "0.8px"
                    : "",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0.5px",
                marginLeft: "254px",
                width: "100px",
                height: " 40px",
                textAlign: "center",
                float: "",
                marginTop: "",
                border: "1px solid #B3261E",
              }}
              disabled={
                selectedTopicIds.length === 0 ||
                selectedSubtopicIds.length === 0 ||
                !isAtLeastOneAreaChecked ||
                moment(eventDetail.start).isBefore(moment().subtract(1, "days"))
              }
            >
              Delete
            </Button>
          )}
          {props.eventType === "add" ? (
            <Button
              bsStyle="warning"
              style={{
                width: "100px",
                height: " 40px",
                float: "right",
              }}
              className={
                selectedTopicIds.length === 0 ||
                selectedSubtopicIds.length === 0 ||
                !isAtLeastOneAreaChecked
                  ? "disable-button"
                  : "enable-button1"
              }
              onClick={() =>
                props.addEvent(
                  eventDetail,
                  eventDetail.newArea,
                  selectedAreas,
                  checkboxStates
                )
              }
              disabled={
                selectedTopicIds.length === 0 ||
                selectedSubtopicIds.length === 0 ||
                !isAtLeastOneAreaChecked
              }
            >
              Save
            </Button>
          ) : (
            <Button
              bsStyle="warning"
              className={
                selectedTopicIds.length === 0 ||
                selectedSubtopicIds.length === 0 ||
                !isAtLeastOneAreaChecked
                  ? "disable-button"
                  : "enable-button1"
              }
              onClick={() =>
                props.updateEvent(
                  eventDetail,
                  eventDetail.newArea,
                  eventDetail.data,
                  checkboxStates,
                  selectedAreas
                )
              }
              disabled={
                selectedTopicIds.length === 0 ||
                selectedSubtopicIds.length === 0 ||
                !isAtLeastOneAreaChecked ||
                moment(eventDetail.start).isBefore(moment().subtract(1, "days"))
              }
              style={{
                backgroundColor: "#7E418B",
                width: "100px",
                height: " 40px",
                float: "right",
              }}
            >
              Update
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EventDetails;
