import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DayViewModal from "./DayViewModal ";
import EventDetails from "./eventDetails";
import * as eventAction from "../store/eventAction";
import * as types from "../store/eventActionTypes";
import { useRef } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "../css/datetime.css";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "bootstrap/dist/css/bootstrap.min.css";
BigCalendar.momentLocalizer(moment);

const Event = ({ event, loading }) => {
  const location = useLocation();

  const handleMap = () => {
    if (event.data.have_subtopic === 1) {
      return event.data.sub_topic_objects.map((subtopic, index) => (
        <div key={index}>
          {subtopic.sub_topic_name
            ? subtopic.sub_topic_name
            : subtopic.area_name}
        </div>
      ));
    } else {
      return (
        event.data.area_object_lists &&
        event.data.area_object_lists.map((area, index) => (
          <div key={index}>
            {area.sub_topic_name ? area.sub_topic_name : area.area_name}
          </div>
        ))
      );
    }
  };
  const formattedEventStartDate = moment(event.start).format("YYYY-MM-DD");
  const formattedLoadingDate = moment(loading).format("YYYY-MM-DD");
  return (
    <>
      {formattedLoadingDate === formattedEventStartDate ? (
        <div className="d-flex justify-content-center align-items-center">
          <SkeletonTheme color="#e0e0e0" highlightColor="#f5f5f5">
            <div>
              <Skeleton circle={false} height={200} width={200} />
              <Skeleton height="100%" width="100%" />
              <Skeleton height="100%" width="100%" />
              <Skeleton height="100%" width="100%" />
              <Skeleton height="100%" width="100%" />
            </div>
          </SkeletonTheme>
        </div>
      ) : (
        event.data && (
          <div
            className="bodyContainer calendar_event"
            style={{ overflowY: "auto", fontSize: "10px" }}
          >
            <div
              className="topic"
              style={{
                backgroundColor: "#039BE5",
                padding: "2px",
                borderRadius: "8px",
                fontFamily: "",
              }}
            >
              {event.data.subject_name}
            </div>

            <div
              style={{
                alignItems: "center",
                gap: "5px",
              }}
            >
              <div
                className="topic"
                style={{
                  backgroundColor: "#0B8043",
                  padding: "2px",
                  borderRadius: "8px",
                  paddingBottom: "14px",
                }}
              >
                {handleMap()}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

const EventCalendar = (props) => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events.allEvents);

  // const newTopic = useSelector((state) => state.someOtherState.newTopic); // Adjust according to your state structure
  // const subject_id = useSelector((state) => state.someOtherState.subject_id); // Adjust according to your state structure

  const [showModal, setShowModal] = useState(false);
  const [eventType, setEventType] = useState("add");
  const [newIndex, setNewIndex] = useState(0);
  const [eventInfo, setEventInfo] = useState({});
  const [showDayViewModal, setShowDayViewModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const selected_topic = useRef([]);
  const selected_subtopic = useRef([]);
  const selected_areas = useRef([]);
  const data = useRef({});
  const location = useLocation();
  const [calendar_id, setCalendar_id] = useState({});
  const [loadingEventId, setLoadingEventId] = useState(null);
  const handleValueChangeTopic = (selectedTopic) => {
    selected_topic.current = selectedTopic;
  };

  const handleValueChangeSubTopic = (selectedSubTopic) => {
    // selected_topic.current = selectedSubTopic;

    selected_subtopic.current = selectedSubTopic;
  };

  const handleValueChangeArea = (selectedArea) => {
    // Check if selectedArea ends with 'D' (meaning it should be removed)
    const isRemoving = selectedArea.endsWith("D");

    if (isRemoving) {
      // Remove 'D' from the value
      const areaToBeRemoved = selectedArea.slice(0, -1);

      // Remove the item from the array if it exists
      selected_areas.current = selected_areas.current.filter(
        (area) => area !== areaToBeRemoved
      );
      console.log("Removed array:", selected_areas.current);
    } else {
      // Add the item to the array
      if (Array.isArray(selected_areas.current)) {
        selected_areas.current.push(selectedArea);
      } else {
        selected_areas.current = [selectedArea];
      }
    }
  };
  const handleValueChangeSelectAll = (selectedArea) => {
    selected_areas.current = selectedArea;
  };

  const getLessonPlans = () => {
    if (props.name !== "Add") {
      // console.log("new new data:", props.classes.classes_data);

      var token = "Bearer " + localStorage.getItem("access_token");

      axios
        .request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL + "/get_lesson_plans",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: token,
          },
          data: JSON.stringify({
            classroom_id: props.classes.class_data.classroom_id,
            subject_status_have_lesson:
              props.classes.class_data.subject?.subject_status_have_lesson,
          }),
        })
        .then((res) => {
          console.log("objedsdct");
          console.log(res.data);
          setTimeout(() => {
            setLoadingEventId(null); // Clear loading state after data is loaded and delay
          }, 5000);
          data.current = res.data;
          var allEvents = [];

          for (let index = 0; index < data.current.length; index++) {
            var allEventObj = new Object();
            const element = data.current[index];
            allEventObj.calender_id = index;
            allEventObj.title = "Today!";
            allEventObj.allDay = true;
            allEventObj.start = element.learn_date;
            allEventObj.end = element.learn_date;
            allEventObj.notes = "Have a great day!";
            allEventObj.hexColor = "#265985";
            allEventObj.data = element;
            allEventObj.selected_topic = element.super_topic_objects
              ? element.super_topic_objects
              : element.topic_objects;

            allEventObj.selected_sub_topic = element.super_topic_objects
              ? element.topic_objects
              : element.sub_topic_objects;
            allEventObj.contentType =
              element.have_subtopic === 0 ? "concept" : "lesson";
            allEvents.push(allEventObj);
          }
          console.log("events are: ", allEvents);

          // localForage.getItem("AllEvents", function (err, allEve) {
          //   if (allEve) {
          //     allEvents = allEve;
          //   } else {
          //     localForage.setItem("AllEvents", allEvents);
          //   }

          dispatch({ type: types.ALL_EVENTS, allEvents });
        });
      console.log(data.current);
    } else {
      var allEvents = [];
      for (let index = 0; index < events.length; index++) {
        var allEventObj = new Object();
        // const element = data[index];
        // allEventObj.calendar_id = index;
        // allEventObj.title = "Today!";
        // allEventObj.allDay = true;
        // // allEventObj.start = moment(element.start).format("YYYY-MM-DD");
        // // allEventObj.end = moment(element.start).format("YYYY-MM-DD");
        // allEventObj.notes = "Have a great day!";
        // allEventObj.hexColor = "#265985";
        // allEventObj.data = element;
        // allEvents.push(allEventObj);
      }

      dispatch({ type: types.ALL_EVENTS, allEvents });
    }
  };
  useEffect(() => {
    getLessonPlans();
  }, [dispatch]);
  if (props.name === "Add") {
    props.setPlanList(events);
  }

  const handleHide = () => setShowModal(false);
  const handleShow = (slotInfo, type, date) => {
    if (loadingEventId) {
      return;
    }
    // const currentDate = moment(new Date());
    // const selectedDate = moment(slotInfo.start);

    // // Check if the selected date is in the current month
    // if (selectedDate.month() !== currentDate.month()) {
    //   return;
    // }
    setNewIndex(events.length);
    console.log("data" + slotInfo);
    setEventInfo(slotInfo);
    setEventType(type);
    setShowModal(true);
  };

  const deleteEvent = (obj, id, data) => {
    let calendarId;
    if (props.name !== "Add") {
      data.forEach((item) => {
        if (
          moment(item.learn_date).format("YYYY-MM-DD") ===
          moment(obj.start).format("YYYY-MM-DD")
        ) {
          console.log("Calendar_id", item);
          calendarId = item.calendar_id;
          console.log("Calendar_id", calendarId);
        }
      });
    }
    var token = "Bearer " + localStorage.getItem("access_token");

    //const sub_topic_id_list = [1, 2]
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    if (props.name !== "Add") {
      axios
        .post(
          process.env.REACT_APP_REST_API_BASE_URL + "/delete_lesson_plans",
          {
            method: "POST",
            calendar_id: calendarId,
          }
        )

        .then((response) => {
          console.log(response);
          getLessonPlans();
          props.setlessonPlanAdded(true);
        })
        .catch((error) => {
          console.log(error);
        });

      console.log("new object", obj);
      dispatch({
        type: types.REMOVE_EVENT,
        payload: id,
      });
    } else {
      console.log("id here is ", id);
      dispatch({
        type: types.REMOVE_EVENT,
        payload: id,
      });
    }
    //selected_areas.current = [];
    setShowModal(false);
    props.setShow2(true);
  };

  const addEvent = (obj, areaList, sarea, area_id) => {
    setLoadingEventId(moment(obj.start).format("YYYY-MM-DD"));
    console.log("all events", events);
    if (!events || events.length === 0) {
      obj.calendar_id = 0;
    } else {
      // Find the highest calendar_id in the events array
      let maxCalendarId = Math.max(...events.map((event) => event.calendar_id));
      // Update obj.calendar_id to be greater than the highest calendar_id
      obj.calendar_id = maxCalendarId + 1;
    }
    const selectedA = [];

    Object.keys(area_id).forEach((key) => {
      if (area_id[key]) {
        const areaId = key.split("-")[1]; // extract area id from key
        selectedA.push(parseInt(areaId));
      }
    });

    console.log("obj here it update", selectedA);
    console.log("area list", areaList);
    console.log("selected areas", selected_areas.current);

    let filteredAreas = areaList.filter((area) =>
      selectedA.includes(area.area_id ? area.area_id : area.sub_topic_id)
    );
    let filteredSubtopics = areaList.filter((area) =>
      selectedA.includes(area.sub_topic_id)
    );
    console.log("Filtered Areas:", sarea);
    let area_ids_csv = filteredAreas
      .map((area) => (area.area_id ? area.area_id : area.sub_topic_id))
      .join(",");
    console.log("Filtered Areas here:", filteredAreas);
    obj.selected_sub_topic = selected_subtopic.current;
    obj.selected_topic = selected_topic.current;
    obj.selected_areas = sarea;
    var newEventObj = new Object();
    newEventObj = {
      calendar_id: obj.calendar_id,
      title: "Today!",
      allDay: true,
      start: obj.start,
      end: obj.end,
      selected_topic: obj.selected_topic,
      selected_sub_topic: obj.selected_sub_topic,
      selected_areas: filteredAreas,
      contentType: obj.contentType,
      area_object_lists: filteredAreas,
      data: {
        subject_name: obj.subject_id.subject_name,
        area_object_lists: filteredAreas,
        sub_topic_objects: filteredSubtopics,
        area_ids_csv: area_ids_csv,
      },
    };

    console.log("NEW EVENT OBJECT: ", newEventObj);

    let newID;

    var token = "Bearer " + localStorage.getItem("access_token");

    //const sub_topic_id_list = [1, 2]
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    if (props.name !== "Add") {
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_lesson_plan", {
          method: "POST",
          class_id: props.classes.class_data.classroom_id,
          learn_date: moment(obj.start).format("YYYY-MM-DD"),
          area_ids_csv: obj.contentType === "concept" ? area_ids_csv : null,
          sub_topic_ids_csv:
            obj.contentType === "concept" ? null : area_ids_csv,
        })

        .then((response) => {
          console.log(response);

          props.setlessonPlanAdded(true);

          getLessonPlans();
        })
        .catch((error) => {
          console.log(error);
        });

      console.log("new object", obj);
      dispatch({
        type: types.ADD_EVENT,
        payload: newEventObj,
      });

      selected_areas.current = [];
      setShowModal(false);
      // window.location.reload();
    } else {
      console.log("NEW EVENT OBJECT after: ", newEventObj);

      let newID;
      let filteredAreas = areaList.filter((area) =>
        selectedA.includes(area.area_id ? area.area_id : area.sub_topic_id)
      );
      console.log("Filtered Areas:", filteredAreas);

      let area_ids_csv = filteredAreas.map((area) => area.area_id).join(",");
      obj.selected_sub_topic = selected_subtopic.current;
      obj.selected_topic = selected_topic.current;
      obj.selected_areas = filteredAreas;
      setTimeout(() => {
        setLoadingEventId(null); // Clear loading state after data is loaded and delay
      }, 2500);
      dispatch({
        type: types.ADD_EVENT,
        payload: newEventObj,
      });
      setShowModal(false);
    }
  };

  const updateEvent = (obj, areaList, data, area_id) => {
    setLoadingEventId(moment(obj.start).format("YYYY-MM-DD"));
    const selectedA = [];

    Object.keys(area_id).forEach((key) => {
      if (area_id[key]) {
        const areaId = key.split("-")[1]; // extract area id from key
        selectedA.push(parseInt(areaId));
      }
    });

    console.log("obj here it update", selectedA);
    console.log("area list", areaList);
    console.log("selected areas", selected_areas.current);

    let filteredAreas2 = areaList.filter((area) =>
      selectedA.includes(area.area_id ? area.area_id : area.sub_topic_id)
    );
    let filteredSubtopic = areaList.filter((area) =>
      selectedA.includes(area.sub_topic_id)
    );
    let area_ids_csv2 = filteredAreas2
      .map((area) => (area.area_id ? area.area_id : area.sub_topic_id))
      .join(",");
    obj.selected_subtopic = selected_subtopic.current;
    obj.selected_topic = selected_topic.current;
    obj.selected_areas = filteredAreas2;
    console.log("new Data", filteredAreas2);
    var newEventObj = new Object();
    newEventObj = {
      calendar_id: obj.calendar_id,
      title: "Today!",
      allDay: true,
      start: obj.start,
      end: obj.end,
      selected_topic: obj.selected_topic,
      selected_sub_topic: obj.selected_sub_topic,
      selected_areas: filteredAreas2,
      contentType: obj.contentType,
      area_object_lists: filteredAreas2,
      data: {
        subject_name: obj.subject_id.subject_name,
        area_object_lists: filteredAreas2,
        sub_topic_objects: filteredSubtopic,
        area_ids_csv: area_ids_csv2,
      },
    };

    console.log("NEW EVENT OBJECT: ", newEventObj);

    let calendarId;
    if (props.name !== "Add") {
      data.forEach((item) => {
        if (
          moment(item.learn_date).format("YYYY-MM-DD") ===
          moment(obj.start).format("YYYY-MM-DD")
        ) {
          console.log("Calendar_id", item);
          calendarId = item.calendar_id;
          console.log("Calendar_id", calendarId);
        }
      });
    }

    let filteredAreas = areaList.filter((area) =>
      selectedA.includes(area.area_id ? area.area_id : area.sub_topic_id)
    );
    console.log("Filtered Areas:", filteredAreas);

    let area_ids_csv = filteredAreas
      .map((area) => (area.area_id ? area.area_id : area.sub_topic_id))
      .join(",");
    obj.selected_subtopic = selected_subtopic.current;
    obj.selected_topic = selected_topic.current;
    obj.selected_areas = filteredAreas;
    var token = "Bearer " + localStorage.getItem("access_token");

    // //const sub_topic_id_list = [1, 2]
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    if (props.name !== "Add") {
      axios
        .post(
          process.env.REACT_APP_REST_API_BASE_URL + "/update_lesson_plans",
          {
            method: "POST",
            class_id: props.classes.class_data.classroom_id,
            learn_date: moment(obj.start).format("YYYY-MM-DD"),
            calendar_id: calendarId,
            area_ids_csv: obj.contentType === "concept" ? area_ids_csv : null,
            sub_topic_ids_csv:
              obj.contentType === "concept" ? null : area_ids_csv,
          }
        )

        .then((response) => {
          console.log(response);
          getLessonPlans();

          // alert("DAta added in calender");
          props.setlessonPlanAdded(true);
        })
        .catch((error) => {
          console.log(error);
        });

      dispatch({
        type: types.UPDATE_EVENT,
        payload: {
          id: calendarId,
          obj: obj,
          obj1: newEventObj,
        },
      });

      setShowModal(false);
    } else {
      console.log("NEW EVENT OBJECT: ", newEventObj);

      let newID;
      let filteredAreas = areaList.filter((area) =>
        selectedA.includes(area.area_id ? area.area_id : area.sub_topic_id)
      );
      console.log("Filtered Areas:", filteredAreas);
      let area_ids_csv = filteredAreas2
        .map((area) => (area.area_id ? area.area_id : area.sub_topic_id))
        .join(",");
      obj.selected_sub_topic = selected_subtopic.current;
      obj.selected_topic = selected_topic.current;
      obj.selected_areas = filteredAreas;
      setTimeout(() => {
        setLoadingEventId(null); // Clear loading state after data is loaded and delay
      }, 3000);
      dispatch({
        type: types.UPDATE_EVENT,
        payload: {
          id: obj.calendar_id,
          obj: obj,
          obj1: newEventObj,
          text: "Add",
        },
      });
      // selected_areas.current = [];
      setShowModal(false);
    }
  };

  const handleDaySelect = (date) => {
    setSelectedDay(date);
    setShowDayViewModal(true);
  };

  const closeDayViewModal = () => setShowDayViewModal(false);

  const eventStyle = (event, start, end, isSelected) => {
    let newStyle = {
      backgroundColor: "white",
      color: "white",
      borderRadius: "0px",
      border: "2px solid white",
      display: "block",
      padding: "2px 5px",
    };

    if (event.isMine) {
      newStyle.backgroundColor = "white";
    }

    return {
      className: "text-white",
      style: newStyle,
    };
  };

  return (
    <div className="ml-[30px]">
      <DayViewModal
        isOpen={showDayViewModal}
        onRequestClose={closeDayViewModal}
        date={selectedDay}
        events={events}
      />
      <div className="bodyContainer">
        <div className="well well-sm mb-[3%] ml-[00px]">
          <p
            style={{
              color: "#403151",
              fontFamily: "",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            Tap on the calendar dates to set your lesson plans
          </p>
          <li
            style={{
              color: "#403151",
              fontFamily: "",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            {location.state.subject_status_have_lesson === 0
              ? "Set the topic areas you'll be teaching that day."
              : "Set the topic lessons or concepts you'll be teaching that day."}
          </li>
        </div>

        <BigCalendar
          selectable
          events={events}
          views={{ month: true, week: true }}
          step={100}
          showMultiDayTimes
          onNavigate={handleDaySelect}
          components={{
            event: (eventProps) => (
              <Event {...eventProps} loading={loadingEventId} />
            ),
          }}
          defaultDate={new Date(moment())}
          onSelectEvent={(event) => handleShow(event, "edit")}
          onSelectSlot={(slotInfo) => handleShow(slotInfo, "add")}
          style={{
            minHeight: "700px",
            width: "100%",
            fontFamily: "Roboto",
            border: "1px solid #79747E",
            fontSize: "16px",
          }}
          eventPropGetter={eventStyle}
          startAccessor="start"
          endAccessor="end"
        />

        <EventDetails
          showModal={showModal}
          handleHide={handleHide}
          eventType={eventType}
          eventInfo={eventInfo}
          newIndex={newIndex}
          deleteEvent={deleteEvent}
          addEvent={addEvent}
          updateEvent={updateEvent}
          date={selectedDay}
          subject_id={props.subject_id}
          newTopic={props.newTopic}
          handleValueChangeTopic={handleValueChangeTopic}
          handleValueChangeSubTopic={handleValueChangeSubTopic}
          handleValueChangeArea={handleValueChangeArea}
          handleValueChangeSelectAll={handleValueChangeSelectAll}
          selectedTopic={selected_topic.current}
          selectedSubTopic={selected_subtopic.current}
          selectedArea={selected_areas.current}
          data={data.current}
        />
      </div>
    </div>
  );
};

export default EventCalendar;
