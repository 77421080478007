import React, { Fragment, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Button from "@mui/material/Button";
import YesNoDialog from "./YesNoDialog";
import TopicAccordions from "./TopicAccordian";
import SubAccordions from "./SubAccordian"; // Import SubAccordions

function ViewSubjectDetails(props) {
  var lesson_stat = localStorage.getItem("lesson_status");
  const [deletingObject, setDeletingObject] = useState([null, null]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const onDeleteClick = (object, type) => {
    setOpenConfirmationDialog(true);
    setDeletingObject([object, type]);
  };

  const handleCloseWithNo = () => {
    setOpenConfirmationDialog(false);
  };

  const handleCloseWithYes = () => {
    setOpenConfirmationDialog(false);
    var type = deletingObject[1];
    var id = null;
    if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_CHAPTER) {
      id = deletingObject[0].topic_id;
    } else if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_SUPER) {
      id = deletingObject[0].super_topic_id;
    } else if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_TOPIC) {
      id = deletingObject[0].sub_topic_id;
    } else if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_SUBTOPIC) {
      id = deletingObject[0].area_id;
    }

    sendDeleteRequest(type, id);
  };

  const sendDeleteRequest = (type, object_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_content", {
        method: "POST",
        type: type,
        id: object_id,
      })
      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addNewChapter = (subject_id, chapter) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    if (lesson_stat === "0") {
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_chapter", {
          method: "POST",
          super_topic_id: null,
          subject_id: subject_id,
        })
        .then((response) => {
          props.addTodo();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(
          process.env.REACT_APP_REST_API_BASE_URL + "/create_new_super_topic",
          {
            method: "POST",
            subject_id: subject_id,
          }
        )
        .then((response) => {
          props.addTodo();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <YesNoDialog
        handleCloseWithNo={handleCloseWithNo}
        handleCloseWithYes={handleCloseWithYes}
        open={openConfirmationDialog}
        title={"Confirm"}
        description={"Are you sure you want to delete this record?"}
      />
      <div className="form-div">
        <div className="ml-[30px] mr-[30px] w-full">
          {props.subject_data?.map((subject, subjectIndex) => (
            <Fragment key={subjectIndex}>
              <div>
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    {subject[
                      lesson_stat === "0"
                        ? "subject_has_topic_list"
                        : "subject_has_super_topic_list"
                    ]?.map((chapter, chapterIndex) => (
                      <Fragment key={chapterIndex}>
                        <div>
                          <div
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              width: "100%",
                              marginBottom: "30px",
                              backgroundColor: "#F0F0F0",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                fontFamily: "Roboto",
                                color: "#49454F",
                              }}
                            >
                              {lesson_stat === "0" ? (
                                <b>Topic Name:</b>
                              ) : (
                                <b>Topic Name: </b>
                              )}
                            </p>
                            <p>
                              {lesson_stat === "0" ? (
                                <SubAccordions
                                  addTodo={props.addTodo}
                                  title={chapter}
                                  subject={subject}
                                  has_sub_topics={
                                    subject.topic_has_sub_topic_list
                                  }
                                  subject_id={subject.subject_id}
                                  onDeleteClick={onDeleteClick}
                                  sub_topics_list={
                                    chapter["topic_has_sub_topic_list"]
                                  }
                                  predefined_question_answer_status={
                                    props.predefined_question_answer_status
                                  }
                                  chapter={props.title}
                                />
                              ) : (
                                <TopicAccordions
                                  addTodo={props.addTodo}
                                  title={chapter}
                                  subject={subject}
                                  has_sub_topics={
                                    subject.topic_has_sub_topic_list
                                  }
                                  subject_id={subject.subject_id}
                                  onDeleteClick={onDeleteClick}
                                  topics_list={
                                    chapter["super_topic_has_topic_list"]
                                  }
                                  predefined_question_answer_status={
                                    props.predefined_question_answer_status
                                  }
                                />
                              )}
                            </p>
                          </div>
                        </div>

                        <div
                          style={{
                            flex: 1,
                            height: "1px",
                            backgroundColor: "#B3B3B3",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        />
                      </Fragment>
                    ))}
                  </p>
                </div>
              </div>
              <Button
                onClick={() => {
                  addNewChapter(subject.subject_id, subject.chapter_id);
                }}
                className="delete-button"
                style={{ color: "#1976d2", padding: "6px 8px" }}
              >
                {lesson_stat === "0" ? "Add New Topic" : "Add New  topic"}
              </Button>
              <div
                style={{
                  flex: 1,
                  height: "1px",
                  backgroundColor: "#B3B3B3",
                  marginTop: "100px",
                  marginBottom: "10px",
                }}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewSubjectDetails;
