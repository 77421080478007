import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";

import appContext from "../../context/appContext";
import TeacherHomeTabs from "./TeacherHomeTabs";
import ClassroomDetailBanner from "./widgets/ClassroomDetailBanner";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useGetClassAnalytics from "../../Components/Endpoints/GetClassAnalytics";
import useGetClassStudentAnalytics from "../../Components/Endpoints/GetCLassStudentAnalytics";
import useGetClassIndividualStudentAnalytics from "../../Components/Endpoints/GetIndividualStudentAnalytics";

function TeacherClassroomHome() {
  const location = useLocation();
  const state = useContext(appContext);
  const search = window.location.search;
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const [classroomData, setClassroomData] = useState({});
  const [studentData, setStudentData] = useState();
  const [studentId, setStudentId] = useState(null);
  const getClassAnalytics = useGetClassAnalytics();
  const getClassStudentAnalytics = useGetClassStudentAnalytics();
  const getClassIndividualStudentAnalytics =
    useGetClassIndividualStudentAnalytics();
  const [data2, setData2] = useState();
  const [analyticsData, setAnalyticsData] = useState();
  const [individualAnalyticsData, setIndividualAnalyticsData] = useState();
  var class_id = params.get("id");

  const removeStudent = (index) => {
    console.log("REMOVED CALLED");
    var array = [...studentData]; // make a separate copy of the array

    //var index = e.target.value;
    if (index !== -1) {
      array.splice(index, 1);
      setStudentData(array);
    }
  };

  useEffect(() => {
    getClassData();
    getClassAnalytics(setData2, class_id);
    if (studentId === null) {
      getClassStudentAnalytics(setAnalyticsData, class_id);
      setIndividualAnalyticsData(null);
    } else {
      getClassIndividualStudentAnalytics(
        setIndividualAnalyticsData,
        class_id,
        studentId
      );
      setAnalyticsData(null);
    }
  }, [class_id, studentId]);

  const getClassData = () => {
    if (localStorage.getItem("teacher_table_state") === null) {
      localStorage.setItem("teacher_table_state", 1);
    }
    localStorage.setItem("quizTabState", 1);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/view_class", {
        method: "POST",
        class_id: class_id,
      })
      .then((res) => {
        setClassroomData(res.data);
        setStudentData(res.data.student);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Sidebar />

      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[315px] mt-[0%]">
        <ClassroomDetailBanner classroomData={classroomData} />

        <div id="tabs" className="  pt-[28px] md:pl-[40px]">
          <TeacherHomeTabs
            classroomData={classroomData}
            studentData={studentData}
            removeStudent={removeStudent}
            getClassData={getClassData}
            class_id={class_id}
            data2={data2}
            analyticsData={
              analyticsData ? analyticsData : individualAnalyticsData
            }
            setStudentId={setStudentId}
            studentId={studentId}
          />
        </div>
      </div>
    </div>
  );
}

export default TeacherClassroomHome;
