import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import Cross from "../../../images/cross.png";

function ConfirmationDialogue(props) {
  const title = props.title;
  const dialogueText = props.text;
  const handleCloseWithConfirm = props.handleCloseWithConfirm;
  const handleCloseWithCancel = props.handleCloseWithCancel;
  const open = props.open;
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "370px",
          height: "224px",
          padding: "10px",
        },
      }}
      open={open}
      onClose={handleCloseWithCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="flex justify-between">
        {title}
        <button onClick={() => handleCloseWithCancel()}>
          <img src={Cross}></img>
        </button>
      </DialogTitle>
      <DialogContent className="overflow-hidden">
        <DialogContentText id="alert-dialog-description">
          {dialogueText}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="mb-[20px]">
        <button
          className="dialogue-button-text mr-[51px]"
          onClick={() => handleCloseWithCancel()}
        >
          Cancel
        </button>
        <button
          className="teepee-button mr-[20px] "
          onClick={() => handleCloseWithConfirm()}
        >
          Confirm
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialogue;
