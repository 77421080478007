import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import Sidebar from "../../Components/Sidebar";
import EdiText from "react-editext";
import ViewSubjectDetails from "./ViewSubjectDetials";
import { useNavigate } from "react-router-dom";

function SubjectsContentContainer() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const [subjectData, setSubjectData] = React.useState(null);
  const [subjectDataFormative, setSubjectDataFormative] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getSubjectDetails(id);
  }, [id]);

  function getSubjectDetails(subject_id) {
    var statuss = localStorage.getItem("lesson_status");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects_data_complete",
        {
          method: "POST",
          subject_id: subject_id,
          subject_status_have_lesson: parseInt(statuss),
        }
      )

      .then((response) => {
        console.log("DATA IN THE JSON: ", response.data["subject"]);
        var subjects_list = response.data["subject"];

        console.log("EXAM STYLE: ", subjects_list);

        setSubjectData(subjects_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const addTodo = () => {
    getSubjectDetails(id);
  };

  const onSaveSubject = (value, subject) => {
    // alert(subject.subject_name);
    subject.subject_name = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_subject", {
        method: "POST",
        subject_name: subject.subject_name,
        subject_id: subject.subject_id,
      })

      .then((response) => {
        // var subjects_list = JSON.parse(
        //   response.data["subject"]
        // );
        //console.log(subjects_list);
        // setSubjectData(subjects_list)
        // alert("Subject Updated");
      })
      .catch((error) => {
        console.log(error);
        // alert("unable to update subject");
      });
  };

  return (
    <>
      <Sidebar item_id="view_subject" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label
              className="dashboard-label"
              onClick={() => {
                navigate("/view_subject");
              }}
            >
              back
            </label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className=" flex-row justify-left items-center sm:pl-[10px] sm:pr-[10px] md:pl-[60px] md:pr-[40px] mt-[20px]">
          {subjectData?.map((subject, subjectIndex) => (
            <div>
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  color: "#49454F",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  <EdiText
                    submitOnEnter={false}
                    type="text"
                    value={subject.subject_name}
                    onSave={(value) => onSaveSubject(value, subject)}
                  />
                </p>
              </p>
            </div>
          ))}
          {subjectData && (
            <Tabs>
              <TabList>
                <Tab>Add Subject Content </Tab>
              </TabList>

              <TabPanel>
                <ViewSubjectDetails
                  addTodo={addTodo}
                  subject_data={subjectData}
                  predefined_question_answer_status={1}
                />
              </TabPanel>
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
}

export default SubjectsContentContainer;
