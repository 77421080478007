import React from "react";
import * as RiIcons from "react-icons/ri";
import MyQuizLogo from "../images/ic_my_quizzes.svg";
import UsersLogo from "../images/user.png";
import FeedbackLogo from "../images/userfeedback.png";
import ShareFeedbackLogo from "../images/feedback.png";
import ViewSubjectsLogo from "../images/sbj.png";
import UsersFeedbackLogo from "../images/users.png";
import EvaluationFeedbackLogo from "../images/thumb_up.png";
import Home from "../images/icon.png";
import Tracking from "../images/Tracking.png";
import OnBoard from "../images/onboard.png";
import Video from "../images/video.png";
import Tutorials from "../images/sound_sampler.png";

export const SidebarData = [
  {
    id: "quiz",
    title: "Home",
    path: "/",
    backgroundColor: "transparent",
    icon: Home,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  // {
  //   id: "folder",
  //   title: "My Folders",
  //   path: "/dashboard/folders",
  //   backgroundColor: "transparent",
  //   icon: MyQuizLogo,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  {
    id: "user_option",
    title: "Users",
    backgroundColor: "transparent",
    path: "/users",
    icon: UsersLogo,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "tutorials",
    title: "Tutorials",
    backgroundColor: "transparent",
    path: "/tutorials",
    icon: Tutorials,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "tracking_analytics",
    title: "Tracking analytics",
    backgroundColor: "transparent",
    path: "/tracking_analytics",
    icon: Tracking,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "report_problem",
    title: "Share feedback",
    path: "/report_problem",
    icon: ShareFeedbackLogo,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "view_subject",
    title: "View Subjects",
    path: "/view_subject",
    icon: ViewSubjectsLogo,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "view_onboarding_subject",
    title: "Onboarding",
    path: "/onboarding-subject",
    icon: OnBoard,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: "view_onboarding_subject",
        title: "Onboarding Subjects",
        path: "/onboarding-subject",
        icon: OnBoard,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "interest_analytics",
        title: "Interest Analytics",
        path: "/interest-analytics",
        icon: EvaluationFeedbackLogo,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "onboarding_video",
        title: "Onboarding Videos",
        path: "/onboarding-video",
        icon: Video,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
    ],
  },

  // {
  //   id:"view_subject_detail",
  //   title: "View Subject Detail",
  //   path: "/view_subject_detail",
  //   icon: FeedbackLogo,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },

  {
    id: "users_feedback",
    title: "Feedback",
    path: "/teacher_feedback",
    icon: FeedbackLogo,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: "users_feedback",
        title: "Teacher Feedback",
        path: "/teacher_feedback",
        icon: UsersFeedbackLogo,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "evaluation_feedback",
        title: "Student Feedback",
        path: "/student_feedback",
        icon: EvaluationFeedbackLogo,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
    ],
  },
];
