import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import TeacherDashboardTabBody from "./TeacherDashboardTabBody";
import TeacherStudentTabBody from "./widgets/TeacherStudentTabBody";
import TeacherAnalyticsTabBody from "./TeacherAnalyticsTabBody";
import useGetClassAnalytics from "../../Components/Endpoints/GetClassAnalytics";
import useGetClassStudentAnalytics from "../../Components/Endpoints/GetCLassStudentAnalytics";
function TeacherHomeTabs(props) {
  const [tabIndex, setTabIndex] = useState(1);

  const data = [
    {
      label: "Dashboard",
      value: 1,
      body: (
        <>
          {tabIndex === 1 ? (
            <TeacherDashboardTabBody
              data={props.data2}
              classroomData={props.classroomData}
              getClassData={props.getClassData}
            />
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      label: "Students",
      value: 2,
      body: (
        <>
          {tabIndex === 2 ? (
            <TeacherStudentTabBody
              classroomData={props.classroomData}
              studentData={props.studentData}
              removeStudent={props.removeStudent}
            />
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      label: "Analytics",
      value: 3,
      body: (
        <>
          {tabIndex === 3 ? (
            <TeacherAnalyticsTabBody
              data={props.analyticsData}
              setStudentId={props.setStudentId}
              studentId={props.studentId}
            />
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  const handleClick = (selectedTab) => {
    setTabIndex(selectedTab);
  };

  return (
    <Tabs
      id="custom-animation"
      value={tabIndex}
      className="md:mr-[24px] sm:mr-2 md:ml-0 sm:ml-2 "
    >
      <div
        className="float-right "
        style={{
          borderRadius: "40px",

          textAlign: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <TabsHeader
          className="w-[100%] h-[60px] flex  flex-row  bg-[#DBD2DD]  "
          style={{ borderRadius: "10px", position: "sticky" }}
          indicatorProps={{
            className:
              "bg-[#AE95B5] text-[white] shadow-none mix-blend-difference ",
          }}
        >
          {data.map(({ label, value }) => (
            <div
              className="!z-0"
              onClick={() => handleClick(value)}
              // style={{ width: "100%" }}
            >
              <Tab
                key={value}
                value={value}
                className={`text-center cursor-pointer ${
                  tabIndex === value ? "text-white" : ""
                }`}
                onClick={() => handleClick(value)}
              >
                <div
                  className="flex justify-center  items-center p-[12px] md:pl-[28px] md:pr-[28px] sm:pr-[14px] sm:pl-[14px]"
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    letterSpacing: "0.016px",
                  }}
                >
                  {label}
                </div>
              </Tab>
            </div>
          ))}
        </TabsHeader>
      </div>

      <TabsBody animate={{}}>
        {data.map(({ value, body }) => (
          <TabPanel key={value} value={value}>
            {body}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default TeacherHomeTabs;
