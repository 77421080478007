import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import EdiText from "react-editext";
import axios from "axios";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Cross from "../../images/cross.png";
import PrefinedQuestions from "./PredefinedQuestions";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";
import ConceptAccordian from "./ConceptAccordian";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function EditableSubTopicAccordion(props) {
  const navigate = useNavigate();

  const [count, setCount] = React.useState(0);
  const [expand, setExpand] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [questionId, setQuestionId] = React.useState();
  var stats = localStorage.getItem("lesson_status");
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  React.useEffect(() => {
    setCount(props.sub_topic.content);
  }, [props.sub_topic.content]);

  const onSaveSubTopicName = (value, subTopic) => {
    subTopic.sub_topic_name = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_topic", {
        method: "POST",
        sub_topic_name: value,
        sub_topic_id: subTopic.sub_topic_id,
      })

      .then((response) => {
        // alert("Area updated");
      })
      .catch((error) => {
        console.log(error);
        // alert("unable to update Area");
      });
  };

  const removeQuestion = (predefined_question_answer_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    console.log("Question params ID:", predefined_question_answer_id);
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;

    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/delete_predefined_question",
        {
          method: "POST",
          predefined_question_answer_id: predefined_question_answer_id,
        }
      )

      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onAddNewQuestionBtnClick = () => {
    var statuss = localStorage.getItem("lesson_status");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_sub_topic", {
        method: "POST",
        sub_topic_id: props.sub_topic.sub_topic_id,
        subject_status_have_lesson: parseInt(statuss),
        // question_marks: 1,
        // question: "",
        // correct_feedback: "",
        // incorrect_feedback: "",
        // question_importance: 1,
        // right_option_id: null,
        // image_file_name: null,
      })

      .then((response) => {
        props.addTodo();

        // alert("successfully inserted new question");
      })
      .catch((error) => {
        console.log(error);
        // alert("failed inserted new question");
      });
  };

  const onSaveSubTopicContent = (value, subTopic) => {
    subTopic.content = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_content",
        {
          method: "POST",
          sub_topic: subTopic,
        }
      )

      .then((response) => {})
      .catch((error) => {
        console.log(error);
        // alert("unable to update sub topic");
      });
  };

  const handleOpenDeleteDialog = (predefined_question_answer_id) => {
    setQuestionId(predefined_question_answer_id);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    setOpenDeleteDialog(false);
    removeQuestion(questionId);
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "370px",
            height: "224px",
            padding: "10px",
          },
        }}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title" className="flex justify-between">
          <b>Delete</b> {/* Adjusted to match the provided example */}
          <button onClick={handleCloseDeleteDialog}>
            <img src={Cross} alt="Close" />{" "}
            {/* Replace 'Cross' with your image path */}
          </button>
        </DialogTitle>
        <DialogContent className="overflow-hidden">
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this Question? {/* Adjusted text */}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-[20px]">
          <span style={{ marginRight: "46px" }}>
            <Button
              onClick={handleCloseDeleteDialog}
              className="dialogue-button-text"
              style={{
                color: "#7E418B",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.1px",
              }}
            >
              Cancel
            </Button>
          </span>
          <Button
            onClick={handleConfirmDelete}
            className="teepee-button"
            style={{
              color: "white",
              backgroundColor: "#7E418B",
              padding: "10px",
              paddingLeft: "24px",
              paddingRight: "24px",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px",
              letterSpacing: "0.1px",
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Accordion expanded={expand}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            onClick: toggleAcordion,
          }}
          style={{
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: 0,
            },
            padding: 0,
          }}
        >
          <Typography className="w-full">
            {" "}
            <EdiText
              editOnViewClick
              className="w-full"
              type="text"
              onSave={(value) => onSaveSubTopicName(value, props.sub_topic)}
              value={props.sub_topic.sub_topic_name}
            />
          </Typography>

          <Button
            style={{ float: "right" }}
            onClick={() =>
              props.onDeleteClick(
                props.sub_topic,
                process.env.REACT_APP_DELETING_OBJECT_TYPE_TOPIC
              )
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p
              style={{
                display: props.chapter?.ai_enabled ? "block" : "none",
              }}
            >
              <EdiText
                type="textarea"
                inputProps={{
                  onChange: (e) => {
                    setCount(e.target.value.length);
                  },
                  className: "textarea",
                  placeholder: "Type your content here",
                  style: {
                    width: "100%",
                    height: "100%",
                    padding: "5px",
                  },
                  rows: 10,
                }}
                value={props.sub_topic.sub_topic_name}
                onSave={(value) =>
                  onSaveSubTopicContent(value, props.sub_topic)
                }
              />
            </p>

            <p
              style={{
                display: props.chapter?.ai_enabled ? "none" : "block",
                fontSize: "14px",
                fontFamily: "Roboto",
                color: "#49454F",
              }}
            >
              {props.sub_topic["sub_topic_has_area_list"]?.map(
                (topic, subIopicIndex) => (
                  <Fragment key={subIopicIndex}>
                    <div>
                      <p
                        style={{
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                        }}
                      >
                        {stats === "0" ? <b>Areas:</b> : <b>Concepts:</b>}
                        {
                          <ConceptAccordian
                            addTodo={props.addTodo}
                            title={topic}
                            chapter={props.title}
                            subject={props.subject}
                            has_sub_topics={props.has_sub_topics}
                            onDeleteClick={props.onDeleteClick}
                            sub_topics_list={
                              props.sub_topic["sub_topic_has_area_list"]
                            }
                            predefined_question_answer_status={
                              props.predefinedQuestionsPromptList
                            }
                            predefined_question_answer_list={
                              props.sub_topic["sub_topic_has_area_list"]
                            }
                            removeQuestion={handleOpenDeleteDialog}
                          />
                        }{" "}
                      </p>
                    </div>
                  </Fragment>
                )
              )}
              {/* <PrefinedQuestions
                addTodo={props.addTodo}
                removeQuestion={handleOpenDeleteDialog}
                predefined_question_answer_list={
                  props.sub_topic["sub_topic_has_area_list"]
                }
                predefinedQuestionsPromptList={
                  props.predefinedQuestionsPromptList
                }
              /> */}
            </p>

            <Button
              style={{
                display: "block",
                color: "#1976d2",
                padding: "6px 8px",
                display: "flex",
                gap: "8px",
                color: "#1976d2",
              }}
              onClick={() => onAddNewQuestionBtnClick()}
            >
              {/* <span className="pt-[1%]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 10 11"
                  fill="none"
                >
                  <path
                    d="M4.5 7.59961H5.5V5.59961H7.5V4.59961H5.5V2.59961H4.5V4.59961H2.5V5.59961H4.5V7.59961ZM5 10.0996C4.30833 10.0996 3.65833 9.96836 3.05 9.70586C2.44167 9.44336 1.9125 9.08711 1.4625 8.63711C1.0125 8.18711 0.65625 7.65794 0.39375 7.04961C0.13125 6.44128 0 5.79128 0 5.09961C0 4.40794 0.13125 3.75794 0.39375 3.14961C0.65625 2.54128 1.0125 2.01211 1.4625 1.56211C1.9125 1.11211 2.44167 0.755859 3.05 0.493359C3.65833 0.230859 4.30833 0.0996094 5 0.0996094C5.69167 0.0996094 6.34167 0.230859 6.95 0.493359C7.55833 0.755859 8.0875 1.11211 8.5375 1.56211C8.9875 2.01211 9.34375 2.54128 9.60625 3.14961C9.86875 3.75794 10 4.40794 10 5.09961C10 5.79128 9.86875 6.44128 9.60625 7.04961C9.34375 7.65794 8.9875 8.18711 8.5375 8.63711C8.0875 9.08711 7.55833 9.44336 6.95 9.70586C6.34167 9.96836 5.69167 10.0996 5 10.0996ZM5 9.09961C6.11667 9.09961 7.0625 8.71211 7.8375 7.93711C8.6125 7.16211 9 6.21628 9 5.09961C9 3.98294 8.6125 3.03711 7.8375 2.26211C7.0625 1.48711 6.11667 1.09961 5 1.09961C3.88333 1.09961 2.9375 1.48711 2.1625 2.26211C1.3875 3.03711 1 3.98294 1 5.09961C1 6.21628 1.3875 7.16211 2.1625 7.93711C2.9375 8.71211 3.88333 9.09961 5 9.09961Z"
                    fill="#49454F"
                  />
                </svg>
              </span>{" "} */}
              {stats === "0" ? "Add Areas" : "Add Concepts"}
            </Button>

            <div className="mt-[40px]"></div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
