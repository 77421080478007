import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Cross from "../../images/cross.png";
import { Link } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import del from "../../images/delete.png";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { ToastHeader } from "react-bootstrap";
function ViewSubject() {
  const columns = [
    {
      subject_id: "subject_id",
      id: "subject_name",
      label: "Subject Name",
      status: "status",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteSubjectId, setDeleteSubjectId] = useState(null);
  const [rows, setRows] = useState([]);
  const [show, setShow] = useState(false);
  const [heading, setHeading] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [open, setOpen] = React.useState(false);
  const [newId, setNewId] = React.useState();
  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  const [sbj, setSbj] = useState("");
  const [checked, setChecked] = React.useState(true);
  const [editingSubjectId, setEditingSubjectId] = useState(null);
  const [editingValue, setEditingValue] = useState("");
  const [toggleStates, setToggleStates] = useState({});
  const toggleColor = "#7E418B"; // Custom color

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (userType === "1") {
      navigate("/view_subject");
      return;
    }
  }, [navigate, userType]);

  // const onChange = (e) => {
  //   setCreateUser({ ...createUser, [e.target.name]: e.target.value });
  // };

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_REST_API_BASE_URL}/get_subjects_for_admin`,
        headers: { "Content-Type": "application/json; charset=utf-8" },
      })
      .then((res) => {
        const subjects = JSON.parse(res.data).map((subject) => ({
          ...subject,
          toggleState: parseInt(subject.subject_status_have_lesson, 10),
        }));
        setRows(subjects);
      });
  };
  useEffect(() => {
    getSubjectsList();
  }, []);

  const isSubmitting = useRef(false);
  function submitForm() {
    var token = "Bearer " + localStorage.getItem("access_token");

    var subject_name = document.getElementById("subject_name").value;

    //const sub_topic_id_list = [1, 2]
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_subject", {
        method: "POST",
        subject_name: subject_name,
        has_sub_topics: checked,
      })

      .then((response) => {
        console.log(response);

        isSubmitting.current = false;

        // alert("New subject created successfully");
        document.getElementById("subject_name").value = "";
        setShow(true);
        setHeading("Created");
        setParagraph("Awesome! Subject  has been Created. 🎉");
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleToggleChange = async (subjectId) => {
    const currentToggleState = rows.find(
      (row) => row.subject_id === subjectId
    ).toggleState;
    const newToggleState = currentToggleState === 1 ? 0 : 1;

    try {
      var token = "Bearer " + localStorage.getItem("access_token");
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_subject_have_lesson_status`,
        {
          subject_id: subjectId,
          subject_status_have_lesson: newToggleState,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (response.data) {
        setRows(
          rows.map((row) =>
            row.subject_id === subjectId
              ? { ...row, toggleState: newToggleState }
              : row
          )
        );
      } else {
        console.error(
          "API call succeeded but failed to update status:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Failed to update subject status", error);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    setOpen(false);
    submitForm();
  };

  const handleSubmitOptionClick = (event) => {
    //setUserMessage(param1)
    //setPrompt(param2)
    var subject_name = document.getElementById("subject_name").value;

    if (subject_name.length > 0) {
    } else {
      alert("Please enter all details");
      // resolve();
      return;
    }
    setOpen(true);
  };

  const updateButtonStatus = (status, subject_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_subject_status",
        {
          method: "POST",
          subject_id: subject_id,
          status: status,
        }
      )

      .then((response) => {
        // alert("Subject Status changed");

        getSubjectsList();
        setShow(true);
        setHeading("Status");
        setParagraph("Awesome! Subject status has been changed. 🎉");
      })
      .catch((error) => {
        console.log(error);
        // alert("unable to update subject status");
      });
  };

  const updateExamPaperStatusButton = (subject_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_subject", {
        method: "POST",
        subject_id: subject_id,
      })

      .then((response) => {
        // alert("Subject Deleted");
        setShow(true);
        setHeading("Deleted");
        setParagraph("Awesome! Subject  has been Deleted. 🎉");
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        // alert("Subject Deleted");
      });
  };
  const handleOpenDeleteDialog = (subjectId) => {
    setDeleteSubjectId(subjectId);
    setOpenDeleteDialog(true);
  };

  const deleteSubject = () => {
    // Your existing logic for deleting a subject
    // Use deleteSubjectId for the subject ID
    // After deletion:
    updateExamPaperStatusButton(deleteSubjectId);
    setOpenDeleteDialog(false); // Close the dialog
    getSubjectsList(); // Refresh the list
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    deleteSubject();
  };

  async function insertSubjectSetting(id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/get_subjects_settings`,
        {
          method: "POST",
          subject_id: id,
        }
      );

      console.log("DATA IN THE JSON2: ", response.data["subject"]);
      var subjects_list = response.data["subject"];

      console.log("EXAM STYLE: ", subjects_list);

      const sbj = subjects_list[0]?.subject_id;

      if (sbj === id) {
        navigate("/subject_settings?id=" + id);
      } else {
        try {
          await axios.post(
            `${process.env.REACT_APP_REST_API_BASE_URL}/create_subjects_settings`,
            {
              method: "POST",
              subject_id: id,
              r1: "",
              r2: "",
              r3: "",
              r2_days: "",
              r3_days: "",
              total_quiz_questions: 0,
              toggle_for_days: 0,
              toggle_button_day: 0,
              time: 1,
            }
          );
          navigate("/subject_settings?id=" + id);
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleEditClick = (subjectId, subjectName) => {
    setEditingSubjectId(subjectId);
    setEditingValue(subjectName);
  };

  const handleSaveClick = (subjectId) => {
    console.log("Editied value", editingValue);
    // Logic to save the updated subject name
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_subject", {
        method: "POST",
        subject_name: editingValue,
        subject_id: subjectId,
      })

      .then((response) => {
        // alert("Subject Deleted");
        getSubjectsList();
        setShow(true);
        setHeading("Updated");
        setParagraph("Awesome! Subject  has been Updated. 🎉");
      })
      .catch((error) => {
        console.log(error);
        // alert("Subject Deleted");
      });
    // After saving, reset the editing state
    setEditingSubjectId(null);
    setEditingValue("");
    // Refresh your subject list here
    // getSubjectsList();
  };
  return (
    <>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast
          style={{ backgroundColor: "#f7f2f9" }}
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <ToastHeader>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_99_1907"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_99_1907)">
                <path
                  d="M9.54961 18.0001L3.84961 12.3001L5.27461 10.8751L9.54961 15.1501L18.7246 5.9751L20.1496 7.4001L9.54961 18.0001Z"
                  fill="#0AB30A"
                />
              </g>
            </svg>
            <span className="snackbarh" style={{ marginRight: "10%" }}>
              {heading}
            </span>
          </ToastHeader>
          <Toast.Body>
            <span className="snackbarp">{paragraph}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Dialog
        PaperProps={{
          sx: {
            width: "370px",
            height: "224px",
            padding: "10px",
          },
        }}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title" className="flex justify-between">
          <b>Delete</b>
          <button onClick={handleCloseDeleteDialog}>
            <img src={Cross} alt="Close"></img>{" "}
            {/* Replace 'Cross' with your image path */}
          </button>
        </DialogTitle>
        <DialogContent className="overflow-hidden">
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this Subject?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-[20px]">
          <span style={{ marginRight: "46px" }}>
            {" "}
            <Button
              onClick={handleCloseDeleteDialog}
              className="dialogue-button-text mr-[51px]"
              style={{
                color: "#7E418B",

                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px" /* 142.857% */,
                letterSpacing: "0.1px",
              }}
            >
              Cancel
            </Button>
          </span>

          <Button
            onClick={handleConfirmDelete}
            className="teepee-button mr-[20px]"
            style={{
              textAlign: "center",
              /* M3/label/large */
              color: "white",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px" /* 142.857% */,
              letterSpacing: "0.1px",
              backgroundColor: "#7E418B",
              padding: "10px",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to create new subject?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button
            className="delete-button"
            onClick={handleCloseWithYes}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Sidebar item_id="view_subject" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[315px] ">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Add New Subject</label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="110"
              height="14"
              viewBox="0 0 110 14"
              fill="none"
            >
              <path
                d="M2.5 14C2.5 12.3431 3.84315 11 5.5 11H104.5C106.157 11 107.5 12.3431 107.5 14V14H2.5V14Z"
                fill="#403151"
              />
            </svg>
          </div>
        </div>
        <div className="flex flex-row md:ml-[60px] items-center ">
          <div className=""></div>
          <div className="selectionDiv sm:w-[100%] md:w-[600px]">
            <div className="form-div">
              <div className="md:w-[600px]">
                <div>
                  <label className="create_quiz_label">
                    Enter subject name
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="subject_name"
                    id="subject_name"
                    className="teepee-input"
                    placeholder=""
                    required
                  />
                </div>
                {/* <div>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <label className="create_quiz_label">Has subtopics</label>
                </div> */}

                <button
                  onClick={handleSubmitOptionClick}
                  disabled={isSubmitting.current}
                  className="teepee-button mt-[10px] hover:bg-black"
                  style={{ width: "46%", backgroundColor: "#7E418B" }}
                >
                  {isSubmitting.current && (
                    <div
                      className="spinner-border spinner-border-m float-left"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-left items-center sm:ml-[10px] md:ml-[60px] mt-[20px] sm:mr-[10px] md:mr-[60px]">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 840 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#f7f2f9",
                        }}
                      >
                        <b>{column.label}</b>
                      </TableCell>
                    ))}
                    <TableCell
                      key={"prompt"}
                      align={"center"}
                      style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}
                    >
                      <b>Settings</b>
                    </TableCell>
                    <TableCell
                      key={"action"}
                      align={"center"}
                      style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}
                    >
                      <b>Action</b>
                    </TableCell>
                    <TableCell
                      key={"action2"}
                      align={"right"}
                      style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}
                    >
                      {""}
                    </TableCell>

                    <TableCell
                      key={"action3"}
                      align={"right"}
                      style={{
                        minWidth: "50px",
                        backgroundColor: "#f7f2f9",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      key={"action4"}
                      align={"right"}
                      style={{
                        minWidth: "50px",
                        backgroundColor: "#f7f2f9",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Lessons/Concepts Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          id={row["subject_id"]}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            if (column.id === "subject_name") {
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    maxWidth: column.maxWidth,
                                    overflowWrap: "break-word",
                                    borderLeft:
                                      "1px solid rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  {editingSubjectId === row.subject_id ? (
                                    <>
                                      <input
                                        type="text"
                                        value={editingValue}
                                        onChange={(e) =>
                                          setEditingValue(e.target.value)
                                        }
                                      />
                                      <button
                                        onClick={() =>
                                          handleSaveClick(row.subject_id)
                                        }
                                      >
                                        ✔
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <span style={{ fontFamily: "Roboto" }}>
                                        <b> {value}</b>
                                      </span>

                                      <button
                                        onClick={() =>
                                          handleEditClick(row.subject_id, value)
                                        }
                                        style={{ marginLeft: "15px" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <mask
                                            id="mask0_187_581"
                                            style={{ maskType: "alpha" }}
                                            maskUnits="userSpaceOnUse"
                                            x="0"
                                            y="0"
                                            width="16"
                                            height="16"
                                          >
                                            <rect
                                              width="16"
                                              height="16"
                                              fill="#D9D9D9"
                                            />
                                          </mask>
                                          <g mask="url(#mask0_187_581)">
                                            <path
                                              d="M3.33333 12.6667H4.28333L10.8 6.15L9.85 5.2L3.33333 11.7167V12.6667ZM2 14V11.1667L10.8 2.38333C10.9333 2.26111 11.0806 2.16667 11.2417 2.1C11.4028 2.03333 11.5722 2 11.75 2C11.9278 2 12.1 2.03333 12.2667 2.1C12.4333 2.16667 12.5778 2.26667 12.7 2.4L13.6167 3.33333C13.75 3.45556 13.8472 3.6 13.9083 3.76667C13.9694 3.93333 14 4.1 14 4.26667C14 4.44444 13.9694 4.61389 13.9083 4.775C13.8472 4.93611 13.75 5.08333 13.6167 5.21667L4.83333 14H2ZM10.3167 5.68333L9.85 5.2L10.8 6.15L10.3167 5.68333Z"
                                              fill="#49454F"
                                            />
                                          </g>
                                        </svg>
                                      </button>
                                    </>
                                  )}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{
                                    minWidth: column.minWidth,
                                    maxWidth: column.maxWidth,
                                    overflowWrap: "break-word",
                                    borderLeft:
                                      "1px solid rgba(224, 224, 224, 1)",
                                    fontFamily: "Roboto",
                                  }}
                                >
                                  <b> {value}</b>
                                </TableCell>
                              );
                            }
                          })}
                          {/* Settings Cell */}
                          <TableCell
                            align={"center"}
                            style={{ minWidth: "50px" }}
                          >
                            <button
                              style={{
                                color: "#0000EE",
                                textDecoration: "underline",
                                fontWeight: 50,
                              }}
                              onClick={() =>
                                insertSubjectSetting(row["subject_id"])
                              }
                              className="active"
                            >
                              R1,R2,R3 settings
                            </button>
                          </TableCell>

                          {/* View/Edit Content Cell */}
                          <TableCell
                            align={"center"}
                            style={{ minWidth: "50px" }}
                          >
                            <button
                              style={{
                                color: "#0000EE",
                                textDecoration: "underline",
                                fontWeight: 50,
                              }}
                              onClick={() => {
                                localStorage.setItem(
                                  "lesson_status",
                                  row.subject_status_have_lesson
                                );
                                navigate(
                                  `/view_subject_content?id=${row["subject_id"]}`
                                );
                              }}
                              className="active"
                            >
                              View/Edit Content
                            </button>
                          </TableCell>

                          {/* Delete Cell */}
                          <TableCell
                            align={"right"}
                            style={{ minWidth: "50px" }}
                          >
                            <button
                              onClick={() =>
                                handleOpenDeleteDialog(row["subject_id"])
                              }
                              className="btn"
                            >
                              <img src={del} alt="Delete" />
                            </button>
                          </TableCell>

                          {/* Status Cell */}
                          <TableCell
                            align={"right"}
                            style={{ minWidth: "50px", textAlign: "center" }}
                          >
                            {row["status"] === 2 ? (
                              <button
                                onClick={() =>
                                  updateButtonStatus(1, row["subject_id"])
                                }
                                className="btn btn-danger"
                              >
                                Activate
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  updateButtonStatus(2, row["subject_id"])
                                }
                                className="btn btn-danger"
                              >
                                De-Activate
                              </button>
                            )}
                          </TableCell>
                          <TableCell
                            align={"right"}
                            style={{ minWidth: "50px", textAlign: "center" }}
                          >
                            <label className="relative inline-block w-14 h-8">
                              <input
                                type="checkbox"
                                className="opacity-0 w-0 h-0"
                                checked={row.toggleState || false}
                                onChange={() =>
                                  handleToggleChange(row["subject_id"])
                                }
                              />
                              <span
                                className={`absolute cursor-pointer inset-0 transition-all duration-300 rounded-full ${
                                  row.toggleState
                                    ? "bg-purple-800"
                                    : "bg-gray-300"
                                }`}
                                style={{
                                  backgroundColor: row.toggleState
                                    ? "#7E418B"
                                    : undefined,
                                }}
                              >
                                <span
                                  className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform ${
                                    row.toggleState
                                      ? "translate-x-6"
                                      : "translate-x-0"
                                  }`}
                                ></span>
                              </span>
                            </label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{ backgroundColor: "#f7f2f9" }}
              rowsPerPageOptions={[100, 150, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}

export default ViewSubject;
