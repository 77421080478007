import React from "react";
import AdminDashboard from "../pages/admin/AdminDashboard";
import TeacherDashboard from "../pages/Teacher/TeacherDashboard";

function ProtectedAdminRoutes() {
  var webUser = localStorage.getItem("user_type") === "2" ? true : null;
  return webUser ? <AdminDashboard /> : <TeacherDashboard />;
}

export default ProtectedAdminRoutes;
