import React, { useState, useEffect, useRef } from "react";
import LoginPageLeftSide from "./PageLeftSide";
import LoginPageRightSide from "./PageRightPart";
import parse from "html-react-parser";
import axios from "axios";

function LessonExperience() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [speaking, setSpeaking] = useState(false);
  const isFirstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedResponseButtons, setSelectedResponseButtons] = useState([]);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1);
  const [isSpinning, setIsSpinning] = useState(false);
  const [pauseResumeStatus, setPauseResumeStatus] = useState(true);
  const [currentWordIndex, setCurrentWordIndex] = useState(-4);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [chunkSize, setChunkSize] = useState(4);
  const [lessonResponseData, setLessonResponseData] = useState();
  const [lessonButtonData, setLessonButtonData] = useState([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");

  //This is the part which is working fine with highlighter but
  const speechUtteranceRef = useRef(null);

  useEffect(() => {
    // handleCurrentResponseIndex();
    getLessonData();
  }, []);
  //   const handleCurrentResponseIndex = () => {
  //     const index = lessonResponseData.findIndex((lesson) => {
  //       return lesson.sourceResponseId === null;
  //     });
  //     console.log("LESSON OBJECT IS: ", lessonResponseData[index]);
  //     console.log("LESSON INDEX IS: ", index);
  //     setCurrentIndex(index);
  //   };

  const getLessonData = () => {
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_lesson_for_user",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          lesson_key: id,
        }),
      })
      .then((res) => {
        console.log("DATA", res.data.lesson_data[0].page_list);
        setLessonResponseData(res.data.lesson_data[0].page_list);
        setSelectedResponseButtons(
          res.data.lesson_data[0].page_list[0].options_list
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTextChunks = (text, chunkSize = 3) => {
    const words = text.replace(/(<([^>]+)>)/gi, "").split(/\s+/);
    const chunks = [];

    for (let i = 0; i < words.length; i += chunkSize) {
      chunks.push(words.slice(i, i + chunkSize).join(" "));
    }

    return chunks;
  };

  const handleRepeat = (text) => {
    window.speechSynthesis.cancel();
    setPauseResumeStatus(true);
    setIsSpinning(true);
    speakText(text); // Your existing function call
    setTimeout(() => {
      setIsSpinning(false);
    }, 2000);
  };
  const stripHtmlTags = (text) => {
    return text.replace(
      /(<([^>]+)>)|([\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E0}-\u{1F1FF}\.])/gu,
      ""
    );
  };
  const speakText = (htmlText) => {
    const plainText = stripHtmlTags(htmlText);
    // Clean the text
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(plainText);
      // Configure the utterance (onstart, onboundary, onend, etc.)

      const voices = window.speechSynthesis.getVoices();
      const selectedVoice = voices.find(
        (voice) => voice.name === "Microsoft George - English (United Kingdom)"
      );
      console.log("VOICES ARE: ", voices);
      console.log("SELECTED VOICE: ", selectedVoice);
      utterance.voice = selectedVoice;
      window.speechSynthesis.speak(utterance);
      utterance.onstart = () => {
        setSpeaking(true); // Indicate that speech synthesis has started
        setCurrentWordIndex(0); // Start highlighting from the first word
      };
      utterance.onend = () => {
        setSpeaking(false); // Indicate that speech synthesis has ended
        setCurrentWordIndex(null); // Set to null or another value that indicates no highlighting
        scrollToBottom();
      };
      utterance.onboundary = (event) => {
        const textUpToBoundary = stripHtmlTags(
          lessonResponseData[currentIndex].page_question
        )
          .slice(0, event.charIndex)
          .trim();
        const wordCountUpToBoundary = textUpToBoundary.split(" ").length;
        setCurrentWordIndex(wordCountUpToBoundary - 1);
      };
    }
  };

  function scrollToBottom() {
    var contentDiv = document.querySelector(".main-content-div"); // Adjust the selector as needed
    contentDiv.scrollTop = contentDiv.scrollHeight;
  }

  const renderTextWithHighlighting = () => {
    // Ensure that the current lesson response data is defined
    if (lessonResponseData === undefined) {
      // Return null, an empty string, or some placeholder content when data is not ready
      return "null"; // or "<p>Loading...</p>" or similar
    }

    let wordCount = 0; // Counter for words to identify the current reading position

    const options = {
      replace: ({ type, data }) => {
        if (type === "text") {
          const words = data.split(" ").map((word, index) => {
            const isFirstWord = index === 0;
            const isHighlighted =
              currentWordIndex !== null &&
              wordCount >= currentWordIndex &&
              wordCount < currentWordIndex + chunkSize;
            wordCount++; // Increment wordCount for each word

            return (
              <span
                key={index}
                style={{
                  backgroundColor: isHighlighted ? "#7E418B" : "transparent",
                }}
              >
                {isFirstWord ? "" : " "}
                {word}
              </span>
            );
          });

          return <>{words}</>;
        }
      },
    };

    // Now safe to access .text property as we've ensured lessonResponseData[currentIndex] is defined
    return parse(
      String(lessonResponseData[currentIndex].page_question),
      options
    );
  };

  const pauseSpeech = () => {
    if (window.speechSynthesis.speaking) {
      setPauseResumeStatus(false);
      window.speechSynthesis.pause();
    }
  };

  const resumeSpeech = () => {
    if (window.speechSynthesis.paused) {
      setPauseResumeStatus(true);
      window.speechSynthesis.resume();
    }
  };

  // Effect for initial speech synthesis
  useEffect(() => {
    window.speechSynthesis.cancel();
    const timer = setTimeout(() => {
      speakText(lessonResponseData[currentIndex].page_question);
      isFirstRender.current = false;
    }, 500); // Delay of 500ms

    return () => clearTimeout(timer);
  }, [lessonResponseData]);

  // Effect for handling changes in currentIndex
  useEffect(() => {
    window.speechSynthesis.cancel();
    if (!isFirstRender.current) {
      speakText(lessonResponseData[currentIndex].page_question);
    }
  }, [currentIndex]);

  const nextObject = () => {
    window.speechSynthesis.cancel();

    // Reset highlighter state
    setCurrentWordIndex(0);
    // Check if we're not at the end of the array
    // Show loading indicator
    setIsLoading(true);

    // Wait for 2 seconds before updating the currentIndex
    setTimeout(() => {
      // Update the currentIndex
      let selectedLesson;
      var index;
      if (
        !lessonResponseData[currentIndex].has_next_page &&
        lessonResponseData[currentIndex].has_button
      ) {
        let selectedoption = selectedResponseButtons[selectedAnswerIndex];
        if (selectedoption.target_page_id === null) {
          setIsLoading(false);
          setCurrentWordIndex(-4);
          return;
        }
      }
      if (
        lessonResponseData[currentIndex].has_next_page &&
        !lessonResponseData[currentIndex].has_button
      ) {
        index = lessonResponseData.findIndex(
          (lesson) =>
            lesson.page_id === lessonResponseData[currentIndex].next_page_id
        );
      } else {
        let selectedoption = selectedResponseButtons[selectedAnswerIndex];
        if (selectedoption.target_page_id !== null) {
          index = lessonResponseData.findIndex(
            (lesson) => selectedoption.target_page_id === lesson.page_id
          );
        }
      }
      if (index !== undefined) {
        selectedLesson = lessonResponseData[index];
        setSelectedAnswerIndex(-1);
      }
      if (selectedLesson.has_button) {
        setSelectedResponseButtons(selectedLesson.options_list);
      } else {
        setSelectedResponseButtons([]);
      }
      setCurrentIndex(index);
      // Hide loading indicator
      setIsLoading(false);
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  const previousObject = () => {
    // Check if we're not at the start of the array
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleSelectedAnswerIndex = (index) => {
    setSelectedAnswerIndex(index);
  };
  console.log("DATA IN THE LESSON: ", lessonResponseData);
  return (
    <React.Fragment>
      <section>
        {lessonResponseData !== undefined && (
          <div
            className={`w-auto h-auto grid text-white text-4xl ${
              lessonResponseData[currentIndex].image_file_name === null
                ? "md:grid-cols-1"
                : "grid-cols-2"
            } overflow-hidden`}
          >
            <LoginPageLeftSide
              lessonResponseData={lessonResponseData}
              lessonButtonData={lessonButtonData}
              selectedResponseButtons={selectedResponseButtons}
              currentIndex={currentIndex}
              previousObject={previousObject}
              nextObject={nextObject}
              speaking={speaking}
              speakText={speakText}
              isLoading={isLoading}
              pauseSpeech={pauseSpeech}
              resumeSpeech={resumeSpeech}
              selectedAnswerIndex={selectedAnswerIndex}
              handleSelectedAnswerIndex={handleSelectedAnswerIndex}
              isSpinning={isSpinning}
              handleRepeat={handleRepeat}
              pauseResumeStatus={pauseResumeStatus}
              renderTextWithHighlighting={renderTextWithHighlighting}
            />
            <LoginPageRightSide
              lessonResponseData={lessonResponseData}
              currentIndex={currentIndex}
            />
          </div>
        )}
      </section>
    </React.Fragment>
    // <div className="app-container">

    //   {/* <QuestionDisplay question={question.text} onSpeak={speakQuestion} />
    //   <MediaDisplay media={question.media} type={question.mediaType} /> */}
    // </div>
  );
}

export default LessonExperience;
