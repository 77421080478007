import React, { useState } from "react";
import participation_icon from "../../images/d1.png";
import accuracy_icon from "../../images/d3.png";
import completion_icon from "../../images/d2.png";
import Info from "../../images/info.png";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 250,
    opacity: "0.8",
    padding: "12px 16px",
  },
}));

const Metric = ({ icon, label, tooltip, value, values }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex items-center">
      <ClickAwayListener onClickAway={handleClose}>
        <div className="flex">
          <HtmlTooltip
            title={tooltip}
            PopperProps={{
              disablePortal: true,
            }}
            enterTouchDelay={0}
            leaveTouchDelay={10}
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
          >
            <div className="flex items-center">
              <button className="ml-[5px] mt-[2px]" onClick={handleOpen}>
                <img
                  className="h-[40px] w-[40px]"
                  src={icon}
                  alt={`${label} Icon`}
                />
              </button>

              <div className="ml-3">
                <div className="text-[#403151] opacity-75 text-[16px] leading-[24px] font-roboto">
                  {label}
                </div>
                <div className="text-[#403151] text-[24px] leading-[20px] font-roboto">
                  {values !== undefined
                    ? values
                        .map((v, i) => (v ? `${parseInt(v)}%` : "0%"))
                        .join(", ")
                    : value
                    ? `${parseInt(value)}%`
                    : "0%"}
                </div>
              </div>
            </div>
          </HtmlTooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
};

const ParticipationMetrics = ({ data }) => (
  <div className="md:ml-[20px] mt-[20px] mb-[20px] flex flex-wrap gap-4 justify-center">
    <Metric
      icon={participation_icon}
      label="Participation Rate"
      tooltip="The percentage of quizzes in which the entire class has attempted at least one question."
      value={data?.class_analytics_data?.participation_rate}
    />
    <Metric
      icon={accuracy_icon}
      label="Completion Rate"
      tooltip="The percentage of quizzes in which the entire class
       has answered all the questions."
      value={data?.class_analytics_data?.completion_rate}
    />
    <Metric
      icon={completion_icon}
      label="Accuracy of R1, R2, R3"
      tooltip="The percentage of correct responses out of the total number of attempts."
      values={[
        data?.class_analytics_data?.R1_accuracy,
        data?.class_analytics_data?.R2_accuracy,
        data?.class_analytics_data?.R3_accuracy,
      ]}
    />
  </div>
);

export default ParticipationMetrics;
