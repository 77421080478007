import React, { useContext, useState } from "react";
import Copy from "../../../images/copy.svg";
import copy from "copy-to-clipboard";
function ClassroomDetailBanner(props) {
  
  const classroomData = props.classroomData;
  const subjectName = classroomData.subject
    ? classroomData.subject.subject_name
    : "";
  return (
    <div
      className="home sm:ml[5px] md:ml-[0px] bg-[#F7F2F9]  "
      style={{
        backgroundColor: "#E1DAE3",

        width: "100%",
        height: "10%",
      }}
    >
      <div className="w-[50%] md:p-[15px] md:pl-[62px]">
        <div
          className="classroom_name_div"
          style={{
            color: "#403151",

            fontFamily: "Roboto",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "28px" /* 127.273% */,
            letterspacing: "0.15px",
          }}
        >
          {classroomData.classroom_name}
        </div>
        <div
          className="classroom_details_label mt-[10px]"
          style={{
            color: "#403151",

            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "100",
            lineHeight: "24px" /* 150% */,
            letterSpacing: "0.15px",
          }}
        >
          Enrolled Students :{" " + classroomData.classroom_enrolled_students}
        </div>
      </div>
      <div className="w-[50%] md:pt-[10px] self-center md:ml-[286px]">
        <div className="classroom_name_div   sm:!overflow-auto">
          <div
            className="classroom_details_label flex sm:!overflow-auto "
            style={{
              color: "#403151",

              fontFamily: "Roboto",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "100",
              lineHeight: "24px" /* 150% */,
              letterSpacing: "0.15px",
            }}
          >
            Class code : {" " + classroomData.classroom_code}
            <button
              onClick={() => {
                copy(
                  "Hello students! \n" +
                    "Join our new classroom in Geovise \n" +
                    "Classroom code: " +
                    classroomData.classroom_code +
                    "\n" +
                    "Download the app here: \n" +
                    "Apple App Store: [https://apps.apple.com/pk/app/geovise/id6478696875]\n" +
                    "Google Play Store: [https://play.google.com/store/apps/details?id=com.highersummit.geovise] \n" +
                    "After downloading, open the app, sign up, and enter the classroom code to join. \n" +
                    "See you there!"
                );
                alert("Class code has been copied");
              }}
            >
              <img src={Copy} className="w-[15px] ml-[10px]"></img>
            </button>
          </div>
        </div>
        <div
          className="classroom_details_label mt-[10px]"
          style={{
            color: "#403151",

            fontFamily: "Roboto",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "100",
            lineHeight: "24px" /* 150% */,
            letterSpacing: "0.15px",
          }}
        >
          Subject : {" " + subjectName}
        </div>
      </div>
    </div>
  );
}

export default ClassroomDetailBanner;
