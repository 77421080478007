import React, { useEffect, useState } from "react";
import Delete from "../../../images/delete.svg";
import axios from "axios";
import ConfirmationDialogue from "../confirmationDialogue/ConfirmationDialogue";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { ToastHeader } from "react-bootstrap";
import Info from "../../../images/info.png";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
function TeacherStudentTabBody(props) {
  const [openDeleteConfirmationDialogue, setOpenDeleteConfirmationDialogue] =
    useState(false);
  const [open, setOpen] = React.useState(false);
  const [openQuizTooltip, setOpenQuizTooltip] = useState(false);
  const [selectedTooltipIndexR1, setSelectedTooltipIndexR1] = useState(null);
  const [selectedTooltipIndexR2, setSelectedTooltipIndexR2] = useState(null);
  const [selectedTooltipIndexR3, setSelectedTooltipIndexR3] = useState(null);
  const [selectedTooltipIndexR4, setSelectedTooltipIndexR4] = useState(null);

  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();
  const [show, setShow] = useState(false);
  const [heading, setHeading] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [studentid, setStudentid] = useState();
  const [table, setTable] = useState("student");
  const [studentName, setStudentName] = useState();
  const [data, setData] = useState({ r1: [], r2: [], r3: [], r4: [] });
  const data2 = [{ r1: [], r2: [], r3: [], r4: [] }];
  var [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const handleOpenQuizTip = (index, column) => {
    if (column === "r1") {
      setSelectedTooltipIndexR1(index);
    } else if (column === "r2") {
      setSelectedTooltipIndexR2(index);
    } else if (column === "r3") {
      setSelectedTooltipIndexR3(index);
    } else if (column === "r4") {
      setSelectedTooltipIndexR4(index);
    }
  };

  // Function to handle closing of the tooltip
  const handleCloseQuizTip = (column) => {
    if (column === "r1") {
      setSelectedTooltipIndexR1(null);
    } else if (column === "r2") {
      setSelectedTooltipIndexR2(null);
    } else if (column === "r3") {
      setSelectedTooltipIndexR3(null);
    } else if (column === "r4") {
      setSelectedTooltipIndexR4(null);
    }
  };

  // Assuming data is an array of your rows
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  let R1 = [];
  try {
    R1 = JSON.parse(data.r1);
  } catch (error) {
    console.error("Error parsing JSON data:", error);
  }
  let R2 = [];
  try {
    R2 = JSON.parse(data.r2);
  } catch (error) {
    console.error("Error parsing JSON data:", error);
  }
  let R3 = [];
  try {
    R3 = JSON.parse(data.r3);
  } catch (error) {
    console.error("Error parsing JSON data:", error);
  }
  let R4 = [];
  try {
    R4 = JSON.parse(data.r4);
  } catch (error) {
    console.error("Error parsing JSON data:", error);
  }
  data2.r1 = R1.slice(firstItemIndex, lastItemIndex);
  data2.r2 = R2.slice(firstItemIndex, lastItemIndex);
  data2.r3 = R3.slice(firstItemIndex, lastItemIndex);
  data2.r4 = R4.slice(firstItemIndex, lastItemIndex);
  const totalData =
    data2?.r1?.length +
    data2?.r2?.length +
    data2?.r3?.length +
    data2?.r4?.length;
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#49454F",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      borderRadius: "8px",
      opacity: "0.8",
      backgroundColor: "#FFF",
      padding: "12px 16px",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  // Change page handler
  const paginate = (pageNumber) => {
    // getRecalls();
    setCurrentPage(pageNumber);

    console.log("Total Pages", totalPages);
  };

  // Calculate total pages
  const totalPages = Math.ceil(
    (R1?.length + R2?.length + R3?.length + R4?.length) / itemsPerPage
  );
  // const [student, setStudent] = useState(props.classroomData.student);
  var student = props.studentData;

  const handleClickDeleteStudent = (student, index) => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/change_status_of_student",
        {
          method: "POST",
          student_id_by_teacher: student.student_id,
          class_id: props.classroomData.classroom_id,
        }
      )
      .then((response) => {
        props.removeStudent(index);
        setOpenDeleteConfirmationDialogue(false);
        setShow(true);
        setHeading("Deleted");
        setParagraph("Awesome! student has been removed from the class. 🎉");
      })
      .catch((error) => {
        console.log(error);
        alert("failed to delete student");
      });
  };
  const handleOpen = (student, index) => {
    setOpenDeleteConfirmationDialogue(true);
    setSelectedStudent(student);
    setSelectedIndex(index);
  };
  const handleCloseWithYes = () => {
    handleClickDeleteStudent(selectedStudent, selectedIndex);
  };
  const handleCloseWithCancel = () => {
    setOpenDeleteConfirmationDialogue(false);
  };
  const handleTable = (studentId, studentname) => {
    setStudentid(studentId);
    setStudentName(studentname);
    setTable("setting");
  };
  function stripHTMLTags(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }
  const getRecalls = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_student_questions",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: {
          student_id: studentid,
          classroom_id: props.classroomData.classroom_id,
          limit: itemsPerPage + 2 * currentPage,
        },
      })
      .then((res) => {
        var data2 = res.data;
        console.log("Total data:", itemsPerPage * currentPage);

        setData(res.data);
        console.log("DATA ISL", data.r1);
      });
  };
  useEffect(() => {
    if (table === "setting") {
      getRecalls();
    }
  }, [table, currentPage]);
  return (
    <div>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast
          style={{ backgroundColor: "#f7f2f9" }}
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <ToastHeader>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_99_1907"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_99_1907)">
                <path
                  d="M9.54961 18.0001L3.84961 12.3001L5.27461 10.8751L9.54961 15.1501L18.7246 5.9751L20.1496 7.4001L9.54961 18.0001Z"
                  fill="#0AB30A"
                />
              </g>
            </svg>
            <span className="snackbarh" style={{ marginRight: "10%" }}>
              {heading}
            </span>
          </ToastHeader>
          <Toast.Body>
            <span className="snackbarp">{paragraph}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <ConfirmationDialogue
        title="Delete"
        text="Are you sure, you want to delete this student?"
        open={openDeleteConfirmationDialogue}
        handleCloseWithConfirm={handleCloseWithYes}
        handleCloseWithCancel={handleCloseWithCancel}
      />
      <ToastContainer />
      <div className="home md:ml-[20px] mt-[20px] w-[100cz] ">
        <div class="relative overflow-x-auto" style={{ width: "100%" }}>
          {table === "student" ? (
            <table class="w-full text-sm text-left text-white dark:text-white">
              <thead class="text-xs text-white  bg-[#403151] dark:bg-white dark:text-white">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    ID
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Joining Date
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {student ? (
                  <>
                    {student.length > 0 ? (
                      student.map((student, index) => (
                        <tr
                          key={student.student_id}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            style={{ color: "#49454F" }}
                          >
                            {index + 1}
                          </th>
                          <td className="px-6 py-4" style={{ color: "blue" }}>
                            <button
                              onClick={() =>
                                handleTable(
                                  student.student_id,
                                  student.student_name
                                )
                              }
                            >
                              {student.student_name}
                            </button>
                          </td>
                          <td
                            className="px-6 py-4"
                            style={{ color: "#49454F" }}
                          >
                            {student.student_email}
                          </td>
                          <td
                            className="px-6 py-4"
                            style={{ color: "#49454F" }}
                          >
                            {student.classroom_joined_timestamp}
                          </td>
                          <td className="px-6 py-4">
                            <button onClick={() => handleOpen(student, index)}>
                              <img src={Delete} alt="Delete" />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td
                          colSpan={5}
                          className="text-center py-4 text-gray-500"
                        >
                          No student has joined the class yet.
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    {/* <td className="text-gray">
                      No student has joined the class yet.
                    </td> */}
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <div>
              <div className=" mb-[15px]">
                <label
                  className="disabled-label"
                  style={{ color: "#9a7ba2" }}
                  onClick={() => setTable("student")}
                >
                  Back
                </label>
              </div>
              <p
                style={{
                  color: "#49454F",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  lineHeight: " 36px",
                  fontWeight: "600",
                  paddingBottom: "22px",
                }}
              >
                Student Name: {studentName}
              </p>
              <div className="flex">
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th
                        scope="col"
                        colspan="5"
                        class="px-6 py-1 text-center hidden"
                      ></th>
                    </tr>
                    <tr>
                      <th scope="col" class="px-2 py-2 hidden">
                        S.no
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from(
                      {
                        length: totalData > 30 ? totalData - 2 : totalData,
                      },
                      (_, index) => (
                        <tr
                          key={index}
                          style={{ border: "1px solid black", display: "none" }}
                        >
                          <td
                            className="text-center"
                            style={{
                              color: "#49454F",
                              borderRight: "1px solid black",
                            }}
                          >
                            {index + 1}
                          </td>

                          {/* Accessing R2 data */}

                          {/* You can add more cells here to display more data from R2 and R3 if needed */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th
                        scope="col"
                        colspan="5"
                        class="px-6 py-1 text-center "
                      >
                        R1
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" class="px-6 py-2">
                        S.no
                      </th>
                      <th scope="col" class="px-6 py-2">
                        Question
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Date
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Time
                      </th>
                      <th scope="col" class="px-2 py-1 border-r">
                        Priority
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data2.r1.map((row, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          // Close tooltip when not hovering
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          onMouseEnter={() => handleOpenQuizTip(index, "r1")} // Open tooltip on hover
                          onMouseLeave={() => handleCloseQuizTip("r1")} // Close tooltip when not hovering
                        >
                          <div
                            className="text-center"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "1.6em",
                              maxHeight: "4.8em",
                            }}
                          >
                            {stripHTMLTags(row.quiz_question)}
                          </div>
                          <ClickAwayListener onClickAway={handleCloseQuizTip}>
                            <div className="flex item-center text-center justify-center">
                              <HtmlTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={10}
                                title={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: "600",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Question:{" "}
                                      {stripHTMLTags(row.quiz_question)}
                                    </p>
                                    <p>
                                      History:{" "}
                                      {
                                        row.recall_status_activities
                                          ? row.recall_status_activities
                                              .split(",")
                                              .map((activity) => {
                                                switch (activity.trim()) {
                                                  case "1":
                                                    return "r1";
                                                  case "2":
                                                    return "r2";
                                                  case "3":
                                                    return "r3";
                                                  case "4":
                                                    return "r4";
                                                  default:
                                                    return activity; // or 'N/A' for unknown values
                                                }
                                              })
                                              .filter((activity) => activity) // Optional, to filter out any empty strings
                                              .join(", ")
                                          : "N/A" // Display this when row.recall_status_activities is null or undefined
                                      }
                                    </p>
                                  </React.Fragment>
                                }
                                text={row.recall_status_activities}
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                open={selectedTooltipIndexR1 === index} // Control tooltip visibility based on state
                              ></HtmlTooltip>
                            </div>
                          </ClickAwayListener>
                        </td>

                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.date}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.time}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.quiz_question_importance === 1 ? "High" : "Low"}
                        </td>
                        {/* Accessing R2 data */}

                        {/* You can add more cells here to display more data from R2 and R3 if needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th scope="col" colspan="5" class="px-6 py-1 text-center">
                        R2
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="px-6 py-2">
                        S.no
                      </th>
                      <th scope="col" class="px-6 py-2">
                        Question
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Date
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Time
                      </th>
                      <th scope="col" class="px-2 py-1 border-r">
                        Priority
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data2.r2.map((row, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          onMouseEnter={() => handleOpenQuizTip(index, "r2")} // Open tooltip on hover
                          onMouseLeave={() => handleCloseQuizTip("r2")} // Close tooltip when not hovering
                        >
                          <div
                            className="text-center"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "1.6em",
                              maxHeight: "4.8em",
                            }}
                          >
                            {stripHTMLTags(row.quiz_question)}
                          </div>
                          <ClickAwayListener onClickAway={handleCloseQuizTip}>
                            <div className="flex item-center text-center justify-center">
                              <HtmlTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={10}
                                title={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: "600",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Question:{" "}
                                      {stripHTMLTags(row.quiz_question)}
                                    </p>
                                    <p>
                                      History:{" "}
                                      {
                                        row.recall_status_activities
                                          ? row.recall_status_activities
                                              .split(",")
                                              .map((activity) => {
                                                switch (activity.trim()) {
                                                  case "1":
                                                    return "r1";
                                                  case "2":
                                                    return "r2";
                                                  case "3":
                                                    return "r3";
                                                  case "4":
                                                    return "r4";
                                                  default:
                                                    return activity; // or 'N/A' for unknown values
                                                }
                                              })
                                              .filter((activity) => activity) // Optional, to filter out any empty strings
                                              .join(", ")
                                          : "N/A" // Display this when row.recall_status_activities is null or undefined
                                      }
                                    </p>
                                  </React.Fragment>
                                }
                                text={row.recall_status_activities}
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                open={selectedTooltipIndexR2 === index} // Control tooltip visibility based on state
                              ></HtmlTooltip>
                            </div>
                          </ClickAwayListener>
                        </td>

                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.date}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.time}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.quiz_question_importance === 1 ? "High" : "Low"}
                        </td>
                        {/* Accessing R2 data */}

                        {/* You can add more cells here to display more data from R2 and R3 if needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th scope="col" colspan="5" class="px-6 py-1 text-center">
                        R3
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="px-6 py-2">
                        S.no
                      </th>
                      <th scope="col" class="px-6 py-2">
                        Question
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Date
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Time
                      </th>
                      <th scope="col" class="px-2 py-1 border-r">
                        Priority
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data2.r3.map((row, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          onMouseEnter={() => handleOpenQuizTip(index, "r3")} // Open tooltip on hover
                          onMouseLeave={() => handleCloseQuizTip("r3")} // Close tooltip when not hovering
                        >
                          <div
                            className="text-center"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "1.6em",
                              maxHeight: "4.8em",
                            }}
                          >
                            {stripHTMLTags(row.quiz_question)}
                          </div>
                          <ClickAwayListener onClickAway={handleCloseQuizTip}>
                            <div className="flex item-center text-center justify-center">
                              <HtmlTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={10}
                                title={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: "600",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Question:{" "}
                                      {stripHTMLTags(row.quiz_question)}
                                    </p>
                                    <p>
                                      History:{" "}
                                      {
                                        row.recall_status_activities
                                          ? row.recall_status_activities
                                              .split(",")
                                              .map((activity) => {
                                                switch (activity.trim()) {
                                                  case "1":
                                                    return "r1";
                                                  case "2":
                                                    return "r2";
                                                  case "3":
                                                    return "r3";
                                                  case "4":
                                                    return "r4";
                                                  default:
                                                    return activity; // or 'N/A' for unknown values
                                                }
                                              })
                                              .filter((activity) => activity) // Optional, to filter out any empty strings
                                              .join(", ")
                                          : "N/A" // Display this when row.recall_status_activities is null or undefined
                                      }
                                    </p>
                                  </React.Fragment>
                                }
                                text={row.recall_status_activities}
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                open={selectedTooltipIndexR3 === index} // Control tooltip visibility based on state
                              ></HtmlTooltip>
                            </div>
                          </ClickAwayListener>
                        </td>

                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.date}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.time}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.quiz_question_importance === 1 ? "High" : "Low"}
                        </td>
                        {/* Accessing R2 data */}

                        {/* You can add more cells here to display more data from R2 and R3 if needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th scope="col" colspan="5" class="px-6 py-1 text-center">
                        R4
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="px-6 py-2">
                        S.no
                      </th>
                      <th scope="col" class="px-6 py-2">
                        Question
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Date
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Time
                      </th>
                      <th scope="col" class="px-2 py-1 border-r">
                        Priority
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data2.r4.map((row, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          onMouseEnter={() => handleOpenQuizTip(index, "r4")} // Open tooltip on hover
                          onMouseLeave={() => handleCloseQuizTip("r4")} // Close tooltip when not hovering
                        >
                          <div
                            className="text-center"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "1.6em",
                              maxHeight: "4.8em",
                            }}
                          >
                            {stripHTMLTags(row.quiz_question)}
                          </div>
                          <ClickAwayListener onClickAway={handleCloseQuizTip}>
                            <div className="flex item-center text-center justify-center">
                              <HtmlTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={10}
                                title={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: "600",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Question:{" "}
                                      {stripHTMLTags(row.quiz_question)}
                                    </p>
                                    <p>
                                      History:{" "}
                                      {
                                        row.recall_status_activities
                                          ? row.recall_status_activities
                                              .split(",")
                                              .map((activity) => {
                                                switch (activity.trim()) {
                                                  case "1":
                                                    return "r1";
                                                  case "2":
                                                    return "r2";
                                                  case "3":
                                                    return "r3";
                                                  case "4":
                                                    return "r4";
                                                  default:
                                                    return activity; // or 'N/A' for unknown values
                                                }
                                              })
                                              .filter((activity) => activity) // Optional, to filter out any empty strings
                                              .join(", ")
                                          : "N/A" // Display this when row.recall_status_activities is null or undefined
                                      }
                                    </p>
                                  </React.Fragment>
                                }
                                text={row.recall_status_activities}
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                open={selectedTooltipIndexR4 === index} // Control tooltip visibility based on state
                              ></HtmlTooltip>
                            </div>
                          </ClickAwayListener>
                        </td>

                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.date}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.time}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.quiz_question_importance === 1 ? "High" : "Low"}
                        </td>
                        {/* Accessing R2 data */}

                        {/* You can add more cells here to display more data from R2 and R3 if needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-[15px] mr-[0px] float-right flex gap-3">
                <button
                  className="btn"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "#403151",
                    color: "white",
                    fontFamily: "Roboto",
                  }}
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {"<"}
                </button>
                <span
                  className="btn"
                  style={{ fontSize: "20px", color: "#49454F" }}
                >
                  {" "}
                  {currentPage}
                </span>
                <button
                  className="btn"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "#403151",
                    color: "white",
                    fontFamily: "Roboto",
                  }}
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {">"}
                </button>
                {/* {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number) => (
                    <button
                      key={number}
                      onClick={() => paginate(number)}
                      disabled={currentPage === number}
                    >
                      {number}
                    </button>
                  )
                )} */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TeacherStudentTabBody;
