import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import misconception_icon from "../../images/misconception.svg";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 250,
    opacity: "0.8",
    padding: "12px 16px",
  },
}));
const CommonMisconceptionsTable = (props) => {
  const misconceptions =
    props.data?.class_analytics_data?.misconception_questions;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="p-4 overflow-x-auto">
      <table className="w-full text-left">
        <thead className="bg-[#403151] text-white">
          <tr>
            <th className="py-2 px-4 text-center">ID</th>
            <th className="py-2 px-4 ">
              <ClickAwayListener onClickAway={handleClose}>
                <span className="">
                  <HtmlTooltip
                    title="The questions that a significant portion of class answers incorrectly."
                    PopperProps={{
                      disablePortal: true,
                    }}
                    enterTouchDelay={0}
                    leaveTouchDelay={10}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                  >
                    <button className="ml-[16px] flex" onClick={handleOpen}>
                      Common Misconceptions{" "}
                      <img
                        className="ml-2 h-[24px] w-[24px]"
                        src={misconception_icon}
                        alt=""
                      />
                    </button>
                  </HtmlTooltip>
                </span>
              </ClickAwayListener>
            </th>
            <th className="py-2 px-4 text-center">Question Inaccuracy</th>
          </tr>
        </thead>
        <tbody>
          {misconceptions && misconceptions.length > 0 ? (
            misconceptions.map((misconception, index) => (
              <MisconceptionRow
                key={index}
                id={index + 1}
                misconception={misconception.quiz_question}
                percentage={misconception.incorrect_percentage}
              />
            ))
          ) : (
            <tr>
              <td colSpan="3" className="py-2 px-10 text-center text-gray-600">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const MisconceptionRow = ({ id, misconception, percentage }) => (
  <tr>
    <td className="py-2 px-10 bg-[#E8E8E8]">{id}</td>
    <td className="py-2 px-10 border border-b text-[black]">
      <b>
        <span dangerouslySetInnerHTML={{ __html: misconception }} />
      </b>
    </td>
    <td className="py-2 px-10 text-center bg-[#DBD2DD]">
      {percentage !== undefined ? `${parseInt(percentage)}%` : "0%"}
    </td>
  </tr>
);

export default CommonMisconceptionsTable;
