import React, { useEffect, useState } from "react";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cross from "../../../images/cross.png";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "../../../images/edit.png";
import ReactQuill from "react-quill";
import Tick from "../../../images/tick.png";
import Cancel from "../../../images/cancel.png";
import EdiText from "react-editext";
import axios from "axios";

function LessonPages(props) {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [optId, setOptId] = React.useState();
  const [indexes, setIndexes] = React.useState();
  const [predefinedQuestionAnswerList, setPredefinedQuestionAnswerList] =
    useState(props.predefined_question_answer_list);
  const [show, setShow] = useState(false);
  const [image_file_name, setImage_file_name] = useState("");

  useEffect(() => {
    setPredefinedQuestionAnswerList(props.predefined_question_answer_list);
  }, [props.predefined_question_answer_list]);

  console.log("PROPS DATA: ", props.predefined_question_answer_list);
  console.log("HOOK DATA: ", predefinedQuestionAnswerList);

  const [options, setOptions] = useState([
    { label: "A" },
    { label: "B" },
    { label: "C" },
    { label: "D" },
    { label: "E" },
    { label: "F" },
    { label: "G" },
    { label: "H" },
    { label: "I" },
    { label: "J" },
    { label: "K" },
    { label: "L" },
    { label: "M" },
    { label: "N" },
    { label: "O" },
    { label: "P" },
    { label: "Q" },
    { label: "R" },
    { label: "S" },
    { label: "T" },
    // Add more options here as needed
  ]);
  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };
  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "align",
    "size",
  ];

  const onSaveSubTopicRubric2 = (value, predefined_question_answer, index) => {
    predefined_question_answer.incorrect_feedback = value;
    if (value.length > 0) {
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] =
        "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(
          process.env.REACT_APP_REST_API_BASE_URL + "/update_page_content",
          {
            method: "POST",
            page_id: predefined_question_answer.page_id,
            page_question: predefined_question_answer.question,
            correct_feedback: predefined_question_answer.correct_feedback,
            incorrect_feedback: predefined_question_answer.incorrect_feedback,
            next_page_id: predefined_question_answer.next_page_id,
            correct_option_id: predefined_question_answer.correct_option_id,
            image_file_name: predefined_question_answer.image_file_name,
          }
        )

        .then((response) => {
          setPredefinedQuestionAnswerList((prevList) => {
            // Create a new array with the updated item
            const newList = prevList.map((item, idx) =>
              idx === index ? { ...item, incorrect_feedback: value } : item
            );

            return newList;
          });
          // alert("updated");
        })
        .catch((error) => {
          console.log(error);
          // alert("fail");
        });
    } else {
      alert("No Content Added");
    }
  };

  const handleAddOption = (pageIndex, page_id) => {
    // ... existing axios setup code

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_page_option", {
        method: "POST",
        page_id: page_id,
        target_page_id: null,
      })
      .then((response) => {
        // Assuming response contains the new option data
        const newOption = response.data;

        setPredefinedQuestionAnswerList((prevList) => {
          // Creating a deep copy of the list
          const updatedList = prevList.map((item, idx) =>
            idx === pageIndex
              ? {
                  ...item,
                  question_has_option_list: item.question_has_option_list
                    ? [...item.question_has_option_list, newOption]
                    : [newOption],
                }
              : item
          );

          return updatedList;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSaveSubTopicRubric = (value, predefined_question_answer, index) => {
    predefined_question_answer.correct_feedback = value;
    if (value.length > 0) {
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] =
        "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(
          process.env.REACT_APP_REST_API_BASE_URL + "/update_page_content",
          {
            method: "POST",
            page_id: predefined_question_answer.page_id,
            page_question: predefined_question_answer.question,
            correct_feedback: predefined_question_answer.correct_feedback,
            incorrect_feedback: predefined_question_answer.incorrect_feedback,
            next_page_id: predefined_question_answer.next_page_id,
            correct_option_id: predefined_question_answer.correct_option_id,
            image_file_name: predefined_question_answer.image_file_name,
          }
        )

        .then((response) => {
          setPredefinedQuestionAnswerList((prevList) => {
            // Create a new array with the updated item
            const newList = prevList.map((item, idx) =>
              idx === index ? { ...item, correct_feedback: value } : item
            );

            return newList;
          });
          // alert("updated");
        })
        .catch((error) => {
          console.log(error);
          // alert("fail");
        });
    } else {
      alert("No content Added");
    }
  };
  const onSaveOption = async (value, option, questionIndex, optionId) => {
    if (value.length > 0) {
      try {
        // Set up Axios headers just once
        const token = "Bearer " + localStorage.getItem("access_token");
        axios.defaults.headers.common["Authorization"] = token;
        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=utf-8";
        axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

        // Send POST request to update option
        await axios
          .post("/update_page_option", {
            method: "POST",
            page_option_id: optionId,
            option_text: value,
            target_page_id: option.target_page_id,
          })
          .then((response) => {
            // Update local state
            setPredefinedQuestionAnswerList((prevList) => {
              return prevList.map((item, idx) => {
                if (idx === questionIndex) {
                  // Update the specific option with the new value
                  const updatedOptions = item.question_has_option_list.map(
                    (option) => {
                      if (option.page_option_id === optionId) {
                        return { ...option, option_text: value };
                      }
                      return option;
                    }
                  );
                  return { ...item, question_has_option_list: updatedOptions };
                }
                return item;
              });
            });
            console.log("response data", response);
          });
      } catch (error) {
        console.error("Error in onSaveOption:", error);
      }
    } else {
      alert("No Content Added");
    }
  };

  const handleOptionChange = async (
    selectedOptionId,
    predefined_question_answer,
    index,
    newIndex
  ) => {
    // const token = "Bearer " + localStorage.getItem("access_token");
    // axios.defaults.headers.common["Authorization"] = token;
    // axios.defaults.headers.post["Content-Type"] =
    //   "application/json;charset=utf-8";
    // axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    // const questionId = predefined_question_answer.question_id;
    // console.log("Question id:", questionId);
    // const responsed = await axios.post(
    //   process.env.REACT_APP_REST_API_BASE_URL + "/get_options_via_question_id",
    //   {
    //     question_id: questionId,
    //   }
    // );

    // const subjectsList = responsed.data;
    // console.log("option: ", subjectsList);

    // // Second POST Request to update option
    // const optionId = subjectsList.response[newIndex].option_id;
    // console.log("newOption: ", optionId);
    await axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_page_content", {
        method: "POST",
        page_id: predefined_question_answer.page_id,
        page_question: predefined_question_answer.question,
        correct_feedback: predefined_question_answer.correct_feedback,
        incorrect_feedback: predefined_question_answer.incorrect_feedback,
        next_page_id: predefined_question_answer.next_page_id,
        correct_option_id: selectedOptionId,
        image_file_name: predefined_question_answer.image_file_name,
      })
      .then((response) => {
        setPredefinedQuestionAnswerList((prevList) =>
          prevList.map((item, idx) =>
            idx === index
              ? { ...item, right_option_id: selectedOptionId }
              : item
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCloseEditor = (index, id) => {
    if (props.is_mini_mock) {
      document.getElementById(`questionm${id}`).style.display = "none";
      document.getElementById(`qm${id}`).style.display = "flex";
      return;
    }
    document.getElementById(`question${id}`).style.display = "none";
    document.getElementById(`q${id}`).style.display = "flex";
  };

  const removePredefinedQuestionImageFileName = (
    predefined_question_answer_id,
    predefined_question_answer
  ) => {
    console.log("del id:", predefined_question_answer_id);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/remove_page_image_file",
        {
          method: "POST",
          page_id: predefined_question_answer_id,
        }
      )

      .then((response) => {
        // if (props.is_mini_mock) {
        //   props.addTodoParent();
        // }
        // alert("DELETED");
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileUpload = (event, predefined_question_answer, index) => {
    // get the selected file from the input
    const file = event.target.files[0];
    // create a new FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file);
    formData.append("page_id", predefined_question_answer.page_id);
    // make a POST request to the File Upload API with the FormData object and Rapid API headers
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/upload_file_for_page",
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        // handle the response
        console.log(response);
        // alert("File uploaded successfully");

        predefined_question_answer.image_file_name =
          response.data["image_file_name"];
        setImage_file_name(predefined_question_answer.image_file_name);
        predefinedQuestionAnswerList[index] = predefined_question_answer;
        // props.addTodo();
        // 👇️ reset file input
        event.target.value = null;
      })
      .catch((error) => {
        // handle errors
        // alert("Error while uploading file");
        console.log(error);
        // 👇️ reset file input
        event.target.value = null;
      });
  };

  const onSaveSubTopicQuestion = (
    value,
    predefined_question_answer,
    index,
    id
  ) => {
    // if (value === undefined) {
    //   document.getElementById(`question${id}`).style.display = "none";
    //   document.getElementById(`q${id}`).style.display = "flex";
    //   return;
    // }
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");

    // Access the text content of the <p> tag
    const text = doc.querySelector("p").textContent;
    if (text.length > 0) {
      predefined_question_answer.question = value;
      value = value.replace(/style="color: rgb\(0, 0, 0\);"/g, "");

      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] =
        "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(
          process.env.REACT_APP_REST_API_BASE_URL + "/update_page_content",
          {
            method: "POST",

            page_id: predefined_question_answer.page_id,
            page_question: value,
            correct_feedback: predefined_question_answer.correct_feedback,
            incorrect_feedback: predefined_question_answer.incorrect_feedback,
            next_page_id: predefined_question_answer.next_page_id,
            correct_option_id: predefined_question_answer.right_option_id,
            image_file_name: predefined_question_answer.image_file_name,
            question_id: predefined_question_answer.page_id,
          }
        )

        .then((response) => {
          props.addTodo();
          setShow(true);
          document.getElementById(`question${id}`).style.display = "none";
          document.getElementById(`q${id}`).style.display = "flex";
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert("No content Added");
    }
  };
  const handleProcedureContentChange = (content, index) => {
    setPredefinedQuestionAnswerList((prevState) => {
      // Create a new array with updated content for the specific item
      return prevState.map((item, idx) => {
        if (idx === index) {
          return { ...item, question: content };
        }
        return item;
      });
    });
  };

  const handleConfirmDelete = () => {
    setOpenDeleteDialog(false);
    handleOptionDelete(optId, indexes);
  };

  const handleOptionDelete = (opt_id, questionIndex) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_page_option", {
        method: "POST",
        page_option_id: opt_id,
      })

      .then((response) => {
        setPredefinedQuestionAnswerList((prevList) => {
          // Updating the list by filtering out the deleted option
          return prevList.map((item, idx) =>
            idx === questionIndex
              ? {
                  ...item,
                  question_has_option_list:
                    item.question_has_option_list.filter(
                      (option) => option.page_option_id !== opt_id
                    ),
                }
              : item
          );
        });
      })
      .catch((error) => {
        console.log(error);
        // alert("fail");
      });
  };

  const handleOpenDeleteDialog = (opt_id, questionIndex) => {
    setOpenDeleteDialog(true);
    setOptId(opt_id);
    setIndexes(questionIndex);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenEditor = (questionIndex, id) => {
    document.getElementById(`question${id}`).style.display = "flex";
    document.getElementById(`q${id}`).style.display = "none";
  };

  const onSaveTargetPage = async (value, option, questionIndex, optionId) => {
    if (value.length > 0) {
      try {
        // Set up Axios headers just once
        const token = "Bearer " + localStorage.getItem("access_token");
        axios.defaults.headers.common["Authorization"] = token;
        axios.defaults.headers.post["Content-Type"] =
          "application/json;charset=utf-8";
        axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

        // Send POST request to update option
        await axios
          .post("/update_page_option", {
            method: "POST",
            page_option_id: optionId,
            option_text: option.option_text,
            target_page_id: value,
          })
          .then((response) => {
            // Update local state
            setPredefinedQuestionAnswerList((prevList) => {
              return prevList.map((item, idx) => {
                if (idx === questionIndex) {
                  // Update the specific option with the new value
                  const updatedOptions = item.question_has_option_list.map(
                    (option) => {
                      if (option.page_option_id === optionId) {
                        return { ...option, target_page_id: value };
                      }
                      return option;
                    }
                  );
                  return { ...item, question_has_option_list: updatedOptions };
                }
                return item;
              });
            });
            console.log("response data", response);
          });
      } catch (error) {
        console.error("Error in onSaveOption:", error);
      }
    } else {
      alert("No Content Added");
    }
  };
  const onSaveTargetPageResponse = async (value, page) => {
    try {
      // Set up Axios headers just once
      const token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.headers.common["Authorization"] = token;
      axios.defaults.headers.post["Content-Type"] =
        "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

      // Send POST request to update option
      await axios
        .post("/update_page_content", {
          method: "POST",
          page_id: page.page_id,
          page_question: page.page_question,
          correct_feedback: page.correct_feedback,
          incorrect_feedback: page.incorrect_feedback,
          next_page_id: value === null || value.length === 0 ? null : value,
          correct_option_id: page.right_option_id,
          image_file_name: page.image_file_name,
        })
        .then((response) => {
          console.log("response data", response);
        });
    } catch (error) {
      console.error("Error in onSaveOption:", error);
    }
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "370px",
            height: "224px",
            padding: "10px",
          },
        }}
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title" className="flex justify-between">
          <b>Delete</b> {/* Adjusted to match the provided example */}
          <button onClick={handleCloseDeleteDialog}>
            <img src={Cross} alt="Close" />{" "}
            {/* Replace 'Cross' with your image path */}
          </button>
        </DialogTitle>
        <DialogContent className="overflow-hidden">
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this Option?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-[20px]">
          <span style={{ marginRight: "46px" }}>
            <Button
              onClick={handleCloseDeleteDialog}
              className="dialogue-button-text"
              style={{
                color: "#7E418B",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.1px",
              }}
            >
              Cancel {/* Changed from 'No' to 'Cancel' */}
            </Button>
          </span>
          <Button
            onClick={handleConfirmDelete}
            className="teepee-button"
            style={{
              color: "white",
              backgroundColor: "#7E418B",
              padding: "10px",
              paddingLeft: "24px",
              paddingRight: "24px",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px",
              letterSpacing: "0.1px",
            }}
            autoFocus
          >
            Confirm {/* Changed from 'Yes' to 'Confirm' */}
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer className="p-5 position-fixed top-12  right-10 ">
        <Toast
          style={{ backgroundColor: "#f7f2f9" }}
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <strong className="me-auto" style={{ fontFamily: "Roboto" }}>
              Question Saved
            </strong>
            {/* <small>11 mins ago</small> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_187_302"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_187_302)">
                <path
                  d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                  fill="#0AB30A"
                />
              </g>
            </svg>
          </Toast.Header>
          <Toast.Body>
            <p style={{ fontFamily: "Roboto" }}>
              Question has been added Successfully
            </p>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div>
        <p>
          {/* <b style={{ fontSize: "16px" }}>Predefined Question Answers:</b> */}
          {predefinedQuestionAnswerList?.map(
            (predefined_question_answer, predinedQuestionAnswerIndex) => (
              <React.Fragment key={predinedQuestionAnswerIndex}>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                      width: "100%",
                      marginBottom: "30px",
                      backgroundColor: "#F0F0F0",
                    }}
                  >
                    <div className="flex">
                      <b className="w-[20%]">
                        Page Id: {predefined_question_answer.page_id}
                      </b>
                      <div
                        className="flex ml-[10%] w-[100%]"
                        style={{
                          display:
                            predefined_question_answer.question_has_option_list
                              .length > 0 && "none",
                        }}
                      >
                        <b className="w-[auto] mr-[1%]">Target Page Id:</b>
                        <EdiText
                          editOnViewClick
                          className="w-[30%]"
                          type="text"
                          inputProps={{
                            onChange: (e) => {},
                            className: "text",
                            placeholder: "Target Page",
                            style: {
                              width: "50%",
                              height: "100%",
                              padding: "5px",
                            },
                            rows: 1,
                          }}
                          onSave={(value) =>
                            onSaveTargetPageResponse(
                              value,
                              predefined_question_answer
                            )
                          }
                          value={predefined_question_answer.next_page_id}
                        />
                      </div>
                    </div>

                    <Button
                      style={{ float: "right", minWidth: "0px" }}
                      onClick={() =>
                        props.removeQuestion(predefined_question_answer.page_id)
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                    <p
                      className="answer-paragraph"
                      style={{
                        fontSize: "12px",
                        fontFamily: "Roboto",
                        color: "#49454F",
                      }}
                    >
                      <div
                        id={`q${predefined_question_answer.page_id}`}
                        className="flex mt-[2%]"
                      >
                        <div className="pl-[5%]">
                          <button
                            className="p-[4px]"
                            style={{
                              border: "1px solid",
                              borderColor:
                                "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
                            }}
                            onClick={() => {
                              handleOpenEditor(
                                predinedQuestionAnswerIndex,
                                predefined_question_answer.page_id
                              );
                            }}
                          >
                            <img src={EditIcon}></img>
                          </button>
                        </div>
                        <div
                          className="ml-[1%]"
                          style={{ fontSize: "14px", fontFamily: "Roboto" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: predefined_question_answer.question,
                            }}
                          ></div>
                        </div>
                      </div>

                      <div
                        id={`question${predefined_question_answer.page_id}`}
                        style={{ display: "none", width: "95%" }}
                        className="mb-[10%] mt-[4%]"
                      >
                        <ReactQuill
                          id={`q${predefined_question_answer.page_id}`}
                          value={predefined_question_answer.question}
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          placeholder="write your content ...."
                          onChange={(content) =>
                            handleProcedureContentChange(
                              content,
                              predinedQuestionAnswerIndex
                            )
                          }
                          style={{ height: "220px", width: "100%" }}
                        ></ReactQuill>
                        <div className="self-center mt-[6%] ml-[2%]">
                          <button
                            onClick={() =>
                              onSaveSubTopicQuestion(
                                predefined_question_answer.question,
                                predefined_question_answer,
                                predinedQuestionAnswerIndex,
                                predefined_question_answer.page_id
                              )
                            }
                            className="p-[4px]"
                            style={{
                              border: "1px solid",
                              borderColor:
                                "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
                            }}
                          >
                            <img src={Tick}></img>
                          </button>
                          <button
                            onClick={() =>
                              handleCloseEditor(
                                predinedQuestionAnswerIndex,
                                predefined_question_answer.page_id
                              )
                            }
                            className="p-[4px]"
                            style={{
                              border: "1px solid",
                              borderColor:
                                "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
                            }}
                          >
                            <img src={Cancel}></img>
                          </button>
                        </div>
                      </div>
                    </p>
                    <p
                      className="answer-paragraph"
                      style={{
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        color: "#49454F",
                      }}
                    >
                      <div className="flex justify-center mb-[20px] mt-[20px]"></div>
                      <div className="flex justify-center mb-[20px] mt-[20px]">
                        {predefined_question_answer.image_file_name !== null ? (
                          <div>
                            <Button
                              style={{ float: "right", minWidth: "0px" }}
                              onClick={() =>
                                removePredefinedQuestionImageFileName(
                                  predefined_question_answer.page_id
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                            <img
                              alt="s"
                              src={
                                process.env
                                  .REACT_APP_CDN_URL_FOR_QUESTION_IMAGES +
                                predefined_question_answer.image_file_name
                              }
                            ></img>
                          </div>
                        ) : (
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                              handleFileUpload(
                                e,
                                predefined_question_answer,
                                predefinedQuestionAnswerList.page_id
                              )
                            }
                          />
                        )}
                      </div>

                      <b>Options </b>
                      {predefined_question_answer.question_has_option_list?.map(
                        (option, index) => (
                          <div
                            key={option.page_option_id}
                            style={{
                              // width: "40%",
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              className="btn rounded-circle "
                              style={{
                                marginRight: "15px",
                                fontWeight: "bold",
                                backgroundColor: "#403151",
                                color: "white",
                                fontSize: "12px",
                                fontFamily: "Roboto",
                              }}
                            >
                              {options[index].label}
                            </div>
                            <div style={{ display: "flex" }}>
                              <EdiText
                                editOnViewClick
                                className="w-full"
                                type="text"
                                inputProps={{
                                  onChange: (e) => {},
                                  className: "text",
                                  placeholder: "Type your content here",
                                  style: {
                                    width: "50%",
                                    height: "100%",
                                    padding: "5px",
                                  },
                                  rows: 1,
                                }}
                                onSave={(value) =>
                                  onSaveOption(
                                    value,
                                    option,
                                    predinedQuestionAnswerIndex,
                                    option.page_option_id
                                  )
                                }
                                value={option.option_text}
                              />
                            </div>
                            <input
                              type="radio"
                              name={`correctOption-${option.page_option_id}`}
                              style={{ marginLeft: "10px" }}
                              checked={
                                predefined_question_answer.right_option_id ===
                                option.page_option_id
                              }
                              onChange={() =>
                                handleOptionChange(
                                  option.page_option_id,
                                  predefined_question_answer,
                                  predinedQuestionAnswerIndex,
                                  index
                                )
                              }
                            />
                            <span
                              style={{
                                marginLeft: "5px",
                                marginRight: "15px",
                              }}
                            >
                              Correct
                            </span>
                            <span
                              style={{
                                marginRight: "15px",
                                color: "red",
                              }}
                            >
                              {index === 0 ? "*" : ""}
                            </span>
                            <Button
                              style={{ float: "right", minWidth: "0px" }}
                              onClick={() => {
                                handleOpenDeleteDialog(
                                  option.page_option_id,
                                  predinedQuestionAnswerIndex
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                            <div
                              style={{ display: "flex", marginLeft: "auto" }}
                            >
                              <EdiText
                                editOnViewClick
                                className="w-full"
                                type="text"
                                inputProps={{
                                  onChange: (e) => {},
                                  className: "text",
                                  placeholder: "Target Page",
                                  style: {
                                    width: "50%",
                                    height: "100%",
                                    padding: "5px",
                                  },
                                  rows: 1,
                                }}
                                onSave={(value) =>
                                  onSaveTargetPage(
                                    value,
                                    option,
                                    index,
                                    option.page_option_id
                                  )
                                }
                                value={option.target_page_id}
                              />
                            </div>
                          </div>
                        )
                      )}
                      <button
                        onClick={() =>
                          handleAddOption(
                            predinedQuestionAnswerIndex,
                            predefined_question_answer.page_id
                          )
                        }
                        style={{
                          color: "#40315",
                          width: "100px",
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <span className="pt-[5%]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 10 11"
                            fill="none"
                          >
                            <path
                              d="M4.5 7.59961H5.5V5.59961H7.5V4.59961H5.5V2.59961H4.5V4.59961H2.5V5.59961H4.5V7.59961ZM5 10.0996C4.30833 10.0996 3.65833 9.96836 3.05 9.70586C2.44167 9.44336 1.9125 9.08711 1.4625 8.63711C1.0125 8.18711 0.65625 7.65794 0.39375 7.04961C0.13125 6.44128 0 5.79128 0 5.09961C0 4.40794 0.13125 3.75794 0.39375 3.14961C0.65625 2.54128 1.0125 2.01211 1.4625 1.56211C1.9125 1.11211 2.44167 0.755859 3.05 0.493359C3.65833 0.230859 4.30833 0.0996094 5 0.0996094C5.69167 0.0996094 6.34167 0.230859 6.95 0.493359C7.55833 0.755859 8.0875 1.11211 8.5375 1.56211C8.9875 2.01211 9.34375 2.54128 9.60625 3.14961C9.86875 3.75794 10 4.40794 10 5.09961C10 5.79128 9.86875 6.44128 9.60625 7.04961C9.34375 7.65794 8.9875 8.18711 8.5375 8.63711C8.0875 9.08711 7.55833 9.44336 6.95 9.70586C6.34167 9.96836 5.69167 10.0996 5 10.0996ZM5 9.09961C6.11667 9.09961 7.0625 8.71211 7.8375 7.93711C8.6125 7.16211 9 6.21628 9 5.09961C9 3.98294 8.6125 3.03711 7.8375 2.26211C7.0625 1.48711 6.11667 1.09961 5 1.09961C3.88333 1.09961 2.9375 1.48711 2.1625 2.26211C1.3875 3.03711 1 3.98294 1 5.09961C1 6.21628 1.3875 7.16211 2.1625 7.93711C2.9375 8.71211 3.88333 9.09961 5 9.09961Z"
                              fill="#49454F"
                            />
                          </svg>
                        </span>
                        Add Option
                      </button>
                    </p>
                    <div className="flex pt-[2%]">
                      <EdiText
                        editOnViewClick
                        className="w-full"
                        type="textarea"
                        inputProps={{
                          onChange: (e) => {},
                          className: "textarea",
                          placeholder: "Type your content here",
                          style: {
                            width: "100%",
                            height: "100%",
                            padding: "5px",
                          },
                          rows: 10,
                        }}
                        onSave={(value) =>
                          onSaveSubTopicRubric(
                            value,
                            predefined_question_answer,
                            predinedQuestionAnswerIndex
                          )
                        }
                        value={predefined_question_answer.correct_feedback}
                      />
                      <p
                        className="answer-paragraph"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <b>
                          Correct Ans Feedback
                          <span style={{ color: "red" }}>*</span>{" "}
                        </b>
                      </p>
                    </div>
                    <div className="flex pt-[2%]">
                      <EdiText
                        editOnViewClick
                        className="w-full"
                        type="textarea"
                        inputProps={{
                          onChange: (e) => {},
                          className: "textarea",
                          placeholder: "Type your content here",
                          style: {
                            width: "100%",
                            height: "100%",
                            padding: "5px",
                          },
                          rows: 10,
                        }}
                        onSave={(value) =>
                          onSaveSubTopicRubric2(
                            value,
                            predefined_question_answer,
                            predinedQuestionAnswerIndex
                          )
                        }
                        value={predefined_question_answer.incorrect_feedback}
                      />
                      <p
                        className="answer-paragraph"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <b>
                          Incorrect Ans Feedback{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </b>
                      </p>
                    </div>
                    <div className="pl-[90%]" style={{ display: "none" }}>
                      {/* <button
                            className={`btn ${
                              !predefined_question_answer.question ||
                              !predefined_question_answer.right_option_id ||
                              predefined_question_answer.question_marks === 0 ||
                              !predefined_question_answer.correct_feedback ||
                              !predefined_question_answer.incorrect_feedback
                                ? "disable-button"
                                : "btn"
                            }`}
                            onClick={() =>
                              handleSaveData(predinedQuestionAnswerIndex)
                            }
                            style={{
                              backgroundColor: "#403151",
                              color: "white",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              fontSize: "14px",
                              lineHeight: "20px",
                              fontFamily: "Roboto",
                            }}
                            // ... other properties
                            disabled={
                              !predefined_question_answer.question ||
                              !predefined_question_answer.right_option_id ||
                              predefined_question_answer.question_marks === 0 ||
                              !predefined_question_answer.correct_feedback ||
                              !predefined_question_answer.incorrect_feedback
                            }
                          >
                            Save
                          </button> */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "#B3B3B3",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
              </React.Fragment>
            )
          )}
        </p>
      </div>
    </div>
  );
}

export default LessonPages;
