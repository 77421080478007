import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { ToastHeader } from "react-bootstrap";
import { USER_TYPE_BY_PRODUCT } from "../../Components/Constants/EnvConstants";
function Users() {
  const [validEmail, setValidEmail] = useState(true);
  const columns = [
    {
      id: "email_address",
      label: "Email",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
    {
      id: "password",
      label: "Password",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
    {
      id: "account_auth_type",
      label: "Opt-in Type",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
    {
      id: "creation_date",
      label: "Creation Date",
      minWidth: 100,
      maxWidth: 150,
      align: "center",
    },
    {
      id: "last_login_date",
      label: "Last Login Date",
      minWidth: 100,
      maxWidth: 150,
      align: "center",
    },

    {
      id: "last_created_classroom",
      label: "Class Creation Date",
      minWidth: 170,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    // {
    //   id: "last_attempted_quiz_date",
    //   label: "Last Attempted Quiz Date",
    //   minWidth: 170,
    //   align: "center",
    //   format: (value) => value.toLocaleString("en-US"),
    // // },
    // {
    //   id: "creation_tokens_count",
    //   label: "Token Usage (Creation)",
    //   minWidth: 170,
    //   maxWidth: 200,
    //   align: "center",
    // },
    // {
    //   id: "evaluation_tokens_count",
    //   label: "Token Usage (Evaluation)",
    //   minWidth: 170,
    //   maxWidth: 200,
    //   align: "center",
    // },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [show, setShow] = useState(false);
  const [heading, setHeading] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [rows, setRows] = useState([]);
  const [createUser, setCreateUser] = useState({
    email_address: "",
    password: "",
    user_type: "",
  });
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");
  useEffect(() => {
    if (userType === "1") {
      navigate("/");
      return;
    }
  }, [navigate, userType]);

  const onChange = (e) => {
    setCreateUser({ ...createUser, [e.target.name]: e.target.value });
  };

  const getWebUsers = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_web_users",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
      })
      .then((res) => {
        var web_users = res.data.web_users;
        console.log(web_users);
        setRows(web_users);
      });
    console.log(rows);
  };

  useEffect(() => {
    getWebUsers();
  }, []);

  const isSubmitting = useRef(false);
  function submitForm() {
    var token = "Bearer " + localStorage.getItem("access_token");

    var email_address = document.getElementById("email_address").value;
    var password = document.getElementById("password").value;
    var user_type = document.getElementById("user_type_dropdown").value;
    if (user_type === "1") {
      userType = USER_TYPE_BY_PRODUCT;
    }
    //const sub_topic_id_list = [1, 2]
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_user", {
        method: "POST",
        email: email_address,
        password: password,
        user_type: user_type,
      })

      .then((response) => {
        console.log(response);

        isSubmitting.current = false;
        setShow(true);
        setHeading("Created");
        setParagraph("Awesome! New User  Created. 🎉");
        setValidEmail(true);
        // alert("Account created successfully");
        getWebUsers();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    setOpen(false);
    submitForm();
  };

  const handleDelateOptionClick = (event) => {
    var email_address = document.getElementById("email_address").value;
    var password = document.getElementById("password").value;

    // Regular expression for validating an email address
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    // Check if the email address matches the pattern
    if (!emailRegex.test(email_address) && password.length > 0) {
      setValidEmail(false);

      return;
    }

    if (email_address.length > 0 && password.length > 0) {
      // Continue with your logic if both email and password are valid
      setOpen(true);
    } else {
      alert("Please enter all details");
    }
  };

  const updateUserAccountStatus = (status, web_user_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          "/update_web_user_account_status",
        {
          method: "POST",
          web_user_id: web_user_id,
          status: status,
        }
      )

      .then((response) => {
        // alert("Subject Status changed");
        setShow(true);
        setHeading("Status");
        setParagraph("Awesome! User Status Changed. 🎉");

        getWebUsers();
      })
      .catch((error) => {
        console.log(error);
        // alert("unable to update web user status");
      });
  };

  return (
    <>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast
          style={{ backgroundColor: "#f7f2f9" }}
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <ToastHeader>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_99_1907"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_99_1907)">
                <path
                  d="M9.54961 18.0001L3.84961 12.3001L5.27461 10.8751L9.54961 15.1501L18.7246 5.9751L20.1496 7.4001L9.54961 18.0001Z"
                  fill="#0AB30A"
                />
              </g>
            </svg>
            <span className="snackbarh" style={{ marginRight: "10%" }}>
              {heading}
            </span>
          </ToastHeader>
          <Toast.Body>
            <span className="snackbarp">{paragraph}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Dialog
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to create this account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button
            className="delete-button"
            onClick={handleCloseWithYes}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Sidebar item_id="user_option" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Add New User</label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="90"
              height="14"
              viewBox="0 0 90 14"
              fill="none"
            >
              <path
                d="M2 14C2 12.3431 3.34315 11 5 11H85C86.6569 11 88 12.3431 88 14V14H2V14Z"
                fill="#403151"
              />
            </svg>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center ">
          <div className=""></div>
          <div className="selectionDiv">
            <div className="form-div">
              <div className="md:w-[600px]">
                <div>
                  <label className="create_quiz_label">
                    Enter user email address
                  </label>
                </div>
                <div>
                  <input
                    type="email"
                    name="email_address"
                    onChange={onChange}
                    id="email_address"
                    className="teepee-input"
                    placeholder=""
                    required
                  />
                </div>
                <div>
                  {!validEmail && (
                    <p
                      style={{
                        color: "red",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                      }}
                    >
                      Please enter a valid email like abc@mail.com.
                    </p>
                  )}
                </div>
                <div>
                  <label className="create_quiz_label">Password</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="password"
                    onChange={onChange}
                    id="password"
                    className="teepee-input"
                    placeholder=""
                    required
                  />
                </div>

                <div>
                  <label className="create_quiz_label">Role.</label>
                </div>
                <div>
                  <div className="">
                    <select
                      id="user_type_dropdown"
                      onChange={onChange}
                      name="user_type"
                      className="teepee-select shadow-none leading-[30px]  mb-5 font-[300] px-4 w-[100%] h-[100%] bg-transparent"
                    >
                      <option value="1">Client</option>
                      <option value="2">Admin</option>
                    </select>
                  </div>
                </div>
                <div>
                  {/* <button
                    onClick={handleClick}
                    className="teepee-button hover:bg-black"
                  >
                    Create New User
                  </button> */}

                  <button
                    onClick={handleDelateOptionClick}
                    disabled={isSubmitting.current}
                    className="teepee-button hover:bg-black"
                    style={{ width: "46%", backgroundColor: "#7E418B" }}
                  >
                    {isSubmitting.current && (
                      <div
                        className="spinner-border spinner-border-m float-left"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    Create User
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-left items-center sm:ml-[10px] md:ml-[60px] mt-[20px] sm:mr-[10px] md:mr-[60px]">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 840 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#f7f2f9",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      key={"action"}
                      align={"center"}
                      style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}
                    >
                      Product Type
                    </TableCell>

                    <TableCell
                      key={"action"}
                      align={"center"}
                      style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                style={{
                                  minWidth:
                                    column.id === "email_address"
                                      ? "300px"
                                      : column.minWidth,
                                  maxWidth: column.maxWidth,
                                  overflowWrap: "break-word",
                                  borderLeft:
                                    "1px solid rgba(224, 224, 224, 1)",
                                }}
                                key={column.id}
                                align={
                                  column.id === "email_address" ||
                                  column.id === "password"
                                    ? "left"
                                    : "center"
                                }
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                          <TableCell
                            style={{
                              minWidth: 170,
                              maxWidth: 170,
                              overflowWrap: "break-word",
                              borderLeft: "1px solid rgba(224, 224, 224, 1)",
                            }}
                            key={"action"}
                            align={"center"}
                          >
                            {row["user_type"] === 1
                              ? "Geovise"
                              : row["user_type"] === 2
                              ? "Geovise Admin"
                              : row["user_type"] === 3 && "Byteback"}
                          </TableCell>

                          <TableCell
                            style={{
                              minWidth: 170,
                              maxWidth: 170,
                              overflowWrap: "break-word",
                              borderLeft: "1px solid rgba(224, 224, 224, 1)",
                            }}
                            key={"action"}
                            align={"center"}
                          >
                            {row["status"] === 0 && (
                              <button
                                onClick={() =>
                                  updateUserAccountStatus(1, row["web_user_id"])
                                }
                                className="btn btn-danger"
                              >
                                Activate
                              </button>
                            )}
                            {row["status"] === 1 && (
                              <button
                                onClick={() =>
                                  updateUserAccountStatus(0, row["web_user_id"])
                                }
                                className="btn btn-danger"
                              >
                                De-Activate
                              </button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{ backgroundColor: "#f7f2f9" }}
              rowsPerPageOptions={[100, 150, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}

export default Users;
