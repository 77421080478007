import React, { useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import EdiText from "react-editext";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ViewSubjectDetails from "./ViewSubjectDetials";
import ViewLessonDetails from "./Lesson/ViewLessonDetails";

function ViewLessonContent() {
  const [lessonData, setLessonData] = React.useState(null);
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");

  useEffect(() => {
    getLessonDetails(id);
  }, [id]);

  const onSaveLesson = (value, subject) => {
    subject.subject_name = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_subject", {
        method: "POST",
        subject_name: subject.subject_name,
        subject_id: subject.subject_id,
      })

      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  function getLessonDetails(area_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_lesson_for_admin", {
        method: "POST",
        area_id: area_id,
      })

      .then((response) => {
        console.log("DATA IN THE JSON: ", response.data["lesson_data"]);
        var lesson_list = response.data["lesson_data"];

        console.log("EXAM STYLE: ", lesson_list);

        setLessonData(lesson_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const addTodo = () => {
    getLessonDetails(id);
  };

  return (
    <div>
      <Sidebar item_id="view_subject" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className=" flex-row justify-left items-center sm:pl-[10px] sm:pr-[10px] md:pl-[60px] md:pr-[40px] mt-[20px]">
          {lessonData?.map((lesson, lessonIndex) => (
            <div>
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  color: "#49454F",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    color: "#49454F",
                  }}
                >
                  {"Area Name: " + lesson.area_name}
                  {/* <EdiText
                    submitOnEnter={false}
                    type="text"
                    value={subject.subject_name}
                    onSave={(value) => onSaveLesson(value, subject)}
                  /> */}
                </p>
              </p>
            </div>
          ))}
          {
            <Tabs>
              <TabList>
                <Tab>Add Lesson Content </Tab>
              </TabList>

              <TabPanel>
                <ViewLessonDetails addTodo={addTodo} lesson_data={lessonData} />
              </TabPanel>
            </Tabs>
          }
        </div>
      </div>
    </div>
  );
}

export default ViewLessonContent;
