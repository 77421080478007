import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cross from "../../images/cross.png";
import Button from "@mui/material/Button";

export default function YesNoDialog(props) {
  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "370px",
            height: "224px",
            padding: "10px",
          },
        }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="flex justify-between">
          {props.title}
          <button onClick={props.handleCloseWithNo}>
            <img src={Cross} alt="Close"></img>{" "}
            {/* Replace 'Cross' with your image path */}
          </button>
        </DialogTitle>
        <DialogContent className="overflow-hidden">
          <DialogContentText id="alert-dialog-description">
            {props.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-[20px]">
          <span style={{ marginRight: "46px" }}>
            <Button
              onClick={props.handleCloseWithNo}
              className="dialogue-button-text"
              style={{
                color: "#7E418B",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.1px",
              }}
            >
              CANCEL
            </Button>
          </span>
          <Button
            onClick={props.handleCloseWithYes}
            className="teepee-button"
            style={{
              color: "white",
              backgroundColor: "#7E418B",
              padding: "10px",
              paddingLeft: "24px",
              paddingRight: "24px",
              textAlign: "center",
              fontFamily: "Roboto",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "20px",
              letterSpacing: "0.1px",
            }}
            autoFocus
          >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
