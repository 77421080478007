import React from "react";

function GetStartedChildComponent({ headingsArray }) {
  return (
    <div className=" grid md:grid-cols-2 gap-14 sm:p-[10px] ">
      {headingsArray.map((heading) => (
        <div
          className={
            heading.isDesktop
              ? "mr-[10%] sm:hidden md:block"
              : "mr-[10%] md:hidden sm:block"
          }
        >
          <div className="flex">
            <div className="mr-[2%]">{heading.icon}</div>
            <div
              className="onboarding-sub-heading1 text-start"
              style={{ color: "#403151" }}
            >
              {heading.heading}
            </div>
          </div>
          <div className="flex">
            <div
              style={{ visibility: "hidden", fontFamily: "Roboto" }}
              className="mr-[2%]"
            >
              🚀
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: heading.subHeading }}
              className="onboarding-sub-heading2 text-start md:whitespace-nowrap overflow-visible "
              style={{ color: "rgba(64, 49, 81, 0.84)" }}
              //   style={{ whiteSpace: "nowrap", overflow: "visible" }}
            ></div>
          </div>
          {/* <div className="w-[10%]"></div> */}
        </div>
      ))}
    </div>
  );
}

export default GetStartedChildComponent;
