import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import appContext from "../../context/appContext";
import DashboardLabel from "./DashboardLabel";
import UpdateLessonPlanButton from "./UpdateLessonPlanButton";
import ParticipationMetrics from "./ParticipationMetrics";
import AreasToFocusOn from "./AreasToFocusOn";
import StudentsNeedingAttention from "./StudentsNeedingAttention";
import CommonMisconceptionsTable from "./CommonMisconceptionsTable";

function TeacherDashboardTabBody(props) {
  const [assignedQuizzes, setAssignedQuizzes] = useState([]);
  const [draftQuizzes, setDraftQuizzes] = useState([]);
  const navigate = useNavigate();
  const state = useContext(appContext);
  const classroomData = props.classroomData;

  const handleClick = () => {
    navigate("/update-lesson-plans", {
      state: {
        classroomData: props.classroomData,
        selectedSubject: classroomData.subject,
        class_data: classroomData,
        subject_status_have_lesson:
          props.classroomData.subject.subject_status_have_lesson,
      },
    });
  };

  useEffect(() => {
    // Add any necessary API calls here
  }, [props.classroomData]);

  return (
    <div>
      <DashboardLabel />
      <UpdateLessonPlanButton
        handleClick={handleClick}
        assignedQuizzes={assignedQuizzes}
        draftQuizzes={draftQuizzes}
      />
      <div className="md:ml-[20px] mt-[20px] mb-[40px]">
        <div className="teepee-divider"></div>
      </div>
      <ParticipationMetrics data={props.data} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <AreasToFocusOn data={props.data} />
        <StudentsNeedingAttention data={props.data} />
      </div>
      <CommonMisconceptionsTable data={props.data} />
    </div>
  );
}

export default TeacherDashboardTabBody;
