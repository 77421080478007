import React, { useState } from 'react';
import axios from 'axios';

const SupportPage = () => {
    const [email, setEmail] = useState('');
    const [feedback, setFeedback] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setDialogOpen(true);
        handleFeedbackSubmission();
    };

    const handleFeedbackSubmission = () => {
        axios
            .request({
                method: "POST",
                url: process.env.REACT_APP_REST_API_BASE_URL + "/create_support_feedback",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                data: JSON.stringify({
                    email: email,
                    feedback: feedback,
                }),
            })
            .then((res) => {
                // setDialogOpen(false);
                setResponseMessage('Feedback submitted successfully.');
            })
            .catch((err) => {
                // setDialogOpen(false);
                setResponseMessage('Failed to submit feedback. Please try again.');
            });
    };

    const clearAllFields = () => {
        setEmail('');
        setFeedback('');
        setDialogOpen(false);

    };

    return (
        <div className="p-4">
            <form onSubmit={handleSubmit} className="space-y-4">

                <div className="space-y-1">
                    <label htmlFor="email" className="block font-medium">Email:</label>
                    <input
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        type="email"
                    />
                </div>
                <div className="space-y-1">
                    <label htmlFor="feedback" className="block font-medium">Feedback:</label>
                    <textarea
                        id="feedback"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        rows="4"
                    />
                </div>

                <button type="submit" className="w-full py-2 px-4 rounded-md bg-blue-500 text-white hover:bg-blue-600">
                    Submit
                </button>
            </form>

            {dialogOpen && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-50">
                    <div className="bg-white p-6 rounded-md shadow-xl">
                        <h2 className="text-lg font-medium mb-2">Thank You!</h2>
                        <p className="text-sm mb-2">{responseMessage}</p>

                        {/* <h2 className="text-lg font-medium mb-2"></h2> */}
                        <button onClick={clearAllFields} className="w-full py-2 px-4 rounded-md bg-gray-500 text-white hover:bg-gray-600">
                            Ok
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

// Export the component
export default SupportPage;