import React, { useRef, useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import BackArrow from "../../images/icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { ToastHeader } from "react-bootstrap";
import copy from "copy-to-clipboard";
export default function Share() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const location = useLocation();

  var id = params.get("id");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const toastMessage = useRef("");
  const toastHeadeing = useRef("");
  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_220_7960" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_220_7960)">
        <path d="M9.5501 17.9996L3.8501 12.2996L5.2751 10.8746L9.5501 15.1496L18.7251 5.97461L20.1501 7.39961L9.5501 17.9996Z" fill="#0AB30A"/>
      </g>
    </svg>
  
  `;
  //const [sso_required, setSsoRequired] = useState(true);
  const goBack = () => {
    navigate(-2);
  };
  useEffect(() => {
    if (count === 0) {
      toastHeadeing.current = (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: svgContent }}
            style={{ width: "24px", height: "24px", marginRight: "10%" }}
          />
          <span>Class Created</span>
        </div>
      );
      toastMessage.current = "Awesome! Your class is now up and running. 🎉";
      setShow(true);
      setCount(1);
    }
  });
  return (
    <div>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast
          style={{ backgroundColor: "#f7f2f9" }}
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <ToastHeader>{toastHeadeing.current}</ToastHeader>
          <Toast.Body>{toastMessage.current}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Sidebar item_id="quiz" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label" style={{ color: "#403151" }}>
              Create Class
            </label>
          </div>
          <div className="pl-10">
            <label className="disabled-label" style={{ color: "#403151" }}>
              Add lesson
            </label>
          </div>
          <div className="pl-10">
            <label className="disabled-label" style={{ color: "#403151" }}>
              Share
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="37"
                height="14"
                viewBox="0 0 37 14"
                fill="none"
              >
                <path
                  d="M2 14C2 12.3431 3.34315 11 5 11H32C33.6569 11 35 12.3431 35 14V14H2V14Z"
                  fill="#403151"
                />
              </svg>
            </label>
          </div>
        </div>
        <div className="form-div">
          <div className="w-[600px] mr-[15%] mt-[36px]">
            <div>
              <p
                style={{
                  color: "#403151",

                  fontFamily: "Roboto",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontweight: "700",
                  lineheight: "normal",
                }}
              >
                {" "}
                <b>Your class is all set up!</b>
              </p>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    color: "#403151",

                    fontFamily: "Roboto",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                >
                  Now, just copy the code below and share it with your students
                </p>
                {/* <MdSettings className="mt-[4px] ml-[20px]" style={{width:"20px", height:"20px"}} onClick={() => {
                      setOpen(true)
                    }}/> */}
              </div>
              <p
                style={{
                  color: "#49454F",

                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px" /* 150% */,
                  letterSpacing: "0.5px",
                  marginTop: "30px",
                }}
              >
                Copy this code
              </p>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  height: "64px",
                }}
              >
                <input
                  type="text"
                  name="text"
                  id="loginInput"
                  style={{
                    borderRadius: "4px 4px 0px 0px",
                    padding: "2%",
                    paddingRight: "30px", // Make room for the SVG
                    color: "#FFFFFF", // Assuming you want the text color white as previously mentioned
                  }}
                  className="mt-0 rounded-0 border-[1px] border-[#49454F96] w-full h-full text-[#49454F96] leading-[30px] text-[20px] font-[500] pl-4"
                  value={location.state.classes_data.class_data.classroom_code}
                  disabled
                />
                <button>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    style={{
                      position: "absolute",
                      right: "20px", // Adjust as needed
                      top: "20px", // Adjust as needed to place it above the input
                    }}
                    onClick={() => {
                      toastHeadeing.current = (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "50%",
                          }}
                        >
                          <span>Code Copied</span>
                        </div>
                      );
                      toastMessage.current =
                        "Awesome! Class code has been copied. 🎉";
                      setShow(true);
                      copy(
                        "Hello students! \n" +
                          "Join our new classroom in Geovise \n" +
                          "Classroom code: " +
                          location.state.classes_data.class_data
                            .classroom_code +
                          "\n" +
                          "Download the app here: \n" +
                          "Apple App Store: [https://apps.apple.com/pk/app/geovise/id6478696875]\n" +
                          "Google Play Store: [https://play.google.com/store/apps/details?id=com.highersummit.geovise]\n" +
                          "After downloading, open the app, sign up, and enter the classroom code to join. \n" +
                          "See you there!"
                      );
                    }}
                  >
                    <g mask="url(#mask0_107_4633)">
                      <path
                        d="M9 18C8.45 18 7.97917 17.8042 7.5875 17.4125C7.19583 17.0208 7 16.55 7 16V4C7 3.45 7.19583 2.97917 7.5875 2.5875C7.97917 2.19583 8.45 2 9 2H18C18.55 2 19.0208 2.19583 19.4125 2.5875C19.8042 2.97917 20 3.45 20 4V16C20 16.55 19.8042 17.0208 19.4125 17.4125C19.0208 17.8042 18.55 18 18 18H9ZM9 16H18V4H9V16ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6H5V20H16V22H5Z"
                        fill="#49454F"
                      />
                    </g>
                  </svg>
                </button>
              </div>

              <div className="create-quiz-button mt-3">
                <div>
                  <button
                    onClick={goBack}
                    style={{ border: "1px solid #403151", color: " #7E418B" }}
                    className="teepee-back-button sm:w-[43%] md-w-[46%]"
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img alt="" src={BackArrow} className="pr-4"></img>
                      <div>Go Back</div>
                    </div>
                  </button>

                  <button
                    onClick={() => {
                      navigate(
                        "/teacher-classroom-home?id=" +
                          location.state.classes_data.class_data.classroom_id,
                        {
                          state: {
                            subject_status_have_lesson:
                              location.state.subject_status_have_lesson,
                          },
                        }
                      );
                    }}
                    style={{ backgroundColor: "#7E418B", color: "white" }}
                    className="teepee-button  sm:w-[43%] md-w-[46%]"
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
