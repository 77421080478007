import React, { useState, useEffect } from "react";
import Sidebar from "../../Components/Sidebar";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../images/plus.svg";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import DashboardClassroomCard from "./DashboardClassroomCard";
import { useContext } from "react";
import appContext from "../../context/appContext";
import StickyFooterDashboard from "./widgets/StickyFooterDashboard";
import ConfirmationDialogue from "./confirmationDialogue/ConfirmationDialogue";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import GetStartedChildComponent from "./OnBoarding/GetStartedChildComponent";
import Info from "../../images/info.png";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";

function TeacherDashboard() {
  const state = useContext(appContext);
  const navigate = useNavigate();
  const [quizzes, setQuizzes] = useState([]);
  const [foldersList, setFoldersList] = useState([]);
  const [classroomList, setClassroomList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(1);
  const [createDialogStatus, setCreateDialogStatus] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [stickyEditIndex, setStickyEditIndex] = useState(-1);
  const [stickyEditQuiz, setStickyEditQuiz] = useState();
  const [toasterValue, setToasterValue] = useState();
  const [show, setShow] = useState(false);
  const [quizCount, setQuizCount] = useState(0);
  const [deleteQuizList, setDeleteQuizList] = useState([]);
  const [openDeleteConfirmationDialogue, setOpenDeleteConfirmationDialogue] =
    useState(false);
  const [isActiveQuiz, setIsActiveQuiz] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openQuizTooltip, setOpenQuizTooltip] = useState(false);

  const handleOpenQuizTip = () => {
    setOpenQuizTooltip(true);
  };
  const handleCloseQuizTip = () => {
    setOpenQuizTooltip(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [folderDetail, setFolderDetail] = useState({
    folderName: "",
    description: "",
  });
  const [checked, setChecked] = useState([]);

  const [headingsArray] = useState([
    {
      icon: "🏫",
      heading: "Set Up Your Classroom Today",
      subHeading: "Begin the journey towards enhanced learning.",
      isDesktop: true,
    },
    {
      icon: "🎯",
      heading: "Effective Recall",
      subHeading:
        "Utilize quizzes to boost retention and master key<br/> concepts.",
      isDesktop: true,
    },
    {
      icon: "📚",
      heading: "Tailored Learning",
      subHeading: "Customize content to meet your student's<br/> unique needs.",
      isDesktop: true,
    },
    {
      icon: "🏅",
      heading: "Achieve Mastery",
      subHeading:
        "Guide students towards academic excellence in a <br/> supportive environment.",
      isDesktop: true,
    },
    {
      icon: "🏫",
      heading: "Set Up Your Classroom Today",
      subHeading: "Begin the journey toward enhanced learning.",
      isDesktop: false,
    },
    {
      icon: "🎯",
      heading: "Effective Recall",
      subHeading:
        "Utilize quizzes to boost retention and master key<br/> concepts.",
      isDesktop: false,
    },
  ]);

  const onFolderClick = (folder) => {
    setSelectedFolder(folder);
    getQuizzesList(folder);
  };

  const refreshData = () => {
    setSelectedFolder(selectedFolder);
    getQuizzesList(selectedFolder);
  };

  // const location = useLocation();
  // const { data } = location.state;

  // let datad = [
  //   { number: "1", other: "some data" },
  //   { number: "1", other: "some data" },
  // ];
  useEffect(() => {
    state.setDashboardTabsState(1);
    localStorage.setItem("teacher_table_state", 1);
    setSelectedFolder(null);
    getQuizzesList(null);
    getFoldersList(false);

    getClassroomList();
  }, []);

  const getClassroomList = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_classes", {
        method: "POST",
      })
      .then((res) => {
        console.log("classroom list: ", res.data.classes_data);
        setClassroomList(res.data.classes_data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createNewFolder = (folderDetail) => {
    // alert(subject.subject_name);

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_folder", {
        method: "POST",
        folder_name: folderDetail.folderName,
        description: folderDetail.description,
      })

      .then((response) => {
        getFoldersList(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeFolderName = (e) => {
    setFolderDetail({ ...folderDetail, [e.target.name]: e.target.value });
  };
  const onChangeFolderDescription = (e) => {
    setFolderDetail({ ...folderDetail, [e.target.name]: e.target.value });
  };
  const getQuizzesList = (folder) => {
    // console.log("Folder Id: ", folder.folder_id);
    setQuizzes([]);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_quizzes", {
        method: "POST",
        folder_id: folder !== null ? folder.folder_id : null,
        class_id: null,
      })

      .then((res) => {
        console.log(res.data.quizzes_data);
        setQuizzes(JSON.parse(res.data.quizzes_data));
        setClassroomList(res.data.classes_data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const editedQuizName = (quiz_id, editedValue) => {
    console.log("quizzes are: ", quizzes);
    let index = quizzes.findIndex((quiz) => quiz.quiz_id === quiz_id);
    let newQuizData = quizzes;
    newQuizData[index].quiz_title = editedValue.toString();
    setQuizzes(newQuizData);
  };
  const getFoldersList = (isNewFolderCreated) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    console.log("TOKEN", token);
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_folders_list",

        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
      })
      .then((res) => {
        setFoldersList(res.data);

        if (isNewFolderCreated) {
          if (res.data.length > 0) {
            setSelectedOption(2);
          }
          if (res.data.length === 1) {
            onFolderClick(res.data[res.data.length - 1]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = () => {
    navigate("/create-classroom", {
      state: {
        folder_id: selectedFolder !== null ? selectedFolder.folder_id : null,
      },
    });
  };
  const handleCloseWithCancel = () => {
    setCreateDialogStatus(false);
  };

  const handleCloseWithYes = () => {
    if (folderDetail.folderName === null || folderDetail.folderName === "") {
      return;
    }

    setCreateDialogStatus(false);
    createNewFolder(folderDetail);
  };

  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    let quizIds = [];
    let stickyEditedQuiz = null;
    for (let index = 0; index < quizzes.length; index++) {
      const element = quizzes[index];
      if (element.quiz_id.toString() === updatedList[0]) {
        setStickyEditQuiz(element);
      }
      quizIds.push(element.quiz_id.toString());
    }
    setStickyEditIndex(quizIds.indexOf(updatedList[0]));
    setChecked(updatedList);
  };
  const handleCancel = () => {
    var quizzesList = document.getElementsByName("quizzes");
    for (let index = 0; index < quizzesList.length; index++) {
      quizzesList[index].checked = false;
    }
    setChecked([]);
  };

  const handleCopyMultipleQuizzes = () => {
    setToasterValue(2);
    let quiz_id_list = [];
    for (let index = 0; index < checked.length; index++) {
      const element = parseInt(checked[index]);
      quiz_id_list.push(element);
    }
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/get_quiz_data_for_copy",
        {
          method: "POST",
          quiz_id_list: quiz_id_list,
          quiz_name: null,
          quiz_status: null,
        }
      )

      .then((response) => {
        console.log(response);
        getQuizzesList(null);
        setToasterValue(2);
        setShow(true);
        setQuizCount(checked.length);
        handleCancel();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteMultipleQuiz = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/check_student_data_in_quiz",
        {
          method: "POST",
          quiz_id_list: checked,
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.msg === 0) {
          setDeleteQuizList(checked);
          setOpenDeleteConfirmationDialogue(true);
          setIsActiveQuiz(false);
        } else {
          setIsActiveQuiz(true);
          setDeleteQuizList(checked);
          setOpenDeleteConfirmationDialogue(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseWithCancelDelete = () => {
    setOpenDeleteConfirmationDialogue(false);
  };
  const handleOpenDeleteDialogue = () => {
    setOpenDeleteConfirmationDialogue(true);
  };

  const handleSetDeleteQuiz = (quiz_list) => {
    setDeleteQuizList(quiz_list);
  };

  const handleCloseDeleteWithYes = () => {
    console.log("DELETING ARRAY : ", deleteQuizList);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/remove_quiz", {
        method: "POST",
        quiz_id_list: deleteQuizList,
        quiz_name: null,
        quiz_status: null,
      })
      .then((response) => {
        console.log(response);
        setOpenDeleteConfirmationDialogue(false);
        getQuizzesList(null);
        handleCancel();
        setShow(true);
        setToasterValue(4);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSetActive = (isActiveQuiz) => {
    setIsActiveQuiz(isActiveQuiz);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#49454F",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      borderRadius: "8px",
      opacity: "0.8",
      backgroundColor: "#FFF",
      padding: "12px 16px",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  return (
    <>
      <ToastContainer className="p-3 position-fixed top-12 right-0 left-0">
        <Toast
          style={{ backgroundColor: "#f7f2f9" }}
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">
              {toasterValue === 1 || toasterValue === 2
                ? "Quiz Copied"
                : toasterValue === 3
                ? "Quiz Renamed!"
                : "Quiz Deleted!"}
            </strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            {toasterValue === 1 || toasterValue === 2
              ? quizCount === 1
                ? `${quizCount} Quiz copied successfully!`
                : `${quizCount} Quizzes copied successfully!`
              : toasterValue === 3
              ? "Quiz name updated successfully!"
              : "Quiz deleted successfully!"}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <ConfirmationDialogue
        title={isActiveQuiz ? "Delete Active Quiz" : "Delete Quiz"}
        text={
          isActiveQuiz
            ? "This quiz contains student attempts. Are you sure you want to permanently delete?"
            : "Once deleted, this quiz will no longer be accessible. Are you sure you want to proceed?"
        }
        open={openDeleteConfirmationDialogue}
        handleCloseWithConfirm={handleCloseDeleteWithYes}
        handleCloseWithCancel={handleCloseWithCancelDelete}
      />
      <Dialog
        className="overflow-y-hidden"
        open={createDialogStatus}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <b>Folder Details</b>
        </DialogTitle>
        <DialogContent className="overflow-y-hidden">
          <div className="mt-[2px]">
            <label className="create_quiz_label">Folder Name</label>
            <input
              type="text"
              required={true}
              value={folderDetail.folderName}
              onChange={onChangeFolderName}
              name="folderName"
              style={{ height: "45px" }}
              className="textfield-text border-[1px] border-[#8B8B90] rounded-md w-full px-4 text-black leading-tight  "
              id="folderName"
              placeholder="Enter folder name"
            />
          </div>
          <div>
            <label className="normal_label mt-[13px] ">
              {"Aditional Detail(optional)"}
            </label>
            <input
              type="text"
              value={folderDetail.description}
              onChange={onChangeFolderDescription}
              name="description"
              className="textfield-text border-[1px] border-[#8B8B90] rounded-md w-full px-4 text-black leading-tight  "
              id="folderDescription"
              placeholder="Enter folder description"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>Cancel</Button>
          <Button
            className="delete-button"
            onClick={handleCloseWithYes}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <Sidebar item_id={"quiz"} />
      </div>
      <div className=" sm:ml-0 md:ml-[315px] mb-[40px] ">
        <StickyFooterDashboard
          handleCopyMultipleQuizzes={handleCopyMultipleQuizzes}
          handleDeleteMultipleQuiz={handleDeleteMultipleQuiz}
          // stickyEditQuiz={props.stickyEditQuiz}
          // editQuizName={editQuizName}
          // stickyEditIndex={props.stickyEditIndex}
          checked={checked}
          handleCancel={handleCancel}
        />
        <div className="flex">
          <div className="quiz-info-light md:items-center w-[60%]">
            <div className="home sm:ml-[20px] md:ml-[60px]">
              <div>
                <label
                  className="dashboard-label mt-[36px]"
                  style={{ color: "#403151" }}
                >
                  Get Started
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="90"
                    height="14"
                    viewBox="0 0 90 14"
                    fill="none"
                    style={{ paddingRight: "5px", paddingLeft: "0px" }}
                  >
                    <path
                      d="M2.5 14C2.5 12.3431 3.84315 11 5.5 11H76.5C78.1569 11 79.5 12.3431 79.5 14H2.5Z"
                      fill="#403151"
                    />
                  </svg>
                </label>
              </div>
            </div>
          </div>

          {/* <div className="teepee-label justify-end w-[40%] ">
            <button
              style={{ fontSize: "14px" }}
              className="create-folder-button"
              onClick={() => {
                setCreateDialogStatus(true);
              }}
            >
              Create Folder
            </button>
          </div> */}
        </div>
        <div className="w-[100%] mb-[7%]">
          <center>
            <div
              className="onboarding-heading mb-[3%] sm:mt-[5vh] "
              style={{ color: "#403151" }}
            >
              🚀 Jumpstart your teaching experience
            </div>
            <div
              className="flex container  w-[100%] justify-center text-center  "
              style={{ color: "#403151" }}
            >
              <GetStartedChildComponent headingsArray={headingsArray} />
            </div>
            <div
              className="create_quiz_div-main-new sm:ml-[20px] md:ml-[0px]"
              onClick={handleClick}
            >
              <button
                style={{ width: "100%", backgroundColor: "#7E418B" }}
                type="button"
                className="create_quiz_button_dashboard-new"
                onClick={handleClick}
              >
                <div className="inline-flex">
                  <img
                    style={{ display: "none" }}
                    className="create-quiz-button-icon"
                    src={plusIcon}
                    alt=""
                  />{" "}
                  <span>
                    {quizzes.length > 0
                      ? "👉 Create Your Classroom Now!"
                      : "👉 Create Your Classroom Now!"}
                  </span>
                </div>
              </button>
            </div>
          </center>
        </div>
        {selectedFolder === null ? (
          <>
            <div className="flex w-[95%] justify-between">
              <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]">
                <div>
                  <div className="flex"></div>

                  {selectedOption === 1 && (
                    <div className="teepee-divider"></div>
                  )}
                </div>
              </div>
            </div>

            <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]">
              <div>
                <div className="flex">
                  <label
                    className={
                      selectedOption === 1
                        ? "dashboard-label cursor-pointer"
                        : "disabled-label cursor-pointer"
                    }
                    onClick={() => {
                      setSelectedOption(1);
                    }}
                    style={{
                      color: "#403151",
                      textAlign: "center",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "20px" /* 142.857% */,
                      letterSpacing: "0.1px",
                    }}
                  >
                    My Classrooms
                  </label>
                  <ClickAwayListener onClickAway={handleClose}>
                    <div className="flex">
                      <HtmlTooltip
                        title="Display of all created classrooms"
                        PopperProps={{
                          disablePortal: true,
                        }}
                        enterTouchDelay={0}
                        leaveTouchDelay={10}
                        open={open}
                        onOpen={handleOpen}
                        onClose={handleClose}
                      >
                        <button
                          className="ml-[5px] mt-[2px]"
                          onClick={handleOpen}
                        >
                          <img src={Info}></img>
                        </button>
                      </HtmlTooltip>
                    </div>
                  </ClickAwayListener>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="110"
                  height="18"
                  viewBox="0 0 100 14"
                  fill="none"
                  style={{ paddingLeft: "1px", paddingRight: "10px" }}
                >
                  <path
                    d="M2.5 14C2.5 12.3431 3.84315 11 5.5 11H94.5C96.1569 11 97.5 12.3431 97.5 14V14H2.5V14Z"
                    fill="#403151"
                  />
                </svg>
              </div>
            </div>
            <div className="my-quiz-div sm:ml-[20px] md:ml-[60px]">
              <DashboardClassroomCard
                classroomData={classroomList}
                handleClick={handleClick}
              />
            </div>
            <div className="my-quiz-div sm:ml-[20px] md:ml-[60px]"></div>
            <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]">
              <div>
                <div className="flex"></div>
                {selectedOption === 1 && <div className="teepee-divider"></div>}
              </div>

              {/* <div className="pl-10">
     <label
       className={
         selectedOption === 2
           ? "dashboard-label cursor-pointer"
           : "disabled-label cursor-pointer"
       }
       onClick={() => {
         setSelectedOption(2);
       }}
     >
       My Folders
     </label>
     {selectedOption === 2 && <div className="teepee-divider"></div>}
   </div> */}
            </div>
          </>
        ) : (
          <div className="home sm:ml[25px] md:ml-[60px] ml-[25px]">
            {/* <div className="">
              <label
                className={
                  selectedOption === 2
                    ? "dashboard-label cursor-pointer"
                    : "disabled-label cursor-pointer"
                }
                onClick={() => {
                  setSelectedOption(2);
                  setSelectedFolder(null);
                  getQuizzesList(null);
                  getFoldersList(false);
                }}
              >
                My Folders
              </label>
              {selectedOption === 2 && <div className="teepee-divider"></div>}
            </div> */}
          </div>
        )}
        <div className="my-quiz-div sm:ml-[20px] md:ml-[60px] mb-[20px]"></div>
      </div>
    </>
  );
}

export default TeacherDashboard;
