import React from "react";
import PlusIcon from "../../images/plus.png";

const UpdateLessonPlanButton = ({
  handleClick,
  assignedQuizzes,
  draftQuizzes,
}) => (
  <div
    className="create_quiz_div-main body md:ml-[20px] mb-[40px]"
    onClick={handleClick}
  >
    <button
      className="w-full bg-[#DBD2DD] text-[#403151]"
      type="button"
      onClick={handleClick}
    >
      <div className="inline-flex items-center">
        <img className="create-quiz-button-icon" src={PlusIcon} alt="" />
        <span>
          {assignedQuizzes.length > 0 || draftQuizzes.length > 0
            ? "Update Lesson Plan"
            : "Update Lesson Plan"}
        </span>
      </div>
    </button>
  </div>
);

export default UpdateLessonPlanButton;
