import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress = ({ StudentParticipationRate, color, label }) => {
  const percentage = 70;

  return (
    <div style={{ width: "180px", height: "180px", position: "relative" }}>
      <CircularProgressbar
        value={parseInt(StudentParticipationRate)}
        styles={buildStyles({
          textColor: "#333",
          pathColor: color,
          trailColor:
            parseInt(StudentParticipationRate) === 0 ? "#FAAD98" : "#f0f0f0",
          textSize: "16px",
        })}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          fontSize: "16px",
          color: "#333",
          fontWeight: "600",
        }}
      >
        {parseInt(StudentParticipationRate)}%<br />
        <span style={{ fontSize: "12px", color: "#aaa", lineHeight: "0.01px" }}>
          {label}
        </span>
      </div>
    </div>
  );
};

export default CircularProgress;
