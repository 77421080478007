import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../Components/Sidebar";
import moment from "moment";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import * as types from "../../Components/Calendar/store/eventActionTypes";
import {
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  TextField,
  Chip,
  Paper,
  List,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch } from "react-redux";

const PurpleRadio = styled((props) => <Radio {...props} />)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
  },
  "&.Mui-checked": {
    color: "#7E418B",
    padding: 0,
    width: "16px",
    height: "16px",
    marginRight: "4px",
    marginLeft: "8px",
  },
  "&:not(.Mui-checked)": {
    color: "transparent",
    border: "2px solid black",
    marginRight: "4px",
    marginLeft: "8px",
    width: "16px",
    height: "16px",
    padding: 0,
  },
  "& .MuiSvgIcon-root": {
    width: "20px",
    height: "20px",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiTouchRipple-root": {
    display: "block",
  },
}));

function AddPriorPlan() {
  const location = useLocation();
  const navigate = useNavigate();
  const [topics, setTopics] = useState([]);
  const [subtopics, setSubtopics] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedSubtopics, setSelectedSubtopics] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const contentType = useRef(
    location.state.subject_status_have_lesson === 0 ? "concept" : "lessons"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getTopicList();
  }, []);

  const getTopicList = () => {
    const token = "Bearer " + localStorage.getItem("access_token");
    const id = location.state.selectedSubject.subject_id;
    const url =
      location.state.subject_status_have_lesson === 0
        ? "/get_topic_for_lesson"
        : "get_super_topic_for_lesson";
    axios
      .request({
        method: "POST",
        url: `${process.env.REACT_APP_REST_API_BASE_URL}` + url,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({ subject_id: id }),
      })
      .then((res) => {
        setTopics(res.data);
      });
  };

  const fetchSubtopics = (topicIds) => {
    const url =
      location.state.subject_status_have_lesson === 0
        ? "/get_sub_topic_for_lesson"
        : "/get_topic_lesson";
    var requests = "";
    if (location.state.subject_status_have_lesson === 0) {
      requests = topicIds.map((topicId) =>
        axios.request({
          method: "POST",
          url: `${process.env.REACT_APP_REST_API_BASE_URL}${url}`,
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: JSON.stringify({ topic_id: topicId }),
        })
      );
    } else {
      requests = topicIds.map((topicId) =>
        axios.request({
          method: "POST",
          url: `${process.env.REACT_APP_REST_API_BASE_URL}${url}`,
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: JSON.stringify({ super_topic_id: topicId }),
        })
      );
    }

    Promise.all(requests)
      .then((responses) => {
        const combinedSubtopics = responses.flatMap((res) => res.data);
        let subtopicIds = combinedSubtopics.map((subtopic) =>
          subtopic.sub_topic_id ? subtopic.sub_topic_id : subtopic.topic_id
        );
        let filteredSelectedSubtopicIds = selectedSubtopics.filter(
          (selectedSubtopicId) => subtopicIds.includes(selectedSubtopicId.value)
        );
        setSelectedSubtopics(filteredSelectedSubtopicIds);
        setSubtopics(combinedSubtopics);
      })
      .catch((error) => {
        console.error("Error fetching subtopics:", error);
      });
  };

  const fetchAreas = (subTopicIds) => {
    const url =
      location.state.subject_status_have_lesson === 0
        ? "/get_areas_for_lesson"
        : "/get_sub_topic_lesson";
    var requests = "";
    if (location.state.subject_status_have_lesson === 0) {
      requests = subTopicIds.map((subTopicId) =>
        axios.request({
          method: "POST",
          url: `${process.env.REACT_APP_REST_API_BASE_URL}${url}`,
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: JSON.stringify({
            sub_topic_id: subTopicId,
            status: contentType.current === "concept" ? 1 : 0,
          }),
        })
      );
    } else {
      requests = subTopicIds.map((subTopicId) =>
        axios.request({
          method: "POST",
          url: `${process.env.REACT_APP_REST_API_BASE_URL}${url}`,
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: JSON.stringify({
            topic_id: subTopicId,
            status: contentType.current === "concept" ? 1 : 0,
          }),
        })
      );
    }

    Promise.all(requests)
      .then((responses) => {
        const combinedAreas = responses.flatMap((res) => res.data);
        let subtopicIds = combinedAreas.map((subtopic) =>
          subtopic.area_id ? subtopic.area_id : subtopic.sub_topic_id
        );
        let filteredSelectedSubtopicIds = selectedAreas.filter(
          (selectedSubtopicId) =>
            subtopicIds.includes(
              selectedSubtopicId.area_id
                ? selectedSubtopicId.area_id
                : selectedSubtopicId.sub_topic_id
            )
        );
        setAreas(combinedAreas);
        setSelectedAreas(filteredSelectedSubtopicIds);
      })
      .catch((error) => {
        console.error("Error fetching areas:", error);
      });
  };

  const lessonStatus = (e) => {
    setSelectedAreas([]);
    contentType.current = e.target.value;
    fetchAreas(selectedSubtopics.map((subtopic) => subtopic.value));
  };
  useEffect(() => {
    fetchAreas(selectedSubtopics.map((subtopic) => subtopic.value));
  }, [selectedSubtopics]);
  const handleTopicChange = (event, newValue) => {
    const uniqueTopics = Array.from(new Set(newValue.map((a) => a.value))).map(
      (value) => {
        return newValue.find((a) => a.value === value);
      }
    );
    setSelectedTopics(uniqueTopics);
    if (uniqueTopics.length > 0) {
      const selectedTopicIds = uniqueTopics.map((topic) => topic.value);
      fetchSubtopics(selectedTopicIds);
    } else {
      setSubtopics([]);
      setSelectedSubtopics([]);
      setAreas([]);
      setSelectedAreas([]);
    }
  };

  const handleSubtopicChange = (event, newValue) => {
    const uniqueSubtopics = Array.from(
      new Set(newValue.map((a) => a.value))
    ).map((value) => {
      return newValue.find((a) => a.value === value);
    });
    setSelectedSubtopics(uniqueSubtopics);
    if (uniqueSubtopics.length > 0) {
      const selectedSubtopicIds = uniqueSubtopics.map(
        (subtopic) => subtopic.value
      );
      fetchAreas(selectedSubtopicIds);
    } else {
      setAreas([]);
      setSelectedAreas([]);
    }
  };

  const handleAreaChange = (e) => {
    const selectedID = e.target.value;
    const isChecked = e.target.checked;
    console.log("Selected ID:", selectedID);
    console.log("Checked:", isChecked);

    let updatedSelectedAreas;

    if (contentType.current === "concept") {
      const selectedArea = areas.find((area) => area.area_name === selectedID);
      if (selectedArea) {
        if (isChecked) {
          updatedSelectedAreas = [
            ...selectedAreas,
            {
              area_id: selectedArea.area_id,
              area_name: selectedArea.area_name,
            },
          ];
        } else {
          updatedSelectedAreas = selectedAreas.filter(
            (area) => area.area_id !== selectedArea.area_id
          );
        }
      }
    } else {
      const selectedArea = areas.find(
        (area) => area.sub_topic_name === selectedID
      );
      if (selectedArea) {
        if (isChecked) {
          updatedSelectedAreas = [
            ...selectedAreas,
            {
              sub_topic_id: selectedArea.sub_topic_id,
              sub_topic_name: selectedArea.sub_topic_name,
            },
          ];
        } else {
          updatedSelectedAreas = selectedAreas.filter(
            (area) => area.sub_topic_id !== selectedArea.sub_topic_id
          );
        }
      }
    }

    setSelectedAreas(updatedSelectedAreas);
    console.log("Updated Selected Areas:", updatedSelectedAreas);
  };

  const handleSelectAllAreas = (e) => {
    if (e.target.checked) {
      if (contentType.current === "concept") {
        setSelectedAreas(
          areas.map((area) => ({
            area_id: area.area_id,
            area_name: area.area_name,
          }))
        );
      } else {
        setSelectedAreas(
          areas.map((area) => ({
            sub_topic_id: area.sub_topic_id,
            sub_topic_name: area.sub_topic_name,
          }))
        );
      }
    } else {
      setSelectedAreas([]);
    }
  };

  const handleCreate = () => {
    setIsSubmitting(true);
    var selectedTopicsObj = [];
    if (location.state.subject_status_have_lesson === 0) {
      selectedTopicsObj = selectedTopics.map((topic) => ({
        topic_id: topic.value,
        topic_name: topic.label,
      }));
    } else {
      selectedTopicsObj = selectedTopics.map((topic) => ({
        super_topic_id: topic.value,
        super_topic_name: topic.label,
      }));
    }
    var selectedSubtopicsObj = [];
    if (location.state.subject_status_have_lesson === 0) {
      selectedSubtopicsObj = selectedSubtopics.map((subtopic) => ({
        sub_topic_id: subtopic.value,
        sub_topic_name: subtopic.label,
      }));
    } else {
      selectedSubtopicsObj = selectedSubtopics.map((subtopic) => ({
        topic_id: subtopic.value,
        topic_name: subtopic.label,
      }));
    }

    var newEventObj = new Object();
    const areaIds = selectedAreas.map((area) =>
      area.area_id ? area.area_id : area.sub_topic_id
    );
    const areaIdsCsv = areaIds.join(",");

    newEventObj = {
      calendar_id: 0,
      title: "Today!",
      allDay: true,
      start: moment(),
      end: moment(),
      selected_topic: selectedTopicsObj,
      selected_sub_topic: selectedSubtopicsObj,
      contentType: contentType.current,
      data: {
        subject_name: location.state.data.quizSubject,
        area_object_lists: selectedAreas,
        area_ids_csv: areaIdsCsv,
      },
    };

    console.log("events are" + JSON.stringify(newEventObj));
    newEventObj.selected_sub_topic = selectedSubtopicsObj;
    newEventObj.selected_topic = selectedTopicsObj;
    // Check if there are any areas selected
    dispatch({ type: types.ADD_EVENT, payload: newEventObj });

    // Prepare the list of plans
    const list_of_plans = [
      {
        area_ids_csv: contentType.current === "lessons" ? null : areaIdsCsv,
        learn_date: moment().format("YYYY-MM-DD"),
        sub_topic_ids_csv:
          contentType.current === "lessons" ? areaIdsCsv : null,
      },
    ];

    // Making the API request to create a classroom
    axios
      .post(`${process.env.REACT_APP_REST_API_BASE_URL}/create_classroom`, {
        class_name: location.state.data.quizName,
        subject_id: location.state.selectedSubject.subject_id,
        list_of_plans: list_of_plans,
        product_type: localStorage.getItem("user_type") === "3" ? 2 : 1,
      })

      .then((response) => {
        setIsSubmitting(false);
        navigate("/share", {
          state: {
            classes_data: response.data,
          },
        });
      })
      .catch((error) => {
        setIsSubmitting(false);
        alert("Class with the same name already exists.");
        console.error(error);
      });
  };

  const topicOptions = topics.map((topic) => ({
    label:
      location.state.subject_status_have_lesson === 0
        ? topic.topic_name
        : topic.super_topic_name,
    value:
      location.state.subject_status_have_lesson === 0
        ? topic.topic_id
        : topic.super_topic_id,
  }));

  const subtopicOptions = subtopics.map((subtopic) => ({
    label:
      location.state.subject_status_have_lesson === 0
        ? subtopic.sub_topic_name
        : subtopic.topic_name,
    value:
      location.state.subject_status_have_lesson === 0
        ? subtopic.sub_topic_id
        : subtopic.topic_id,
  }));

  return (
    <>
      <Sidebar item_id="quiz" />
      <div className="sm:ml-[10px] md:ml-[315px]">
        <div className="home sm:ml[0px] md:ml-[65px]">
          <div>
            <label className="dashboard-label" style={{ color: "#403151" }}>
              Create Class
            </label>
          </div>
          <div className="pl-10">
            <label className="disabled-label" style={{ color: "#403151" }}>
              Add lesson
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="14"
                viewBox="0 0 82 14"
                fill="none"
              >
                <path
                  d="M2.5 14C2.5 12.3431 3.84315 11 5.5 11H76.5C78.1569 11 79.5 12.3431 79.5 14H2.5Z"
                  fill="#403151"
                />
              </svg>
            </label>
          </div>
          {!location.state.classroomData && (
            <div className="pl-10">
              <label className="disabled-label" style={{ color: "#403151" }}>
                Share
              </label>
            </div>
          )}
        </div>
        <div className="flex flex-col items-center justify-center mt-8 ">
          <div className="w-full max-w-xl bg-white  rounded-lg ">
            <div className="mb-6">
              <label
                className="create_quiz_label"
                style={{
                  color: "#322E33",

                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px" /* 150% */,
                  letterSpacing: "0.5px",
                }}
              >
                1. Select the topics of your choice
              </label>
              <Autocomplete
                multiple
                options={topicOptions}
                getOptionLabel={(option) => option?.label}
                value={selectedTopics}
                onChange={handleTopicChange}
                ListboxComponent={(props) => (
                  <List {...props} style={{ padding: 0 }}>
                    {props.children.map((child, index) => {
                      const selected = selectedTopics.some(
                        (topic) => topic.label === child.props.children
                      );
                      return React.cloneElement(child, {
                        style: {
                          ...child.props.style,
                          backgroundColor: selected ? "#EBEBEB" : "", // Change this to your desired background color for selected item
                        },
                      });
                    })}
                  </List>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      selectedTopics.length === 0 ? "Select Topic" : ""
                    }
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <Chip
                        variant="outlined"
                        label={option?.label}
                        {...getTagProps({ index })}
                        style={{
                          margin: "2px 0",
                          backgroundColor: "#EBEBEB",
                        }}
                      />
                    </div>
                  ))
                }
              />
            </div>

            <div className="mb-6">
              <label
                className="create_quiz_label"
                style={{
                  color: "#322E33",

                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px" /* 150% */,
                  letterSpacing: "0.5px",
                }}
              >
                2. Choose your desired subtopics
              </label>
              <Autocomplete
                multiple
                options={subtopicOptions}
                getOptionLabel={(option) => option?.label}
                value={selectedSubtopics}
                onChange={handleSubtopicChange}
                ListboxComponent={(props) => (
                  <List {...props} style={{ padding: 0 }}>
                    {React.Children.map(props.children, (child, index) => {
                      const selected = selectedSubtopics.some(
                        (subtopic) => subtopic.label === child.props.children
                      );
                      return React.cloneElement(child, {
                        style: {
                          ...child.props.style,
                          backgroundColor: selected ? "#EBEBEB" : "", // Change this to your desired background color for selected item
                        },
                      });
                    })}
                  </List>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      selectedSubtopics.length === 0 ? "Select Subtopic" : ""
                    }
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <Chip
                        variant="outlined"
                        label={option.label}
                        {...getTagProps({ index })}
                        style={{
                          margin: "2px 0",
                          backgroundColor: "#EBEBEB",
                        }}
                      />
                    </div>
                  ))
                }
              />
            </div>

            <div
              className="mb-2"
              style={{
                display:
                  location.state.subject_status_have_lesson === 0 ? "none" : "",
              }}
            >
              <FormLabel className=" ">
                <label
                  className="create_quiz_label"
                  style={{
                    color: "#322E33",

                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px" /* 150% */,
                    letterSpacing: "0.5px",
                  }}
                >
                  3. Select your lessons or concepts
                </label>
              </FormLabel>
              <FormControl
                component="fieldset"
                className="mt-4 mb-4 items-start"
                style={{
                  display: "flex",
                  alignItems: "start",
                  marginLeft: "16px",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <RadioGroup
                  row
                  aria-label="contentType"
                  name="contentType"
                  value={contentType.current}
                  onChange={(e) => lessonStatus(e)}
                >
                  <FormControlLabel
                    value="lessons"
                    control={<PurpleRadio />}
                    label="Lessons"
                  />
                  <FormControlLabel
                    value="concept"
                    control={<PurpleRadio />}
                    label="Concepts"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="mb-6">
              <label
                className="create_quiz_label"
                style={{
                  color: "#322E33",
                  display:
                    location.state.subject_status_have_lesson === 1
                      ? "none"
                      : "",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px" /* 150% */,
                  letterSpacing: "0.5px",
                }}
              >
                3. Choose your desired Areas
              </label>
              <div className="subtopic_checkbox mt-2">
                <div
                  id="sub_topics_container"
                  className="p-2.5 bg-white shadow-sm outline-none rounded-lg border border-gray-300 w-full h-32 overflow-y-auto"
                >
                  <ul className="text-sm text-black">
                    {areas.length > 0 && (
                      <div className="mb-2">
                        <input
                          id="selectAll"
                          type="checkbox"
                          onChange={handleSelectAllAreas}
                          checked={selectedAreas.length === areas.length}
                        />{" "}
                        Select All
                      </div>
                    )}
                    {areas.map((area, index) => (
                      <li key={index} className="mb-1">
                        <div className="flex items-center">
                          <input
                            id={`area-${index}`}
                            type="checkbox"
                            value={
                              area.area_name
                                ? area.area_name
                                : area.sub_topic_name
                            }
                            onChange={handleAreaChange}
                            checked={selectedAreas.some(
                              (a) =>
                                (a.area_id ? a.area_id : a.sub_topic_id) ===
                                (area.area_id
                                  ? area.area_id
                                  : area.sub_topic_id)
                            )}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                          />
                          <label
                            htmlFor={`area-${index}`}
                            className="ml-2 text-sm font-medium text-gray-900"
                          >
                            {location.state.subject_status_have_lesson === 0
                              ? area.area_name
                              : contentType.current === "lessons"
                              ? area.sub_topic_name
                              : area.area_name}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="w-full mt-8 flex justify-between mb-4">
              <button
                onClick={() => {
                  navigate("/create-classroom", {
                    state: {
                      folder_id: selectedFolder
                        ? selectedFolder.folder_id
                        : null,
                    },
                  });
                }}
                className="teepee-button w-[45%] h-[40px] hover:bg-black hover:cursor-pointer"
                style={{
                  background: "transparent",
                  border: "1px solid #7E418B",
                  color: "#7E418B",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: "600px",
                  fontFamily: "Roboto",
                  fontSize: "12px",
                }}
              >
                Go Back
              </button>
              <button
                className={`teepee-button ${
                  selectedAreas.length === 0 ? "disable-button" : ""
                } w-[45%] h-[40px] hover:bg-black hover:cursor-pointer`}
                style={{
                  backgroundColor: "#7E418B",
                  color: "#FFFFFF",
                  fontSize: "12px",
                }}
                onClick={handleCreate}
                disabled={selectedAreas.length === 0 || isSubmitting === true}
              >
                Create Class
                {isSubmitting && (
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-5 h-5 me-3  animate-spin ml-2"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="white"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPriorPlan;
