import React, { useState } from "react";
import search_icon from "../../images/search.png";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 250,
    opacity: "0.8",
    padding: "12px 16px",
  },
}));

const AreasToFocusOn = (props) => {
  const areasToFocus = props.data?.class_analytics_data?.areas_to_focus;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const focusAreaStyles = {
    maxHeight: areasToFocus && areasToFocus.length > 4 ? "230px" : "auto",
    overflowY: areasToFocus && areasToFocus.length > 4 ? "scroll" : "visible",
    msOverflowStyle: "none", // IE and Edge
    scrollbarWidth: "none", // Firefox
  };

  return (
    <div className="p-4 border-gray-300 border-t-[white] shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <div className="flex text-lg font-bold font-roboto text-[#322E33]">
          <ClickAwayListener onClickAway={handleClose}>
            <div className="flex">
              <HtmlTooltip
                title="The areas which are less mastered."
                PopperProps={{
                  disablePortal: true,
                }}
                enterTouchDelay={0}
                leaveTouchDelay={10}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
              >
                <button className="ml-[5px] mt-[2px] flex" onClick={handleOpen}>
                  Areas to focus on{" "}
                  <img
                    className="ml-2 h-[20px] w-[20px] mt-1"
                    src={search_icon}
                    alt="Search Icon"
                  />
                </button>
              </HtmlTooltip>
            </div>
          </ClickAwayListener>
        </div>
        <div className="text-lg font-bold font-roboto text-[#322E33]">
          <ClickAwayListener onClickAway={handleClose2}>
            <div className="flex">
              <HtmlTooltip
                title="It is a measure of the average number of attempts the entire class takes to master each question."
                PopperProps={{
                  disablePortal: true,
                }}
                enterTouchDelay={0}
                leaveTouchDelay={10}
                open={open2}
                onOpen={handleOpen2}
                onClose={handleClose2}
              >
                <button
                  className="ml-[5px] mt-[2px] flex"
                  onClick={handleOpen2}
                >
                  Mastery Level
                </button>
              </HtmlTooltip>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <div style={focusAreaStyles} className="hide-scrollbar">
        {areasToFocus && areasToFocus.length > 0 ? (
          areasToFocus.map((area, index) => (
            <FocusArea
              key={index}
              label={area.area_name}
              level={area.recall_status_4_percentage}
            />
          ))
        ) : (
          <div className="py-2 px-10 text-center text-gray-600 bg-gray-100 font-bold font-roboto flex item-center justify-center">
            No data available
          </div>
        )}
      </div>
    </div>
  );
};

const FocusArea = ({ label, level }) => (
  <div className="flex justify-between items-center mb-2 border border-gray-300 rounded-md bg-gray-100 p-2">
    <div className="text-gray-800 font-bold font-roboto">
      {label ? label : "N/A"}
    </div>
    <div className="text-[#403151]  font-roboto">
      {level ? `${parseInt(level)}%` : "0%"}
    </div>
  </div>
);

export default AreasToFocusOn;
