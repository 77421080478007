import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../Components/Sidebar";

function AdminDashboard() {
  return (
    <React.Fragment>
      <Sidebar item_id={"quiz"} />
      <h1 className="pl-[25%]">Admin</h1>
    </React.Fragment>
  );
}

export default AdminDashboard;
