import "./App.css";
import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import ProtectedAdminRoutes from "./protected_routes/ProtectedAdminRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import Login from "./Components/Login";
import Register from "./Components/Register";
import ProtectedWebUserRoute from "./protected_routes/ProtectedWebUserRoute";
import Users from "./pages/admin/Users";
import Sidebar from "./Components/Sidebar";
import ViewSubject from "./pages/admin/ViewSubject";
import SubjectsContentContainer from "./pages/admin/SubjectsContentContainer";
import RSetting from "./pages/admin/RSetting";
import CreateCLass from "./pages/Teacher/CreateClass";
import ViewLessonPlan from "./pages/Teacher/ViewLessonPlans";
import Share from "./pages/Teacher/Share";
import TeacherClassroomHome from "./pages/Teacher/TeacherClassroomHome";
import UpdateLessonPlans from "./pages/Teacher/UpdateLessonPlans";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import MSLogin from "./Components/MSLogin";
import GoogleLogin from "./Components/GoogleLogin";
import ContactUs from "./Components/ContactUs";
import UsersFeedback from "./Components/UsersFeedback";
import StudentFeedback from "./Components/StudentFeedback";
import ViewLessonContent from "./pages/admin/ViewLessonContent";
import LessonExperience from "./pages/admin/LessonExperience/LessonExperience";
import AccountDeletion from "./pages/AccountDeletion";
import SupportPage from "./Components/SupportPage";
import ResetPassword from "./Components/AppPages/ResetPassword";
import AddPriorPlan from "./pages/Teacher/AddPriorPlan";
const configuration = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_ID,
  },
};

const pca = new PublicClientApplication(configuration);

function App() {
  var token = localStorage.getItem("access_token");
  const [loggedIn, setLoggedIn] = useState(true);
  const Navigate = useNavigate();
  // const checkForInactivity = () => {
  //   const expireTime = localStorage.getItem("expireTime");

  //   if (expireTime < Date.now()) {
  //     localStorage.removeItem("access_token");
  //     localStorage.removeItem("user_type");
  //     localStorage.removeItem("email_address");
  //     alert("you've been logged out due to inactivity");
  //     window.location.replace("/login");
  //     setLoggedIn(false);
  //   }
  // };
  // const updateExpireTime = () => {
  //   const expireTime = Date.now() + 7200000;
  //   localStorage.setItem("expireTime", expireTime);
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (window.location.pathname === "/login") {
  //       console.log("Don't check");
  //       return;
  //     }
  //     checkForInactivity();
  //   }, 7200000);

  //   return () => clearInterval(interval);
  // });

  // useEffect(() => {
  //   // updateExpireTime();

  //   window.addEventListener("click", updateExpireTime);
  //   window.addEventListener("keypress", updateExpireTime);
  //   window.addEventListener("scroll", updateExpireTime);
  //   window.addEventListener("mousemove", updateExpireTime);

  //   return () => {
  //     window.removeEventListener("click", updateExpireTime);
  //     window.removeEventListener("keypress", updateExpireTime);
  //     window.removeEventListener("scroll", updateExpireTime);
  //     window.removeEventListener("mousemove", updateExpireTime);
  //   };
  // }, []);

  useEffect(() => {
    if (token === null) {
      if (window.location.pathname === "/lesson_experience") {
        return;
      }
      if (window.location.pathname === "/support_page") {
        return;
      }
      if (window.location.pathname === "/app_reset_password") {
        return;
      }
      if (window.location.pathname === "/delete_student_account") {
        return;
      }
      if (window.location.pathname !== "/login")
        window.location.replace("/login");
    } else {
      getUserJwtSession();
    }
  }, []);

  const getUserJwtSession = () => {
    // var token = localStorage.getItem("access_token");

    token = "Bearer " + localStorage.getItem("access_token");

    console.log("TOKEN", token);
    axios
      .request({
        method: "GET",
        url:
          process.env.REACT_APP_REST_API_BASE_URL + "/check_user_jwt_session",

        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log(window.location.pathname);
        if (
          window.location.pathname === "/login" ||
          window.location.pathname === "/register"
        ) {
          window.location.replace("/");
        }
      })
      .catch((error) => {
        console.log(error);

        localStorage.removeItem("access_token");
        localStorage.removeItem("student_name");
        localStorage.removeItem("user_type");
        localStorage.removeItem("email_address");
      });
  };

  const AuthWrapper = () => {
    return isExpired(localStorage.getItem("access_token")) ? (
      <Navigate to="/login" replace />
    ) : (
      <Outlet />
    );
  };

  const isExpired = (access_token) => {
    const decodedJwt = parseJwt(access_token);

    if (decodedJwt.exp * 1000 < Date.now()) {
      return true;
    }

    return false;
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  return (
    <MsalProvider instance={pca}>
      <Routes>
        <Route path="/" element={<ProtectedAdminRoutes />} />
        <Route path="/webuser-r" element={<ProtectedWebUserRoute />} />
        <Route path="/login" element={<Login />} />
        <Route path="/ms_login" element={<MSLogin />} />
        <Route path="/google-login" element={<GoogleLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/users" element={<Users />} />
        <Route path="/view_subject" element={<ViewSubject />} />
        <Route
          path="/view_subject_content"
          element={<SubjectsContentContainer />}
        />
        <Route path="/subject_settings" element={<RSetting />} />
        <Route path="/create-classroom" element={<CreateCLass />} />
        <Route path="/add-lesson-plans" element={<ViewLessonPlan />} />
        <Route path="/add-prior-plans" element={<AddPriorPlan />} />
        <Route path="/update-lesson-plans" element={<UpdateLessonPlans />} />
        <Route path="/report_problem" element={<ContactUs />}></Route>
        <Route path="/teacher_feedback" element={<UsersFeedback />} />
        <Route path="/student_feedback" element={<StudentFeedback />} />
        <Route path="/share" element={<Share />} />
        <Route
          path="/teacher-classroom-home"
          element={<TeacherClassroomHome />}
        />
        <Route path="/support_page" element={<SupportPage />} />
        <Route path="/view_lesson_content" element={<ViewLessonContent />} />
        <Route path="/lesson_experience" element={<LessonExperience />} />
        <Route path="/delete_student_account" element={<AccountDeletion />} />
        <Route path="/app_reset_password" element={<ResetPassword />} />
      </Routes>
    </MsalProvider>
  );
}
export default App;
