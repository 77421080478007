// Import the useState Hook from React
import React, { useState } from 'react';
import '../App.css';
import axios from "axios";

// Define the functional component
const AccountDeletion = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setDialogOpen(true);
    };

    const handleAccountDeletion = () => {
        var token = "Bearer " + localStorage.getItem("access_token");
        axios
            .request({
                method: "POST",
                url: process.env.REACT_APP_REST_API_BASE_URL + "/delete_student",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    authorization: token,
                },
                data: JSON.stringify({
                    email: email,
                    password: password,
                }),
            })
            .then((res) => {
                setDialogOpen(false);
            }
            ).catch()
    };

    return (
        <div className="">
            <center>
            <form onSubmit={handleSubmit} className="space-y-4 w-[50%] md:mt-[160px] sm:mt-[24px] ">
                <div className="space-y-1">
                    <label htmlFor="email" className="block font-medium">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div className="space-y-1">
                    <label htmlFor="password" className="block font-medium">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <button type="submit" className="w-full py-2 px-4 rounded-md bg-red-500 text-white hover:bg-red-600">
                    Submit
                </button>
            </form>
</center>
            {dialogOpen && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-200 bg-opacity-50">
                    <div className="bg-white p-6 rounded-md shadow-xl">
                        <h2 className="text-lg font-medium mb-2">Are you sure?</h2>
                        <p className="text-sm mb-[32px]">If You Delete your account it will not be recovered again.</p>
                     
                        <button onClick={handleAccountDeletion} className="w-[40%] mt-[24px] py-2 px-4 rounded-md bg-gray-500 text-white hover:bg-gray-600">
                            Delete.
                        </button>
                        <button onClick={() => setDialogOpen(false)} className="w-[40%]  mt-[24px] float-right  py-2 px-4 rounded-md bg-gray-500 text-white hover:bg-gray-600">
                            Cancle
                        </button>

                    </div>
                </div>
            )}
        </div>
    );
};

// Export the component
export default AccountDeletion;